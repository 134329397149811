import { errorNavigator } from "@common/utils/error-navigator";
import React, { createContext, useContext } from "react";
import { useParams } from "react-router";
import { getUserByUsername } from "../../../requests/user/user";
import {
  ProfileBio,
  ProfileGallery,
  ProfileHeader,
  ProfileHighlight,
  ProfileInfluences,
  ProfilePlaylists,
  ProfileSidebar,
} from "../components";
import { ProfileLinkedDetails } from "../components/ProfileLinkedDetails";
import { User } from "@server/entities";
import { HttpExceptionClient } from "../../../requests/helpers";
import { EditProfileModal } from "./EditProfileModal";
import { useUser } from "../../../contexts/UserContext";
import { Track } from "@server/entities/user";
import { getTracks } from "../../../requests/user/track";
import { ProfileProgress } from "../components/ProfileProgress";
import { useNavigate } from "react-router-dom";

export interface ProfileContextType {
  loading: boolean;
  error?: { response?: HttpExceptionClient };
  user?: User;
  tracks?: Track[];
  mutate: () => Promise<void>;
  openEditProfile: () => void;
  isMe: boolean;
}

const initialState = {} as ProfileContextType;

const ProfileContext = createContext<ProfileContextType>(initialState);
ProfileContext.displayName = "ProfileContext";

export const useProfile = () => useContext(ProfileContext);

const ProfileComponent: React.FC = () => {
  const { error } = useProfile();

  if (error) return errorNavigator(error);

  return (
    <div className="flex gap-4 pb-24">
      <ProfileSidebar />

      <div className="flex-1">
        <ProfileProgress />

        <ProfileHeader />

        <div className="p-8 -mt-8">
          <ProfileLinkedDetails />

          <ProfileHighlight />

          <ProfileGallery />

          <ProfilePlaylists />

          <ProfileBio />

          <ProfileInfluences />
        </div>
      </div>
    </div>
  );
};

export function Profile() {
  const navigate = useNavigate();
  const { user: me } = useUser();
  const { username, tab } = useParams<{ username: string; tab?: string }>();
  const {
    data: user,
    error,
    mutate,
    isValidating: loading,
  } = getUserByUsername(username as string);

  const { data: tracks, mutate: mutateTracks } = getTracks({
    userId: user?.id as string,
  });

  return (
    <ProfileContext.Provider
      value={{
        user,
        error,
        loading,
        tracks,
        mutate: async () => {
          await mutate();
          await mutateTracks();
        },
        openEditProfile: () => navigate(`/u/${username}/edit`),
        isMe: !!user && !!me && user?.id === me?.id,
      }}
    >
      <ProfileComponent />
      {user && me && user.id === me.id && (
        <EditProfileModal
          opened={tab === "edit"}
          onClose={() => navigate(`/u/${username}`)}
        />
      )}
    </ProfileContext.Provider>
  );
}
