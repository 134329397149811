import { isEqual } from "lodash";
import React, { memo, useEffect, useState } from "react";
import { useContextMenu } from "../../contexts/ContextMenu";
import { themePicker } from "../../helpers/colors";
import { stemHeight } from "../../helpers/constants";
import { StemScaleHashmap } from "../../helpers/workers/stem-scale-hashmap";
import { StemHowl } from "../../recoil/stem";
import { useStemViewer } from "../../contexts/StemViewerContext";
import { useAsyncWorker, useWorker } from "@common/utils/use-worker";
import { Skeleton, ThemeIcon } from "@mantine/core";
import {
  StemScaleArgs,
  StemScaleResult,
} from "../../helpers/workers/stem-scale";
import { AlertTriangle } from "tabler-icons-react";
import { Button } from "@common/components";

interface StemProps {
  stem: StemHowl;
}

const createWorker = () =>
  new Worker(
    new URL("../../helpers/workers/stem-scale-hashmap", import.meta.url),
    { type: "module" }
  );

const createScaleWorker = () =>
  new Worker(new URL("../../helpers/workers/stem-scale", import.meta.url), {
    type: "module",
  });

const StemComponent: React.FC<StemProps> = ({ stem }) => {
  const { scale, duration } = useStemViewer().track(["scale", "duration"]);
  const { stem: stemCtl, track } = useStemViewer().controllers;
  const { open } = useContextMenu();
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const [arrayBuffer, setArrayBuffer] = useState<number[]>([]);
  const trackBody = document.getElementById("track_view");

  const [{ result: hashmap, loading }, setWorkerState] =
    useWorker<StemScaleHashmap>(createWorker, {
      buffer: stem.buffer.getChannelData(0),
      duration: stem.buffer.duration,
      name: stem.name,
    });

  const generateScale = useAsyncWorker<StemScaleArgs, StemScaleResult>(
    createScaleWorker
  );

  // Set array buffer whenever scale changed
  useEffect(() => {
    (async () => {
      if (hashmap) {
        if (scale in hashmap) setArrayBuffer(hashmap[scale] || []);
        else {
          const { array } = await generateScale({
            scale,
            duration: stem.buffer.duration,
            buffer: stem.buffer.getChannelData(0),
          });

          setArrayBuffer(array);
          setWorkerState((prev) => ({
            ...prev,
            result: {
              ...prev.result,
              [scale]: array,
            },
          }));
        }
      }
    })();
  }, [scale, hashmap]);

  // Draw stem whenever array buffer or track duration changes
  useEffect(() => {
    draw();
  }, [canvas, arrayBuffer, duration, stem.buffer]);

  const draw = () => {
    const height = stemHeight;

    const cvs = canvas;
    if (!cvs || !trackBody) return;

    const ctx = cvs.getContext("2d");
    if (!ctx) return;

    cvs.width = track.timeToPixels(duration);
    cvs.height = height;
    ctx.fillStyle = "white";
    ctx.strokeStyle = "white";

    for (let i = 1; i < track.timeToPixels(duration) + 1; i += 1) {
      let a = 1;
      if (i < arrayBuffer.length) a = arrayBuffer[i] * 0.8;

      const x1 = i * 4;
      const y1 = height / 2 - a;
      const x2 = i * 4;
      const y2 = height / 2 + a;

      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.stroke();
    }
  };

  const handleDelete = async () => {
    await stemCtl.delete(stem.stemId);
  };

  return (
    <div
      id={"stem-" + stem.id}
      data-testid="sv-stem"
      data-quick-assist-id="sv-stem"
      className="relative w-fit overflow-visible"
    >
      {stem.error ? (
        <div className="flex items-center gap-4 mx-8">
          <ThemeIcon color="red" radius="xl">
            <AlertTriangle className="w-4 h-4" />
          </ThemeIcon>

          <p className="m-0">Error loading stem. Please try again later.</p>
          <Button color="red" size="xs" onClick={handleDelete}>
            Delete
          </Button>
        </div>
      ) : (
        <Skeleton
          visible={stem.loading || loading}
          radius="xs"
          sx={{
            zIndex: 0,
            ":after": {
              backgroundColor: themePicker[stem.color].stem,
            },
          }}
        >
          <div
            className="w-fit transition-colors rounded pr-0 overflow-hidden"
            style={{
              height: stemHeight - 4,
              backgroundColor: themePicker[stem.color].stem,
              borderColor: themePicker[stem.color].stem,
              opacity: (stem.mute ? 0.3 : 0.8) + (stem.active ? 0.2 : 0),
              margin: 0,
              zIndex: 10,
            }}
          >
            <canvas
              data-testid="sv-stem-canvas"
              ref={setCanvas}
              onContextMenu={(ev) => open(ev, stem.stemId)}
            />
          </div>
        </Skeleton>
      )}
    </div>
  );
};

const Stem = memo(StemComponent, (prev, next) => isEqual(prev.stem, next.stem));

export { Stem };
