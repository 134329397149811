import { Button, Menu } from "@common/components";
import { Check, HourglassBottomRounded } from "@mui/icons-material";
import React, { useState } from "react";
import { BsPersonCheck, BsPersonPlus, BsPersonX } from "react-icons/bs";
import { useUser } from "../../../contexts/UserContext";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import { useChat } from "../../../contexts/ChatContext";
import { useProfile } from "../pages/Profile";
import {
  acceptFriendRequest,
  deleteFriendship,
  sendFriendRequest,
  useFriendship,
} from "../../../requests/social/friendship";

interface ProfileFriendRequestProps {}

const ProfileFriendRequest: React.FC<ProfileFriendRequestProps> = ({}) => {
  const { user } = useProfile();
  const { user: me, loading: loading2 } = useUser();
  const { openUser } = useChat();
  const [loading, setLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const {
    data,
    isValidating: loading1,
    mutate,
  } = useFriendship(user?.id as string);

  const handleRequest = async () => {
    if (!me || !user) return;
    if (data?.status === "accepted") return setMenuOpen(true);
    setLoading(true);

    // If no friendship exists
    if (!data) await sendFriendRequest(user.id);
    else {
      // If friendship exists and is pending
      if (data.status === "pending") {
        // If I sent the request, cancel
        if (me.id === data.srcUserId) await deleteFriendship(data.id);
        // Otherwise, accept
        else await acceptFriendRequest(data.id);
      }
    }
    await mutate();
    setLoading(false);
  };

  const handleUnfriend = async () => {
    if (!data) return;
    setLoading(true);
    await deleteFriendship(data.id);
    await mutate(() => null);
    setLoading(false);
  };

  if (me?.id === user?.id) return <></>;

  let color = "";
  let icon = <BsPersonPlus />;
  let text = "Connect";

  switch (data?.status) {
    case "accepted":
      color = "gray";
      icon = <BsPersonCheck />;
      text = "Connected";
      break;
    case "pending":
      if (me?.id === data?.srcUserId) {
        color = "gray";
        icon = <HourglassBottomRounded className="w-5 h-5" />;
        text = "Request sent";
      } else {
        color = "indigo";
        icon = <Check />;
        text = "Accept";
      }
      break;
  }

  return (
    <div className="flex items-center justify-center gap-4">
      <Menu
        position="bottom"
        transition="fade"
        opened={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <Menu.Target>
          <Button
            loading={loading || loading1 || loading2}
            onClick={handleRequest}
            variant="light"
            color={color}
            leftIcon={icon}
          >
            {text}
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item onClick={handleUnfriend} color="red" icon={<BsPersonX />}>
            Remove
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <Button
        variant="light"
        color="dark"
        leftIcon={<ChatBubbleLeftIcon className="w-4" />}
        onClick={() => user?.id && openUser(user.id)}
      >
        Message
      </Button>
    </div>
  );
};

export { ProfileFriendRequest };
