import React from "react";
import { useUser } from "../../../contexts/UserContext";
import { Card, Divider } from "@common/components";
import { Box, Switch } from "@mantine/core";
import RadioGroup from "@common/components/RadioGroup";
import { UpdateUserDto } from "@server/modules/user/user/dto/update-user.dto";
import useAsyncForm from "@common/utils/useAsyncForm";
import { Check } from "tabler-icons-react";
import { getUserByUsername, patchUser } from "../../../requests/user/user";
import { FormActionButtons } from "@common/components/FormActionButtons";

export const PrivacySettings: React.FC = () => {
  const { user: me } = useUser();
  const { data: user } = getUserByUsername(me?.username as string);

  const form = useAsyncForm<UpdateUserDto>({
    initialValues: {
      privacySettings: user?.privacySettings || {},
    },
  });

  const handleSave = async () => {
    if (!user) return;
    await form.sendForm((values) => patchUser(user.id, values), {
      successMessage: "Settings updated!",
      resetInitial: true,
    });
  };

  return (
    <div className="p-10 container">
      <h2 className="mb-4">Privacy</h2>
      <p className="text-gray-400">
        Click <a href="https://www.synqup.com.au/privacy-policy">here</a> to
        learn about our Privacy Policy and how we use your data.
      </p>

      <Divider className="my-8" />

      <h2 className="mb-4">Account Type</h2>
      <p className="text-gray-400">
        By setting your account to private, only your connections will have the
        ability to view the and interact with your media and content. Your
        profile will still appear in other users’ searches.
      </p>

      <Card>
        <div className="flex items-center justify-between -my-2">
          <h4>Private account</h4>

          <Switch
            {...form.getInputProps("privacySettings.private", {
              type: "checkbox",
            })}
          />
        </div>
      </Card>

      <Divider className="my-8" />

      <h2 className="mb-4">Messages</h2>
      <p className="text-gray-400">
        This setting specifically affects new messages you receive, and it does
        not have any impact on existing conversations.
      </p>

      <Card>
        <RadioGroup
          {...form.getInputProps("privacySettings.messages")}
          options={[
            { label: "Everyone", value: "all" },
            { label: "Connections only", value: "connections" },
          ]}
          renderOption={(option, active) => (
            <Box
              className="flex items-center justify-between cursor-pointer p-3 rounded"
              sx={(theme) => ({
                background: active ? theme.colors["primary"][8] : "transparent",
              })}
            >
              {option.icon}

              <div>
                <h4>{option.label}</h4>
              </div>

              {active && <Check size={16} />}
            </Box>
          )}
        />
      </Card>

      <Divider className="my-8" />

      <h2 className="mb-4">Project invites</h2>
      <p className="text-gray-400">
        All incoming invitations you receive will be subject to this setting,
        while any invitations you already have will remain unchanged.
      </p>

      <Card>
        <RadioGroup
          {...form.getInputProps("privacySettings.projects")}
          options={[
            { label: "Everyone", value: "all" },
            { label: "Connections only", value: "connections" },
          ]}
          renderOption={(option, active) => (
            <Box
              className="flex items-center justify-between cursor-pointer p-3 rounded"
              sx={(theme) => ({
                background: active ? theme.colors["primary"][8] : "transparent",
              })}
            >
              {option.icon}

              <div>
                <h4>{option.label}</h4>
              </div>

              {active && <Check size={16} />}
            </Box>
          )}
        />
      </Card>

      <FormActionButtons form={form} onSave={handleSave} />
    </div>
  );
};
