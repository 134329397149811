import {
  ActionIcon,
  Avatar,
  Button,
  Card,
  Divider,
  RichText,
} from "@common/components";
import Confirm from "@common/components/Confirm";
import Skeleton from "@common/components/Skeleton";
import { isEmpty } from "@common/utils/editor";
import { errorNavigator } from "@common/utils/error-navigator";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Textarea, Timeline, Transition } from "@mantine/core";
import { Note as NoteEntity } from "@server/entities/project";
import React, { useState } from "react";
import { useParams } from "react-router";
import { useUser } from "../../../../contexts/UserContext";
import {
  deleteNote,
  getNote,
  getProjectNotes,
  patchNote,
  postComment,
} from "../../../../requests/project/note";
import { Note } from "./Note";
import { useProject } from "../ProjectContext";
import { useNavigate } from "react-router-dom";
import useAsyncForm from "@common/utils/useAsyncForm";
import { UpdateNoteDto } from "@server/modules/project/note/dto";
import { BsFloppy } from "react-icons/bs";

const ProjectNote: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { project } = useProject();
  const { noteId } = useParams<{ noteId: string }>();
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [body, setBody] = useState("");
  const { mutate: mutateAllNotes } = getProjectNotes(project?.id as string);

  const {
    data: note,
    isValidating: loading,
    mutate,
    error,
  } = getNote(noteId as string);

  const form = useAsyncForm<UpdateNoteDto>({
    initialValues: {
      title: note?.title || "",
    },
  });

  if (error) return errorNavigator(error);

  const handlePost = async () => {
    if (isEmpty(body) || !note) return;

    setBody("");
    await postComment(note.id, { body });
    await mutate(
      (note) =>
        ({
          ...note,
          replies: [
            ...(note?.replies || []),
            {
              body,
              user,
            } as NoteEntity,
          ],
        } as NoteEntity)
    );
  };

  const handleSaveTitle = async () => {
    if (!form.isDirty()) return;
    if (!note?.id) return;
    await form.sendForm((values) => patchNote(note.id, values), {
      resetInitial: true,
    });
  };

  const handleDelete = async () => {
    if (!deleteId) return;
    setDeleteId(null);
    await deleteNote(deleteId);
    if (deleteId === note?.id) {
      navigate(`/p/${project?.permalink}/threads`);
      await mutateAllNotes();
    } else await mutate();
  };

  return (
    <div className="pt-5">
      <Confirm
        title={`Delete ${note?.id === deleteId ? "thread" : "comment"}?`}
        icon={<TrashIcon className="w-8 h-8" />}
        color="red"
        content={`Are you sure you would like to delete this ${
          note?.id === deleteId ? "thread" : "comment"
        }?`}
        opened={!!deleteId}
        onClose={() => setDeleteId(null)}
        onConfirm={handleDelete}
      />

      {!loading && note ? (
        <div className="flex items-center gap-6">
          <Textarea
            data-testid="thread-title-textarea"
            {...form.getInputProps("title")}
            classNames={{
              root: "flex-1",
              input:
                "w-full font-bold text-3xl pl-2 -mx-2 bg-dark-1000 bg-opacity-0 transition hover:bg-opacity-100",
            }}
            styles={{
              input: { border: "1px solid transparent" },
            }}
            autosize
            minRows={1}
            readOnly={note.userId !== user?.id}
          />

          <Transition transition="fade" mounted={form.isDirty()}>
            {(styles) => (
              <ActionIcon
                data-testid="thread-title-save"
                color="purple"
                variant="light"
                onClick={handleSaveTitle}
                style={styles}
              >
                <BsFloppy />
              </ActionIcon>
            )}
          </Transition>
          <span className="text-dark-400 font-normal text-xl">#{note.num}</span>
        </div>
      ) : (
        <Skeleton visible height={28} />
      )}

      <Divider />

      <Timeline lineWidth={2}>
        <Timeline.Item bulletSize={36} bullet={<Avatar user={note?.user} />}>
          {loading || !note ? (
            <Skeleton visible height={128} />
          ) : (
            <Note main note={note} onDelete={setDeleteId} mutate={mutate} />
          )}
        </Timeline.Item>

        {note?.replies?.map((note, index) => (
          <Timeline.Item
            key={index}
            bulletSize={36}
            bullet={<Avatar user={note.user} />}
          >
            <Note note={note} onDelete={setDeleteId} mutate={mutate} />
          </Timeline.Item>
        ))}

        <Timeline.Item bulletSize={36} bullet={<Avatar user={user} />}>
          <Card variant="outlined" className="overflow-hidden">
            <RichText
              data-testid="thread-comment-input"
              users={project?.collaborators
                .map((c) => c.user)
                .filter((c) => c.id !== user?.id)}
              placeholder="Add a comment"
              value={body}
              onChange={(value) => setBody(value)}
            />

            <Button
              data-testid="thread-comment-button"
              className="mt-4 float-right"
              variant="light"
              size="sm"
              onClick={handlePost}
            >
              Comment
            </Button>
          </Card>
        </Timeline.Item>
      </Timeline>
    </div>
  );
};

export { ProjectNote };
