import { Project } from "@server/entities/project";
import { atom } from "recoil";

export type TrackState = {
  project: Project | undefined;
  currTime: number;
  playing: boolean;
  scale: number;
  duration: number;
  bpm: number;
  region: [number, number] | null;
  scroll: [number, number];
  notesOpen: boolean;
};

export const projectState = atom<Project | undefined>({
  key: "project",
  default: undefined,
});

export const currTimeState = atom<number>({
  key: "currTime",
  default: 0,
});

export const playingState = atom<boolean>({
  key: "playing",
  default: false,
});

/**
 * Number of seconds per 100 pixels
 */
export const scaleState = atom<number>({
  key: "scale",
  default: 8,
});

export const durationState = atom<number>({
  key: "duration",
  default: 0,
});

export const bpmState = atom<number>({
  key: "bpm",
  default: 130,
});

export const regionState = atom<[number, number] | null>({
  key: "region",
  default: null,
});

export const scrollState = atom<[number, number]>({
  key: "scroll",
  default: [0, 0],
});

export const notesOpenState = atom<boolean>({
  key: "notesOpen",
  default: false,
});
