import React, { useEffect } from "react";
import { useProject } from "../ProjectContext";
import { useMediaLibrary } from "../../../../contexts/MediaLibrary/MediaLibraryContext";
import { MediaLibrary } from "../../../../contexts/MediaLibrary/MediaLibrary";

interface ProjectFilesProps {}

export const ProjectFiles: React.FC<ProjectFilesProps> = ({}) => {
  const { project } = useProject();
  const { setQuery } = useMediaLibrary();

  useEffect(() => {
    if (!project?.id) return;
    setQuery({ projectId: project?.id });
    return () => setQuery({});
  }, [project]);

  if (!project?.id) return <></>;

  return (
    <MediaLibrary
      data-testid="project-media-library"
      queryable
      projectId={project.id}
    />
  );
};
