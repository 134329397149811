import React, { HTMLProps } from "react";

interface RedDotProps extends HTMLProps<HTMLDivElement> {
  count: number;
}

const RedDot: React.FC<RedDotProps> = ({ count, ...props }) => {
  return (
    <div
      className="absolute z-10 bg-red-500 rounded-full
      translate-x-4 translate-y-3 
      border-2 border-solid border-dark-900
      children:text-white text-center"
      {...props}
    >
      <p className="font-semibold leading-tight text-[0.75rem] px-1">
        {count > 9 ? `${count}+` : count}
      </p>
    </div>
  );
};

export { RedDot };
