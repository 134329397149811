import React from "react";
import dompurify from "dompurify";
const DOMPurify = dompurify(window);

interface RenderHTMLProps {
  className?: string;
  html?: string;
}

export const RenderHTML: React.FC<RenderHTMLProps> = ({ html, className }) => {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}
    />
  );
};
