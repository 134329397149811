import React from "react";
import { Button, Divider, Input } from "@common/components";
import { useUser } from "../../../contexts/UserContext";
import useAsyncForm from "@common/utils/useAsyncForm";
import { UpdateUserDto } from "@server/modules/user/user/dto/update-user.dto";
import { FormActionButtons } from "@common/components/FormActionButtons";
import { patchUser } from "../../../requests/user/user";
import { postResetPassword } from "../../../requests/auth/auth";
import { notification } from "@common/utils/notification";

export const AccountSettings: React.FC = () => {
  const { user } = useUser();

  const form = useAsyncForm<UpdateUserDto>({
    initialValues: {
      username: user?.username,
      email: user?.email,
    },
  });

  const handleSave = async () => {
    if (!user) return;
    await form.sendForm((values) => patchUser(user.id, values), {
      successMessage: "User updated!",
      resetInitial: true,
    });
  };

  const handleResetPassword = async () => {
    const { data, error } = await postResetPassword();
    if (error) return notification.error(error.message);
    notification.success(data || "Password reset email sent!");
  };

  return (
    <div className="p-10 container">
      <h2 className="mb-4">Account Settings</h2>

      <Input label="Username" {...form.getInputProps("username")} />
      <p className="text-gray-500">
        https://dev.synqup.com.au/u/{form.values.username}
      </p>

      <Input label="Email address" {...form.getInputProps("email")} />

      <FormActionButtons form={form} onSave={handleSave} />

      <Divider />

      <br />
      <h3>Change password</h3>
      <p className="text-gray-400 my-4">
        To change your password, click on the button below and a password reset
        email will be sent to your account. If you have logged in using a
        third-party provider, such as Google or Facebook, you will need to set a
        password first.
      </p>

      <Button onClick={handleResetPassword}>Change your password</Button>
    </div>
  );
};
