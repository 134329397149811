import React, { useState } from "react";
import { Input } from "@common/components";
import { MessageItem } from "./MessageItem";
import { Card, Loader } from "@mantine/core";
import { useChat } from "../../../contexts/ChatContext";
import { getOtherUser, getUnreadCount } from "./MessagesMenu";
import { MagnifyingGlassIcon } from "@modulz/radix-icons";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useUsers } from "../../../requests/user/user";
import { User } from "@server/entities";
import { useUser } from "../../../contexts/UserContext";
import { createChannel } from "../../../requests/social/channel";
import { notification } from "@common/utils/notification";

export const ChannelsList: React.FC = () => {
  const navigate = useNavigate();
  const { channelId } = useParams<{ channelId: string }>();
  const { channels } = useChat();
  const { user: me } = useUser();

  const [search, setSearch] = useState("");

  const { data: users } = useUsers({ query: search });

  const handleClick = async (user: User) => {
    setSearch("");
    if (!user) return;

    // Creates or finds a channel with two userIds in it
    const { data, error } = await createChannel({
      channelUsers: [{ userId: user.id }],
    });

    if (error) return notification.error(error.message);

    if (data) navigate(`/messages/${data.id}`);
  };

  return (
    <>
      <Input
        data-testid="search"
        className="my-4"
        placeholder="Search for friends"
        icon={<MagnifyingGlassIcon />}
        value={search}
        onChange={({ target }) => setSearch(target.value)}
      />

      {search && search.trim() !== "" ? (
        users ? (
          users.map((user, index) => (
            <Card
              key={index}
              sx={{
                background: "rgba(0,0,0,0.1)",
                "&:hover": {
                  background: "rgba(0,0,0,0.2)",
                },
              }}
              className="my-1 cursor-pointer"
              onClick={() => handleClick(user)}
            >
              <MessageItem otherUser={user} unreadCount={0} />
            </Card>
          ))
        ) : (
          <div className="flex justify-center">
            <Loader variant="bars" />
          </div>
        )
      ) : channels ? (
        channels && channels.length > 0 ? (
          channels
            .filter((channel) => getOtherUser(channel, me))
            .map((channel, index) => (
              <Link key={index} to={`/messages/${channel.id}`}>
                <Card
                  sx={{
                    background:
                      channel.id === channelId
                        ? "rgba(62,86,220,0.1) !important"
                        : getUnreadCount(channel, me) === 0
                        ? "rgba(0,0,0,0.1)"
                        : "rgba(255,217,122,0.1)",
                    "&:hover": {
                      background:
                        getUnreadCount(channel, me) === 0
                          ? "rgba(0,0,0,0.2)"
                          : "rgba(255,217,122,0.15)",
                    },
                  }}
                  className="my-1 cursor-pointer"
                  data-testid="message-item"
                >
                  <MessageItem
                    channel={channel}
                    otherUser={getOtherUser(channel, me)}
                    unreadCount={getUnreadCount(channel, me)}
                  />
                </Card>
              </Link>
            ))
        ) : (
          <div>
            <p>No messages</p>
          </div>
        )
      ) : (
        <div className="flex justify-center">
          <Loader variant="bars" />
        </div>
      )}
    </>
  );
};
