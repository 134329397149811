import React, { useState } from "react";
import { User } from "@server/entities";
import { ActionIcon, Card, DotsMenu } from "@common/components";
import { PlayArrowRounded } from "@mui/icons-material";
import { AudioVisualiser } from "@common/components/AudioVisualiser";
import { Replace, Trash } from "tabler-icons-react";
import { useProfile } from "../pages/Profile";
import { AddProfileHighlightModal } from "../pages/AddProfileHighlightModal";
import { patchUser } from "../../../requests/user/user";
import { usePlayer, usePlayerState } from "../../../contexts/Player";

interface ProfileHighlightProps {
  user?: User;
}

export const ProfileHighlight: React.FC<ProfileHighlightProps> = ({}) => {
  const { user, isMe, mutate } = useProfile();
  const [editing, setEditing] = useState(false);

  const handleDelete = async () => {
    if (!user) return;
    const { error } = await patchUser(user.id, {
      highlight: null,
    });
    if (error) return;
    await mutate();
  };

  if (!user?.highlight && !isMe) return <></>;

  return (
    <section id="highlight" className="py-10">
      <Card className="relative overflow-hidden max-w-4xl h-[28rem] mx-auto flex items-center justify-center border border-solid border-dark-800">
        {isMe && (
          <>
            <AddProfileHighlightModal
              opened={editing}
              onClose={() => setEditing(false)}
            />

            <DotsMenu className="absolute right-4 top-4">
              <DotsMenu.Dropdown>
                <DotsMenu.Item
                  py={4}
                  icon={<Replace className="w-4" />}
                  onClick={() => setEditing(true)}
                >
                  Replace highlight
                </DotsMenu.Item>

                <DotsMenu.Item
                  py={4}
                  icon={<Trash className="w-4" />}
                  color="red"
                  onClick={handleDelete}
                >
                  Delete highlight
                </DotsMenu.Item>
              </DotsMenu.Dropdown>
            </DotsMenu>
          </>
        )}

        {user?.highlight ? (
          <ProfileHighlightComponent user={user} />
        ) : (
          <p className="text-lg max-w-md">
            Add a highlight by clicking the dots in the top right
          </p>
        )}
      </Card>
    </section>
  );
};

interface ProfileHighlightComponentProps {
  user: User;
}

export const ProfileHighlightComponent: React.FC<
  ProfileHighlightComponentProps
> = ({ user }) => {
  const { load } = usePlayer();
  const { audio, playing: _playing } = usePlayerState(["audio", "playing"]);
  const playing = _playing && audio?.src === user?.highlight?.src;

  if (!user.highlight) return <></>;

  return (
    <>
      {!playing && (
        <ActionIcon
          className="absolute z-20 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          size="lg"
          onClick={() =>
            user?.highlight &&
            load({
              src: user.highlight.src,
              name: user.highlight.title,
              artwork: user.highlight.artwork,
              artist: user.displayName,
            })
          }
        >
          <PlayArrowRounded />
        </ActionIcon>
      )}

      <div className="absolute z-10 text-center">
        <h3 className="font-medium">{user.highlight.title}</h3>
        <p className="font-semibold mix-blend-color-dodge">
          {user.displayName}
        </p>
      </div>

      {user.highlight.artwork && (
        <img
          className="absolute z-0 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full blur-xl opacity-40 pointer-events-none"
          src={user.highlight.artwork}
          alt="bg-highlight"
        />
      )}

      <AudioVisualiser src={user.highlight.src} bins={128} />
    </>
  );
};
