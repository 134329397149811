import { AvatarsGroup, Card } from "@common/components";
import { Avatar, Badge } from "@mantine/core";
import React, { useState } from "react";
import { Project } from "@server/entities/project";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../contexts/UserContext";
import { starProject } from "../../../requests/project/project";
import { notification } from "@common/utils/notification";
import { Star } from "tabler-icons-react";

const ProjectCard = ({
  project,
  className = "",
  ...props
}: {
  project: Project;
  className?: string;
}) => {
  const navigate = useNavigate();
  const { user, starred, mutateStarred } = useUser();
  const [hovered, setHovered] = useState(false);

  const handleStar = () => async (e) => {
    e.stopPropagation();
    const { error } = await starProject(project.id);
    if (error) return notification.error(error.message);
    await mutateStarred();
  };

  const isStarred = (project: Project) =>
    starred?.some((p) => p.id === project.id);

  return (
    <Card
      className={`relative overflow-hidden cursor-pointer transition-transform hover:scale-[1.02] hover:shadow ${className}`}
      onClick={() => navigate(`/p/${project.permalink}`)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...props}
    >
      <div className="flex items-start justify-between">
        <div>
          <div className="flex items-start gap-4 w-full">
            <Avatar size="lg" radius="md" src={project.albumArt} />
            <div>
              <h3
                data-testid="project-card-title"
                className="font-medium mb-1 line-clamp-2"
              >
                {project.name}
              </h3>

              <AvatarsGroup
                noAnimation
                size="sm"
                className="ml-auto"
                users={project.collaborators.map((collab) => collab?.user)}
              />
            </div>
          </div>
        </div>

        <div style={{ opacity: hovered ? 1 : 0 }}>
          {!isStarred(project) ? (
            <Star
              onClick={handleStar()}
              data-testid="star-project"
              data-quick-assist-id="star-project"
              className="w-4 h-4 m-2 text-dark-300 hover:text-dark-400"
            />
          ) : (
            <Star
              onClick={handleStar()}
              className="w-4 h-4 m-2 stroke-0 fill-amber-400 hover:fill-amber-300"
            />
          )}
        </div>
      </div>

      <table className="text-xs text-dark-300 pl-20 mt-2">
        <tbody>
          <tr>
            <td className="w-full">My markups</td>
            <td className="text-right">
              <Badge className="tracking-normal" color="red" size="xs">
                {project.markups.filter(
                  (markup) =>
                    markup.assigneeId === user?.id &&
                    markup.status !== "approved"
                ).length || 0}
              </Badge>
            </td>
          </tr>
          <tr>
            <td className="w-full">All markups</td>
            <td className="text-right">
              <Badge className="tracking-normal" size="xs">
                {
                  project.markups.filter(
                    (markup) => markup.status !== "approved"
                  ).length
                }
              </Badge>
            </td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
};

export { ProjectCard };
