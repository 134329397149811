import { showNotification, updateNotification } from "@mantine/notifications";
import { CheckIcon } from "@modulz/radix-icons";
import { BsExclamation } from "react-icons/bs";

export class notification {
  public static success(message: string) {
    return showNotification({
      message: message,
      color: "green",
      icon: <CheckIcon className="w-5 h-5" />,
    });
  }

  public static error(message: string) {
    return showNotification({
      message: message,
      color: "red",
      icon: <BsExclamation className="w-5 h-5" />,
    });
  }

  public static loading(message: string) {
    return showNotification({
      id: "loading-notification",
      message: message,
      color: "gray",
      loading: true,
      autoClose: false,
      disallowClose: true,
    });
  }

  public static done(message: string) {
    return updateNotification({
      id: "loading-notification",
      message: message,
      color: "gray",
      icon: (
        <CheckIcon className="scale-[0.69] translate-x-[-1px] translate-y-[-1px]" />
      ),
      autoClose: 2000,
    });
  }
}
