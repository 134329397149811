import queryString from "query-string";
import React from "react";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { WithContext } from "./with-contexts";

export type QueryParams<T extends { [key: string]: any }> = {
  [K in keyof T]: T[K] | null | undefined;
};

export type SetQueryParams<T extends { [key: string]: any }> = (
  query: Partial<{
    [K in keyof T]: T[K] | null | undefined;
  }>
) => void;

export const withQueryParamsContext: WithContext = (Component) => (props) => {
  return (
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      options={{
        searchStringToObject: queryString.parse,
        objectToSearchString: queryString.stringify,
      }}
    >
      <Component {...props} />
    </QueryParamProvider>
  );
};
