import React, { useEffect, useRef, useState } from "react";
import { Howler } from "howler";
import { mean, zip } from "lodash";
import { usePlayerState } from "../../contexts/Player";

interface AudioVisualiserProps {
  bins?: number;
  gradient?: { from: string; to: string };
  // Add the string for the source music to prevent audio visualiser from
  // animating for other songs
  src?: string;
}

export const AudioVisualiser: React.FC<AudioVisualiserProps> = ({
  bins = 128,
  gradient = { from: "#cdd5d7", to: "#a09da1" },
  src,
}) => {
  const {
    audio,
    playing: _playing,
    volume,
  } = usePlayerState(["audio", "playing", "volume"]);
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const [analyser, setAnalyser] = useState<AnalyserNode | null>(null);

  const [dataArray, setDataArray] = useState(new Uint8Array(bins).fill(2));
  const prevArraysRef = useRef<Array<Uint8Array>>(
    new Array(6).fill(new Uint8Array(bins).fill(2))
  );
  const volumeRef = useRef<number>(volume);
  const [rafId, setRafId] = useState<number | null>(null);

  let playing = _playing;
  if (src) playing = _playing && src === audio?.src;

  useEffect(() => {
    if (!audio) return;
    if (!Howler.ctx) return;

    // Render new spectrum
    const analyser = Howler.ctx?.createAnalyser();
    analyser.maxDecibels = 0;
    analyser.minDecibels = -90;
    setAnalyser(analyser);
    Howler.masterGain.connect(analyser);
    setDataArray(new Uint8Array(analyser.frequencyBinCount));
    renderSpectrum(0);
  }, [audio]);

  const tick = (time: number) => {
    renderSpectrum(time);
    analyser?.getByteTimeDomainData(dataArray);
    setRafId(requestAnimationFrame(tick));
  };

  useEffect(() => {
    if (playing && !rafId) return setRafId(requestAnimationFrame(tick));
    if (rafId) {
      cancelAnimationFrame(rafId);
      setRafId(null);
    }
  }, [playing]);

  useEffect(() => {
    volumeRef.current = volume;
  }, [volume]);

  const renderSpectrum = (time: number) => {
    const cvs = canvas;
    if (!cvs) return;
    const ctx = cvs.getContext("2d");
    if (!ctx) return;

    const radius = 128;

    const _array: Uint8Array = new Uint8Array(bins);
    for (const [idx, value] of dataArray.entries()) {
      if (idx % ((128 * 8) / bins) === 0)
        _array[idx / ((128 * 8) / bins)] = value;
    }

    const min = Math.min(..._array);

    // @ts-ignore
    const array = _array.map((value, idx) => {
      let x = (value - min) * (1 / (volumeRef.current + 0.01));
      x += 2;

      return mean([...zip(...prevArraysRef.current)[idx], x]);
    });

    prevArraysRef.current = [...prevArraysRef.current, array].slice(1);

    let width = cvs.width;
    let height = cvs.height;
    let centre_x = cvs.width / 2;
    let centre_y = cvs.height / 2;

    ctx.clearRect(0, 0, width, height);
    ctx.lineWidth = 3;

    let grad = ctx.createLinearGradient(0, 0, width, height);
    grad.addColorStop(0, gradient.from);
    grad.addColorStop(1, gradient.to);
    ctx.strokeStyle = grad;

    const delta = time / 1000;

    for (let i = 0; i < array.length; i++) {
      ctx.beginPath();

      let theta = (i / array.length) * 2 * Math.PI + Math.PI / 2;
      const a = array[i];

      ctx.moveTo(
        centre_x + radius * Math.cos(theta),
        centre_y + radius * Math.sin(theta)
      );

      ctx.lineTo(
        centre_x + (radius + a) * Math.cos(theta),
        centre_y + (radius + a) * Math.sin(theta)
      );

      ctx.stroke();
    }
  };

  return (
    <canvas
      className="mix-blend-color-dodge"
      ref={setCanvas}
      width={640}
      height={640}
    />
  );
};
