import React from "react";
import { File } from "@server/entities";
import { Badge, Table } from "@mantine/core";
import { formatBytes } from "@common/utils/constants";
import { ActionIcon, AvatarsGroup } from "@common/components";
import { ArrowRight } from "tabler-icons-react";
import { useMediaLibrary } from "../MediaLibraryContext";
import Checkbox from "@common/components/Checkbox";
import { Avatar } from "@common/components/Avatar";
import dayjs from "dayjs";
import { FileMenu } from "./FileMenu";

interface FileLibraryProps {
  files?: File[];
  onFileSelect?: (file: File) => void;
  projectId?: string;
}

export const getFilename = (file: File) =>
  file.filename.split(".").slice(0, -1).join(".");
export const getFiletype = (file: File) =>
  file.filename.split(".").slice(-1)[0];

export const FileLibrary: React.FC<FileLibraryProps> = ({
  files,
  onFileSelect,
  projectId,
}) => {
  const { closeMediaLibrary, onFileDelete, isFileChecked, onFileChecked } =
    useMediaLibrary();

  return (
    <Table>
      <thead>
        <tr>
          <th className="w-10">
            <Checkbox
              data-testid="select-all"
              radius="sm"
              checked={files?.every((file) => isFileChecked(file.id))}
              onChange={() => onFileChecked("all")}
            />
          </th>
          <th>File name</th>
          <th className="w-32">Project</th>
          <th className="w-32">Shared with</th>
          {projectId && <th className="w-24 text-center">Owner</th>}
          <th className="w-32">Date added</th>
          <th className="w-32">Size</th>
          <th className="w-5" />
        </tr>
      </thead>
      <tbody>
        {files?.length === 0 && (
          <tr>
            <td colSpan={6}>
              <p className="text-center text-dark-400 m-0 py-4">
                No files found
              </p>
            </td>
          </tr>
        )}

        {files?.map((file) => (
          <tr key={file.id}>
            <td className="w-10">
              <Checkbox
                data-testid="file-checkbox"
                radius="sm"
                checked={isFileChecked(file.id)}
                onChange={() => onFileChecked(file.id)}
              />
            </td>
            <td>
              <div className="flex items-center gap-2">
                <p data-testid="file-name" className="m-0">
                  {getFilename(file)}
                </p>
                <Badge
                  data-testid="file-type"
                  className="truncate max-w-[4rem]"
                  size="xs"
                  color="teal"
                >
                  {getFiletype(file)}
                </Badge>
              </div>
            </td>
            <td data-testid="file-project-name">{file.project?.name || "-"}</td>
            <td>
              <AvatarsGroup
                data-testid="file-shared-with"
                size="sm"
                limit={2}
                users={file.sharedWith}
              />
            </td>
            {projectId && (
              <td data-testid="file-owner">
                <Avatar size="sm" className="mx-auto" user={file.user} />
              </td>
            )}
            <td>
              <p className="text-dark-400 m-0">
                {dayjs(file.createdAt).format("D/M/YY")}
              </p>
            </td>
            <td>
              <p className="text-dark-400 m-0 mr-4">{formatBytes(file.size)}</p>
            </td>
            <td>
              <div className="flex items-center gap-2">
                {onFileSelect && (
                  <ActionIcon
                    variant="filled"
                    color="dark"
                    onClick={() => {
                      onFileSelect(file);
                      closeMediaLibrary();
                    }}
                  >
                    <ArrowRight />
                  </ActionIcon>
                )}

                <FileMenu file={file} onFileDelete={onFileDelete} />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
