import { ModalProps } from "@mantine/core";
import React from "react";
import Dropzone from "../../../../../common/components/Dropzone";
import { Modal } from "@common/components";
import { useStemViewer } from "../../../contexts/StemViewerContext";

type ReplaceStemProps = Omit<ModalProps, "opened" | "id"> & {
  id: string | null;
};

const ReplaceStem: React.FC<ReplaceStemProps> = ({ id, ...props }) => {
  const { stem } = useStemViewer().controllers;
  const { stems, uploading } = useStemViewer().stem(["stems", "uploading"]);

  const handleUploadFiles = async (files: File[]) => {
    if (!id) return props.onClose();

    if (files.length === 0) return;

    props.onClose();
    await stem.replace(id, files[0]);
  };

  const replacingStem = stems.find((stem) => stem.stemId === id);

  return (
    <Modal
      className="z-[1000]"
      title={<h2>Replace Stem</h2>}
      opened={!!id}
      {...props}
    >
      <p className="text-dark-400">
        You are replacing stem:{" "}
        <span className="p-1 px-2 -m-1 rounded font-bold bg-indigo-600 bg-opacity-25">
          {replacingStem?.name}
        </span>{" "}
        . Upload a file to replace it.
      </p>

      <Dropzone
        loading={uploading.done !== uploading.total}
        acceptedFileTypes={["audio/*"]}
        allowMultiple={false}
        onUpload={handleUploadFiles}
      />
    </Modal>
  );
};

export { ReplaceStem };
