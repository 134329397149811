import { UpdateMouseDto } from "@server/modules/project/collaborator/dto/update-mouse.dto";
import { User } from "@server/entities";
import { debounce } from "lodash";
import { getRecoil, setRecoil } from "src/contexts/RecoilNexus";
import { Controller, PartialSetterOrUpdater } from "../helpers/controller";
import { CollabState } from "./collab.atom";
import { collabState } from "./collab.selector";

export class CollabController extends Controller {
  private readonly _collab: () => CollabState;
  private readonly setCollab: PartialSetterOrUpdater<CollabState>;
  private readonly debouncedUpdateMice: (data: UpdateMouseDto) => void;

  get collab() {
    return this._collab();
  }

  constructor() {
    super();
    this._collab = () => getRecoil(collabState);
    this.setCollab = (state) => setRecoil(collabState, state);

    this.debouncedUpdateMice = debounce(async (data) => {
      // Emit socket event
      this.socket?.emit("collab:mice", data);
    }, 50);
  }

  sendMouse(user: User, x: number, y: number) {
    if (!this.state.track.project) return;

    this.debouncedUpdateMice({
      projectId: this.state.track.project.id,
      user: {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
      } as User,
      x,
      y,
    });
  }

  receiveMouse(updateMouseDto: UpdateMouseDto) {
    this.setCollab((state) => {
      if (state.mice.some((mouse) => mouse.user.id === updateMouseDto.user.id))
        return {
          mice: state.mice.map((mouse) =>
            mouse.user.id === updateMouseDto.user.id ? updateMouseDto : mouse
          ),
        };

      return { mice: [...state.mice, updateMouseDto] };
    });
  }
}
