import { v4 } from "uuid";

export type QueuedWorker = {
  id: string;
  createWorker: () => Worker;
  onWorkerInitialized: (worker: PooledWorker) => void;
  onMessage: (e: MessageEvent) => void;
  onError?: (e: ErrorEvent) => void;
  onMessageError?: (e: MessageEvent) => void;
  onFinish?: () => void;
};

export type PooledWorker = {
  id: string;
  worker: Worker;
  startedAt?: Date;
};

export class Pool {
  private workers: PooledWorker[] = [];
  private queue: QueuedWorker[] = [];
  private timeout: NodeJS.Timeout | null = null;
  constructor(private maxWorkers: number) {}

  /**
   * Clear dangling workers every 5 seconds
   */
  clearDanglingWorkers() {
    if (this.timeout) return;
    this.timeout = setInterval(() => {
      if (this.workers.length === 0) {
        if (this.timeout) {
          clearTimeout(this.timeout);
          this.timeout = null;
        }
        return;
      }

      const danglingWorkers = this.workers.filter(
        (worker) =>
          worker.startedAt && Date.now() - worker.startedAt.getTime() > 5000
      );

      danglingWorkers.forEach((worker) => {
        this.terminateWorker(worker.id).catch();
      });
    }, 5000);
  }

  /**
   * Add a worker to the pool
   * @param worker
   */
  addWorker(worker: Omit<QueuedWorker, "id">) {
    const queuedWorker: QueuedWorker = {
      id: v4(),
      ...worker,
    };

    this.queue.push(queuedWorker);
    this.processQueue();
    return queuedWorker;
  }

  /**
   * Process the queue
   */
  private processQueue() {
    if (this.queue.length === 0) return;
    if (this.workers.length >= this.maxWorkers) return;

    this.clearDanglingWorkers();

    const queuedWorker = this.queue.pop();
    if (!queuedWorker) return;

    const worker = queuedWorker.createWorker();
    const pooledWorker: PooledWorker = {
      id: queuedWorker.id,
      worker,
      startedAt: new Date(),
    };
    this.workers.push(pooledWorker);

    worker.onmessage = async (e) => {
      queuedWorker.onMessage(e);
      await this.terminateWorker(pooledWorker.id);
      queuedWorker.onFinish && queuedWorker.onFinish();
    };

    worker.onerror = async (e) => {
      queuedWorker.onError && queuedWorker.onError(e);
      await this.terminateWorker(pooledWorker.id);
      queuedWorker.onFinish && queuedWorker.onFinish();
    };

    worker.onmessageerror = async (e) => {
      queuedWorker.onMessageError && queuedWorker.onMessageError(e);
      await this.terminateWorker(pooledWorker.id);
      queuedWorker.onFinish && queuedWorker.onFinish();
    };

    queuedWorker.onWorkerInitialized(pooledWorker);
  }

  /**
   * Terminate a worker
   * @param id
   */
  async terminateWorker(id?: string | null) {
    if (!id) return;
    const workerIndex = this.workers.findIndex((worker) => worker.id === id);
    if (workerIndex === -1) return;

    await this.workers[workerIndex].worker.terminate();
    this.workers.splice(workerIndex, 1);
    this.processQueue();
  }

  /**
   * Remove from queue
   * @param id
   */
  removeFromQueue(id: string) {
    const index = this.queue.findIndex((worker) => worker.id === id);
    if (index === -1) return;
    this.queue.splice(index, 1);
  }
}
