import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { WithContext } from "../with-contexts";
import { useLocation } from "react-router";
import { quickAssists } from "./quick-assists";
import { Accordion, ThemeIcon } from "@mantine/core";
import { ActionIcon, Button, Card, Modal } from "@common/components";
import { Cross1Icon, QuestionMarkCircledIcon } from "@modulz/radix-icons";
import Joyride from "react-joyride";
import { Check, ChevronRight, Dots } from "tabler-icons-react";
import { patchUser } from "../../requests/user/user";
import { useUser } from "../UserContext";
import { User } from "@server/entities";
import { DEFAULT_COVER } from "@common/utils/constants";
import logoSvg from "@res/images/logo-v4.svg";

export interface QuickAssistContextType {
  openMenu: () => void;
}

export type QuickAssist = {
  value: string;
  heading: string;
  body: string;
  buttonText?: string;
  image?: string;
  steps?: Joyride.Step[];
  completed?: boolean;
};

const initialState = {} as QuickAssistContextType;

const QuickAssistContext = createContext<QuickAssistContextType>(initialState);
QuickAssistContext.displayName = "QuickAssistContext";

export const useQuickAssist = () => useContext(QuickAssistContext);

export type QuickAssistSectionKey =
  | "home"
  | "projects"
  | "project"
  | "stemviewer";

export type QuickAssistSection = QuickAssist & {
  assists: (QuickAssist | QuickAssistSection)[];
};

export const withQuickAssistContext: WithContext = (Component) => (props) => {
  const { user } = useUser();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState<Joyride.Step[]>([]);
  const [value, setValue] = useState<string | null>(null);
  const [quickAssistCompleted, setQuickAssistCompleted] = useState<
    User["quickAssistCompleted"]
  >({});
  const [firstTimeModal, setFirstTimeModal] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setSteps([]);
    setRun(false);
  }, []);

  let section: QuickAssistSectionKey | null = null;
  if (location.pathname === "/") section = "home";
  else if (location.pathname.startsWith("/projects")) section = "projects";
  else if (
    location.pathname.startsWith("/p/") &&
    location.pathname.includes("/viewer")
  )
    section = "stemviewer";
  else if (location.pathname.startsWith("/p/")) section = "project";

  const handleRun = useCallback((value: string, steps: Joyride.Step[]) => {
    setValue(value);
    setSteps(steps);
    setRun(true);
  }, []);

  const handleCallback: Joyride.Callback = useCallback(
    (data) => {
      if (data.action === "reset") {
        if (value) handleComplete(value).catch();
        if (user) setRun(false);
        setSteps([]);
      }

      if (data.action === "close" || data.action === "skip") {
        setRun(false);
        setSteps([]);
      }
    },
    [user, value]
  );

  const handleComplete = useCallback(
    async (value: string) => {
      if (!user) return;

      const { error } = await patchUser(user.id, {
        quickAssistCompleted: {
          ...user.quickAssistCompleted,
          [value]: true,
        },
      });

      if (error) return;

      setQuickAssistCompleted((quickAssistCompleted) => ({
        ...quickAssistCompleted,
        [value]: true,
      }));
      setValue(null);
    },
    [user]
  );

  const handleCloseFirstTimeModal = useCallback(async () => {
    setFirstTimeModal(false);
    if (!user) return;

    await patchUser(user.id, {
      isFirstLogin: false,
    });
  }, [user]);

  useEffect(() => {
    if (user) {
      setQuickAssistCompleted(user.quickAssistCompleted);
      setFirstTimeModal(user.isFirstLogin);
    }
  }, [user]);

  return (
    <QuickAssistContext.Provider value={{ openMenu: handleOpen }}>
      <Modal
        size="sm"
        opened={firstTimeModal}
        onClose={handleCloseFirstTimeModal}
        title={
          <div className="flex items-center gap-4">
            <h2>Welcome to Synqup!</h2>
            <img className="w-10 h-10" src={logoSvg} alt="Synqup logo" />
          </div>
        }
      >
        <p className="text-lg">
          We've put together a guide called{" "}
          <span className="text-primary-300 font-medium">Quick Assist</span> to
          help you get started. Would you like to take it?
        </p>

        <div className="flex justify-end mt-8 gap-4">
          <Button
            variant="light"
            color="gray"
            onClick={handleCloseFirstTimeModal}
          >
            Skip
          </Button>
          <Button
            onClick={() => {
              handleCloseFirstTimeModal().catch();
              setOpen(true);
            }}
            rightIcon={<ChevronRight />}
          >
            Take a tour
          </Button>
        </div>
      </Modal>

      <div className="flex gap-4">
        <div
          style={{
            width:
              open && section && quickAssists[section]
                ? "calc(100% - 24rem)"
                : "100%",
            transition: "0.3s ease-in-out width",
          }}
        >
          <Component {...props} />
        </div>

        {open && section && quickAssists[section] && (
          <div
            className="w-[24rem] mt-[77px] p-4 bg-dark-900 rounded overflow-hidden"
            style={{
              transform: !open ? "translateX(100%)" : "translateX(0)",
              transition: "0.3s ease-in-out transform",
            }}
          >
            <div
              className="relative flex items-start justify-between gap-4 p-4 -m-4 pb-2 mb-2 overflow-hidden"
              style={{
                backgroundImage: `url(${DEFAULT_COVER})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="w-[120%] h-[120%] -left-4 -top-4 bg-gradient-to-tr from-black to-transparent absolute z-0 opacity-60" />
              <h2 className="relative p-4 z-1">Quick Assist</h2>
              <ActionIcon variant="transparent" onClick={handleClose}>
                <Cross1Icon />
              </ActionIcon>
            </div>

            {quickAssists[section].heading && (
              <h3 className="p-4">{quickAssists[section].heading}</h3>
            )}
            <Accordion>
              {quickAssists[section].assists.map((assist) => (
                <Accordion.Item key={assist.value} value={assist.value}>
                  <Accordion.Control
                    icon={
                      <ThemeIcon
                        size="sm"
                        color={
                          quickAssistCompleted?.[assist.value]
                            ? "green"
                            : "gray"
                        }
                        radius="xl"
                      >
                        {quickAssistCompleted?.[assist.value] ? (
                          <Check className="w-4 h-4" />
                        ) : (
                          <Dots className="text-slate-200 w-4 h-4" />
                        )}
                      </ThemeIcon>
                    }
                  >
                    {assist.heading}
                  </Accordion.Control>
                  <Accordion.Panel>
                    <p className="text-dark-200">{assist.body}</p>

                    {assist.image && (
                      <img
                        className="w-full mb-4"
                        src={assist.image}
                        alt="gif"
                      />
                    )}

                    {assist.steps && (
                      <Button
                        size="sm"
                        onClick={() =>
                          assist.steps && handleRun(assist.value, assist.steps)
                        }
                      >
                        {assist.buttonText || "Show me"}
                      </Button>
                    )}
                  </Accordion.Panel>
                </Accordion.Item>
              ))}
            </Accordion>

            <div className="flex justify-center mt-4 mb-2 gap-4">
              <a href="https://help.synqup.com.au/contact" target="_blank">
                <Button rightIcon={<QuestionMarkCircledIcon />}>
                  Ask a question
                </Button>
              </a>
            </div>
          </div>
        )}

        <Joyride
          continuous
          spotlightClicks
          showSkipButton
          scrollToFirstStep
          run={run}
          steps={steps}
          callback={handleCallback}
          tooltipComponent={Tooltip}
          floaterProps={{
            disableAnimation: true,
            hideArrow: true,
          }}
        />
      </div>
    </QuickAssistContext.Provider>
  );
};

const Tooltip = ({
  continuous,
  index,
  step,
  size,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  isLastStep,
  skipProps,
}: Joyride.TooltipRenderProps) => (
  <Card {...tooltipProps} className="max-w-sm animate-up">
    {step.title && <h3>{step.title}</h3>}
    <p>{step.content}</p>
    <div className="flex justify-between gap-4">
      <div>
        {!continuous && (
          <Button size="sm" variant="subtle" {...closeProps} id="close">
            Done
          </Button>
        )}
        {skipProps && size > 1 && (
          <Button size="sm" variant="subtle" {...skipProps} id="skip">
            Skip
          </Button>
        )}
      </div>

      <div className="flex-1 justify-end flex gap-4">
        {index > 0 && (
          <Button
            size="sm"
            variant="light"
            color="gray"
            {...backProps}
            id="back"
          >
            Back
          </Button>
        )}
        {continuous && (
          <Button
            size="sm"
            {...primaryProps}
            id="next"
            onClick={(ev) => {
              if (step.hideCloseButton) {
                const el = document.querySelector(step.target as string);
                if (el && (el as HTMLButtonElement).click)
                  (el as HTMLButtonElement).click();

                return setTimeout(() => {
                  primaryProps.onClick(ev);
                }, 500);
              }

              primaryProps.onClick(ev);
            }}
          >
            {isLastStep ? "Done" : "Next"}
          </Button>
        )}
      </div>
    </div>
  </Card>
);
