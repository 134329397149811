import { defaults } from "@common/components/Menu";
import React, { useCallback } from "react";
import { Menu as MantineMenu, MenuProps } from "@mantine/core";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";

const DotsMenu = ({
  className,
  vertical,
  ...props
}: MenuProps & { className?: string; vertical?: boolean }) => {
  return (
    <MantineMenu
      withinPortal
      {...defaults}
      {...props}
      width={props.width || 192}
    >
      <MantineMenu.Target>
        <button
          onClick={useCallback((e) => e.stopPropagation(), [])}
          className={`rounded px-1.5 pt-2 pb-1.5 hover:bg-white hover:bg-opacity-10 transition ${className}`}
        >
          {vertical ? (
            <BsThreeDotsVertical className="w-4 h-4 fill-white stroke-white" />
          ) : (
            <BsThreeDots className="w-5 h-4 fill-white stroke-white" />
          )}
        </button>
      </MantineMenu.Target>

      {props.children}
    </MantineMenu>
  );
};

DotsMenu.Item = MantineMenu.Item;
DotsMenu.Divider = MantineMenu.Divider;
DotsMenu.Dropdown = MantineMenu.Dropdown;
DotsMenu.Target = MantineMenu.Target;
DotsMenu.Label = MantineMenu.Label;
DotsMenu.displayName = MantineMenu.displayName;

export { DotsMenu };
