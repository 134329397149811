import { useRef } from "react";
import _useSWR, { BareFetcher, useSWRConfig } from "swr";
import { HttpExceptionClient } from "./fetch-methods";
import querystring from "query-string";
import { PublicConfiguration } from "swr/dist/types";

const fetcher = async (...args: Parameters<typeof fetch>) => {
  const res = await fetch(...args);

  if (!res.ok) {
    const error: HttpExceptionClient = await res.json();
    const transformError: { [key: string]: any } = new Error(error.message);
    transformError["response"] = error;
    throw transformError;
  }

  return res.json();
};

/**
 * Use default SWR, typed, with error response
 * @param url
 * @param params
 */
export function useSWR<T>(url: string, params?: { [key: string]: any }) {
  let _params = "";
  if (params) _params = `?${querystring.stringify(params)}`;

  const _url = `/api${url}${_params}`;

  return _useSWR<T, { response?: HttpExceptionClient }>(_url, fetcher, {
    revalidateOnFocus: false,
  });
}

/**
 * Use SWR that does not revalidate on Focus or Reconnect
 * @param url
 * @param params
 */
export function useStaleSWR<T>(url: string, params?: { [key: string]: any }) {
  let _params = "";
  if (params) _params = `?${querystring.stringify(params)}`;

  const _url = `/api${url}${_params}`;

  const { cache } = useSWRConfig();
  const revalidationOptions: Partial<
    PublicConfiguration<any, any, BareFetcher<T>>
  > = {
    revalidateOnMount: !cache.get(_url),
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    errorRetryCount: 0,
  };

  return _useSWR<T, { response?: HttpExceptionClient }>(
    _url,
    fetcher,
    revalidationOptions
  );
}

function useStickyResult<T>(value: T) {
  const val = useRef<T>();
  if (value !== undefined) val.current = value;
  return val.current;
}

export function useStickyStaleSWR<T>(...args: Parameters<typeof useStaleSWR>) {
  const swr = useStaleSWR<T>(...args);
  return { ...swr, data: useStickyResult(swr.data) };
}

export function useStickySWR<T>(...args: Parameters<typeof useSWR>) {
  const swr = useSWR<T>(...args);
  return { ...swr, data: useStickyResult(swr.data) };
}
