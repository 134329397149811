import { Avatar, Button, Card, Input, RichText } from "@common/components";
import { CONTROLS } from "@common/utils/editor";
import useAsyncForm from "@common/utils/useAsyncForm";
import { CreateNoteDto } from "@server/modules/project/note/dto";
import React from "react";
import { useUser } from "../../../../contexts/UserContext";
import { postNote } from "../../../../requests/project/note";
import { useProject } from "../ProjectContext";
import { useNavigate } from "react-router-dom";

const CreateNote: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { project } = useProject();

  const form = useAsyncForm<CreateNoteDto>({
    initialValues: {
      title: "",
      body: "",
      projectId: project?.id,
    },
  });

  const handleSubmit = async () => {
    const { data, error } = await form.sendForm(postNote);

    if (!error) navigate(`/p/${project?.permalink}/threads/${data?.id}`);
  };

  return (
    <div>
      <Card variant="outlined" className="grid grid-cols-1 gap-6">
        <Input
          data-testid="thread-title-input"
          label="Title"
          {...form.getInputProps("title")}
        />

        <RichText
          data-testid="thread-body-input"
          label="Body"
          controls={CONTROLS}
          users={project?.collaborators
            .map((c) => c.user)
            .filter((c) => c.id !== user?.id)}
          {...form.getInputProps("body")}
        />

        <div className="flex items-center gap-4">
          <p className="m-0">Posting as</p>
          <Avatar user={user} />
        </div>

        <Button
          data-testid="thread-create-button"
          className="ml-auto"
          onClick={handleSubmit}
          loading={form.loading}
        >
          Submit
        </Button>
      </Card>
    </div>
  );
};

export { CreateNote };
