import React from "react";
import { Menu as MantineMenu, MenuProps } from "@mantine/core";

export const defaults: Omit<MenuProps, "children"> = {
  transition: "pop-top-right",
  radius: "sm",
  position: "bottom-end",
  offset: 16,
  withArrow: true,
  arrowSize: 4,
  classNames: {
    dropdown: "bg-dark-900 bg-opacity-80 backdrop-blur-xl p-1 rounded z-[1000]",
    item: "rounded",
  },
};

const Menu: typeof MantineMenu = (props) => {
  return (
    <MantineMenu withinPortal {...defaults} {...props}>
      {props.children}
    </MantineMenu>
  );
};

Menu.Item = MantineMenu.Item;
Menu.Divider = MantineMenu.Divider;
Menu.Dropdown = MantineMenu.Dropdown;
Menu.Target = MantineMenu.Target;
Menu.Label = MantineMenu.Label;
Menu.displayName = MantineMenu.displayName;

export { Menu };
