import { RouterTab, RouterTabs } from "@common/components";
import { Pencil2Icon } from "@modulz/radix-icons";
import React from "react";
import { Route, Routes, useParams } from "react-router";
import { useProject } from "./ProjectContext";
import { CogIcon, HomeIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { ProjectNote } from "./notes/ProjectNote";
import { CreateNote } from "./notes/CreateNote";
import { ProjectNotes } from "./notes/ProjectNotes";
import { ProjectCollaborators } from "./collaborators/ProjectCollaborators";
import { ProjectHotbar } from "./home/ProjectHotbar";
import { Skeleton } from "@mantine/core";
import { ProjectFiles } from "./files/ProjectFiles";
import { Books } from "tabler-icons-react";
import { Helmet } from "react-helmet";
import { ProjectSettings } from "./settings/ProjectSettings";
import { ProjectMixdown } from "./home/ProjectMixdown";
import { ProjectHeader } from "./home/ProjectHeader";
import useAsyncForm from "@common/utils/useAsyncForm";
import { UpdateProjectDto } from "@server/modules/project/project/dto";
import { ProjectCover } from "./home/ProjectCover";
import { FormActionButtons } from "@common/components/FormActionButtons";
import {
  updateProject,
  updateProjectPost,
} from "../../../requests/project/project";
import { usePrompt } from "@common/utils/use-prompt";
import { ProjectDescription } from "./home/ProjectDescription";
import { ProjectSocialIcons } from "./home/ProjectSocialIcons";
import { ProjectComments } from "./tasks/ProjectComments";

export const ProjectView = () => {
  const { tab } = useParams<{ tab?: string }>();
  const { loading, project, view, mutate } = useProject();
  const form = useAsyncForm<UpdateProjectDto>({
    initialValues: project as UpdateProjectDto,
  });

  usePrompt(
    "You will lose your changes. Remember to save!",
    form.isDirty() && !form.isDirty("permalink")
  );

  const tabs: RouterTab[] = [
    {
      home: true,
      value: "home",
      label: "Home",
      icon: <HomeIcon className="w-5 h-5" />,
      "data-testid": "project-home-tab",
      component: (
        <>
          {project && <ProjectMixdown form={form} />}
          <ProjectDescription
            form={form}
            data-testid="project-description-home"
          />
        </>
      ),
    },
    {
      value: "threads",
      label: "Threads",
      icon: <Pencil2Icon className="w-5 h-5" />,
      "data-testid": "project-thread-tab",
      "data-quick-assist-id": "project-thread-tab",
      component: (
        <Routes>
          <Route path=":noteId" element={<ProjectNote />} />
          <Route path="create" element={<CreateNote />} />
          {project && <Route path="" element={<ProjectNotes />} />}
        </Routes>
      ),
    },
    {
      value: "media-library",
      label: "Media Library",
      icon: <Books className="w-5 h-5" />,
      "data-testid": "project-media-library-tab",
      "data-quick-assist-id": "project-media-library-tab",
      component: <ProjectFiles />,
    },
    {
      value: "collab",
      label: "Collaborators",
      icon: <UserGroupIcon className="w-5 h-5" />,
      "data-testid": "project-collaborators-tab",
      "data-quick-assist-id": "project-collaborators-tab",
      component: <ProjectCollaborators />,
    },
  ];

  const handleSave = async () => {
    if (!project) return;

    const permalinkChanged = form.values.permalink !== project.permalink;

    const { error } = await form.sendForm(
      (values) =>
        values.file
          ? updateProject(project.id, values)
          : updateProjectPost(project.id, values),
      {
        dirtyOnly: true,
      }
    );

    if (error) return;

    if (permalinkChanged)
      window.location.replace(`/p/${form.values.permalink}/settings`);

    await mutate();
  };

  if (view === "admin")
    tabs.push({
      value: "settings",
      label: "Settings",
      icon: <CogIcon className="w-5 h-5" />,
      component: <ProjectSettings form={form} />,
      "data-testid": "project-settings-tab",
    });

  return (
    <div className="container max-w-[84rem]">
      <Helmet>
        <title>{project?.name || "Loading..."} | Synqup</title>
      </Helmet>

      {project && <ProjectSocialIcons form={form} />}
      {project && <ProjectCover form={form} />}

      <div className="flex flex-row items-center gap-8 mb-8">
        {!loading && <ProjectHeader form={form} />}

        {!loading && view !== "viewer" ? <ProjectHotbar /> : null}
      </div>

      {project?.permalink ? (
        view !== "viewer" ? (
          <>
            <RouterTabs
              basePath={`/p/${project.permalink}`}
              defaultValue={"home"}
              tabs={tabs}
            />
          </>
        ) : (
          <>
            <ProjectMixdown form={form} />
            <ProjectDescription
              data-testid="project-description-home"
              form={form}
            />
          </>
        )
      ) : (
        <>
          <Skeleton visible width="100%" height={50} />
          <br />
          <Skeleton visible width="100%" height={100} />
        </>
      )}

      {view !== "viewer" && (
        <FormActionButtons
          data-testid="project-save"
          form={form}
          onSave={handleSave}
        />
      )}

      {(!tab || tab === "home") && project?.id && (
        <ProjectComments projectId={project.id} />
      )}
    </div>
  );
};
