import React from "react";
import { Channel } from "@server/entities/social/channel.entity";
import { Avatar } from "@common/components";
import { User } from "@server/entities";
import { Badge } from "@mantine/core";
import dayjs from "dayjs";

interface MessageItemProps {
  channel?: Pick<Channel, "latestMessage" | "lastActivity">;
  otherUser?: User;
  unreadCount: number;
}

export const MessageItem: React.FC<MessageItemProps> = ({
  channel,
  otherUser,
  unreadCount,
}) => {
  function getTimeFromNow(givenDate: Date): string {
    const currentDate: Date = new Date();
    const timeDiff: number = currentDate.getTime() - givenDate.getTime();

    const seconds: number = Math.floor(timeDiff / 1000);
    const minutes: number = Math.floor(seconds / 60);
    const hours: number = Math.floor(minutes / 60);
    const days: number = Math.floor(hours / 24);
    const weeks: number = Math.floor(days / 7);
    const years: number = Math.floor(weeks / 52);

    if (years > 0) {
      return `${years} y`;
    } else if (weeks > 0) {
      return `${weeks} w`;
    } else if (days > 0) {
      return `${days} d`;
    } else if (hours > 0) {
      return `${hours} h`;
    } else if (minutes > 0) {
      return `${minutes} m`;
    } else {
      return `${seconds} s`;
    }
  }

  return (
    <div className="flex items-center">
      <Avatar user={otherUser} />
      <div>
        <p className="text-md mx-5 my-0 span:text-white">
          <span data-testid="message-item-username">
            {otherUser?.displayName}
          </span>
        </p>
        {channel && (
          <p className="text-md mx-5 text-dark-300 truncate w-72">
            {channel.latestMessage}{" "}
            <span className="text-xs text-gray-500 ml-2">
              {getTimeFromNow(dayjs(channel.lastActivity).toDate())}
            </span>
          </p>
        )}
      </div>

      {unreadCount > 0 && (
        <Badge
          data-testid="unread-count"
          className="absolute right-6 pb-[1px] bg-red-600 bg-opacity-70"
          size="sm"
          variant="filled"
        >
          {unreadCount}
        </Badge>
      )}
    </div>
  );
};
