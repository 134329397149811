import {
  genericSelector,
  RecoilStates,
  useSelector,
} from "../helpers/selectors";
import {
  exportingState,
  loadingState,
  replaceState,
  stemsState,
  StemState,
  updatesQueueState,
  uploadingState,
} from "./stem.atom";

export const states: RecoilStates<StemState> = {
  loading: loadingState,
  stems: stemsState,
  uploading: uploadingState,
  exporting: exportingState,
  replace: replaceState,
  updatesQueue: updatesQueueState,
};

export const stemState = genericSelector("stemState", states);

export function useStemSelector<K extends keyof StemState>(
  keys: K[] = []
): Pick<StemState, K> {
  return useSelector(keys, states);
}
