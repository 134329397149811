import { useDelete, useGet, usePatch } from "../helpers";
import { UpdateMixdownDto } from "@server/modules/project/mixdown/dto/update-mixdown.dto";
import { Markup } from "@server/entities";

export const updateMixdown = (id: string, data: UpdateMixdownDto) =>
  usePatch(`/project/mixdown/${id}`, data);

export const deleteMixdown = (id: string) =>
  useDelete(`/project/mixdown/${id}`);

export const getMixdownMarkups = (id: string) =>
  useGet<Markup[]>(`/project/mixdown/${id}/markups`);
