import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { WithContext } from "../with-contexts";
import { MediaLibrary } from "./MediaLibrary";
import { File } from "@server/entities";
import { deleteFile, uploadFile, useFiles } from "../../requests/file";
import { removeEmpty } from "@common/utils/remove-nulls";
import { QueryFileDto } from "@server/modules/file/dto/query-file.dto";
import { Modal } from "@common/components";
import { notification } from "@common/utils/notification";
import Confirm from "@common/components/Confirm";
import { Trash } from "tabler-icons-react";
import { PaginatedListDto } from "@server/dto/paginated-list.dto";
import { KeyedMutator } from "swr";
import { ShareModal } from "./components/ShareModal";
import { uniq, uniqBy } from "lodash";
import { StringParam, useQueryParams } from "use-query-params";
import { useForceUpdate } from "@mantine/hooks";
import { UploadFileDto } from "@server/modules/file/dto/upload-file.dto";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  currentUploadState,
  percentState,
  totalState,
  uploadedState,
  uploadingState,
} from "./media-library.atom";
import { UploadProgressModal } from "./components/UploadProgressModal";

export interface MediaLibraryContextType {
  loading: boolean;
  openMediaLibrary: (
    type?: File["type"],
    callback?: (file: File) => void
  ) => void;
  closeMediaLibrary: () => void;
  checked: File[];
  isFileChecked: (id: string) => boolean;
  onFileDelete: (file: File) => void;
  onBulkDelete: () => void;
  onFileChecked: (id: string) => void;
  onFilesShare: (files: File[]) => void;
  files?: PaginatedListDto<File>;
  mutate: KeyedMutator<any>;
  query: QueryFileDto;
  setQuery: Dispatch<SetStateAction<QueryFileDto>>;
  uploadFiles: (uploads: UploadFileDto[]) => Promise<void>;
}

const initialState = {} as MediaLibraryContextType;

const MediaLibraryContext =
  createContext<MediaLibraryContextType>(initialState);
MediaLibraryContext.displayName = "MediaLibraryContext";

export const useMediaLibrary = () => useContext(MediaLibraryContext);

export const withMediaLibraryContext: WithContext = (Component) => (props) => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<QueryFileDto>({});
  const [del, setDel] = useState<File | null>(null);
  const [bulkDel, setBulkDel] = useState(false);
  const [callback, setCallback] = useState<(file: File) => void>(
    () => (file: File) => {
      //
    }
  );
  const [sharingFiles, setSharingFiles] = useState<File[]>([]);
  const [queryParams, setQueryParams] = useQueryParams({
    mediaType: StringParam,
  });
  const [checked, setChecked] = useState<File[]>([]);
  const [uploading, setUploading] = useRecoilState(uploadingState);
  const uploadQueue = useRef<UploadFileDto[]>([]);
  const setUploaded = useSetRecoilState(uploadedState);
  const setTotal = useSetRecoilState(totalState);
  const setPercent = useSetRecoilState(percentState);
  const setCurrentUpload = useSetRecoilState(currentUploadState);

  const forceUpdate = useForceUpdate();
  // If the query params change, open the media library
  useEffect(() => {
    if (queryParams.mediaType) openMediaLibrary(queryParams.mediaType);
  }, [queryParams.mediaType]);

  const { data, isValidating, mutate } = useFiles(removeEmpty(query));

  const openMediaLibrary = (type?: string, callback?: (file: File) => void) => {
    if (type === "shared") setQuery({ sharedWithMe: true });
    else setQuery({ type });
    setOpen(true);
    if (callback) setCallback(() => callback);
  };

  const closeMediaLibrary = () => {
    setOpen(false);
    setCallback(() => (file: File) => {
      //
    });
    if (queryParams.mediaType) setQueryParams({ mediaType: undefined });
  };

  const handleUpload = async (uploads: UploadFileDto[]) => {
    // Prompt warning on reload
    window.onbeforeunload = () => true;

    setUploading(true);
    uploadQueue.current.push(...uploads);
    setTotal((prev) => prev + uploads.length);

    await processQueue();

    // Remove warning on reload
    window.onbeforeunload = () => null;
  };

  const processQueue = async () => {
    if (uploading) return;

    if (uploadQueue.current.length === 0) {
      setUploading(false);
      setUploaded(0);
      setTotal(0);
      setPercent(0);
      setCurrentUpload(null);
      return;
    }

    const upload = uploadQueue.current.shift();

    if (!upload) return;

    setCurrentUpload(upload);

    const { error } = await uploadFile(upload, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setPercent(percentCompleted);
      },
    });

    setUploaded((prev) => prev + 1);

    if (error) {
      notification.error(error.message);
    }

    return processQueue();
  };

  const handleFileDelete = async () => {
    if (!del) return;
    const { error } = await deleteFile(del.id);
    if (error) return notification.error(error.message);
    if (checked.some((file) => file.id === del.id)) {
      setChecked((prev) => prev.filter((f) => f.id !== del.id));
    }
    setDel(null);
    await mutate();
  };

  const handleBulkDelete = async () => {
    if (!bulkDel || !data) return;
    for (const file of checked) {
      const { error } = await deleteFile(file.id);
      if (error) return notification.error(error.message);
    }
    setBulkDel(false);
    await mutate();
  };

  const handleFileChecked = async (id: string) => {
    if (!data) return;

    if (id === "all") {
      // If all files are checked, uncheck them
      if (data.data.every((file) => checked.some((f) => f.id === file.id))) {
        setChecked((prev) =>
          prev.filter((file) => !data.data.some((f) => f.id === file.id))
        );
      }

      // If not all files are checked, check them
      else {
        setChecked((prev) => uniqBy([...prev, ...data.data], (x) => x.id));
      }

      forceUpdate();
    }

    if (checked.some((file) => file.id === id)) {
      setChecked((prev) => prev.filter((f) => f.id !== id));
    } else {
      const file = data?.data.find((f) => f.id === id);
      if (!file) return;
      setChecked((prev) => [...prev, file]);
    }
  };

  const isFileChecked = (id: string) => checked.some((file) => file.id === id);

  const deleteNumberOfAffectedUsers = uniqBy(
    data?.data
      ?.filter((f) => f.checked)
      .map((f) => f.sharedWith)
      .flat(),
    (x) => x.id
  ).length;

  const deleteAffectedProject =
    uniq(
      data?.data
        ?.filter((f) => f.checked && f.projectId)
        .map((f) => f.projectId)
    ).length || 0;

  const deleteAffects: string[] = [];
  if (deleteNumberOfAffectedUsers > 0)
    deleteAffects.push(
      `${deleteNumberOfAffectedUsers} user${
        deleteNumberOfAffectedUsers > 1 ? "s" : ""
      }`
    );

  if (deleteAffectedProject > 0)
    deleteAffects.push(
      `${deleteAffectedProject} project${deleteAffectedProject > 1 ? "s" : ""}`
    );

  return (
    <MediaLibraryContext.Provider
      value={{
        loading: isValidating,
        openMediaLibrary,
        closeMediaLibrary,
        checked,
        isFileChecked,
        onFileDelete: setDel,
        onFileChecked: handleFileChecked,
        onFilesShare: setSharingFiles,
        onBulkDelete: () => checked && checked.length > 0 && setBulkDel(true),
        uploadFiles: handleUpload,
        files: data,
        mutate,
        query,
        setQuery,
      }}
    >
      <UploadProgressModal />
      <ShareModal files={sharingFiles} onClose={() => setSharingFiles([])} />

      <Confirm
        icon={<Trash />}
        color="red"
        title="Delete file"
        content={`Are you sure you want to delete ${del?.filename}?`}
        opened={!!del}
        onClose={() => setDel(null)}
        onConfirm={handleFileDelete}
      />

      <Confirm
        icon={<Trash />}
        color="red"
        title={`Delete ${checked.length} files`}
        content={`Are you sure you want to delete these ${
          checked.length
        } files? These files will be permanently deleted${
          deleteAffects.length > 0
            ? ` which may affect ${deleteAffects.join(" and ")}`
            : "."
        }`}
        opened={bulkDel}
        onClose={() => setBulkDel(false)}
        onConfirm={handleBulkDelete}
      />

      <Modal
        styles={{ root: { zIndex: 500 } }}
        title={<h2>My files</h2>}
        opened={open}
        onClose={closeMediaLibrary}
        centered={false}
        size="xxl"
      >
        <MediaLibrary
          queryable={false}
          onFileSelect={(file) => {
            callback(file);
            setOpen(false);
          }}
        />
      </Modal>
      <Component {...props} />
    </MediaLibraryContext.Provider>
  );
};
