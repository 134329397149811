import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { User } from "@server/entities";
import { ChatBody } from "../chat/ChatBody";
import { useUser } from "../../../contexts/UserContext";
import { ChannelsList } from "../components/ChannelsList";
import { Loader } from "@mantine/core";
import { ChatInput } from "../chat/ChatInput";
import { Avatar } from "@common/components";
import { useChat } from "../../../contexts/ChatContext";
import Skeleton from "@common/components/Skeleton";
import { getChannel } from "../../../requests/social/channel";
import { withAuth } from "../../routes/ProtectedRoute";
import { Helmet } from "react-helmet";

export const Messages: React.FC = withAuth(() => {
  const { channelId } = useParams<{ channelId: string }>();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User>();
  const { user: me } = useUser();
  const { setActive } = useChat();

  useEffect(() => {
    setLoading(true);
    setUser(undefined);
    if (!channelId) return setLoading(false);

    setActive(channelId);
    getChannel(channelId)
      .then(({ data, error }) => {
        if (!data || error) return setLoading(false);
        setLoading(false);
        setUser(
          data.channelUsers?.find((user) => user.userId !== me?.id)?.user
        );
      })
      .catch(() => setLoading(false));
  }, [channelId]);

  return (
    <div className="flex h-full gap-4 p-4">
      <Helmet>
        <title>Messages | Synqup</title>
      </Helmet>

      <div className="w-16 lg:w-96">
        <h2 className="hidden lg:block">Messages</h2>
        <ChannelsList />
      </div>

      <div className="flex-1 -p-4">
        <div className="flex flex-col justify-center items-center bg-dark-900 rounded-lg overflow-hidden h-full">
          {!loading ? (
            user ? (
              <>
                <div className="bg-dark-1000 w-full p-4">
                  <div className="flex items-center gap-4">
                    <Avatar size="sm" user={user} />
                    <h3>
                      {user?.firstName} {user?.lastName}
                    </h3>
                  </div>
                </div>

                {channelId && <ChatBody channelId={channelId} user={user} />}
              </>
            ) : (
              <>
                <h3 className="flex-1 flex items-center text-center">
                  Select a chat or start a new conversation
                </h3>
              </>
            )
          ) : (
            <div className="flex-1 w-full">
              <div className="bg-dark-1000 w-full p-4">
                <div className="flex items-center gap-4">
                  <Avatar size="sm" />
                  <Skeleton visible height={16} width={70} />
                </div>
              </div>

              <div className="flex justify-center">
                <Loader size="sm" className="mx-auto my-4" />
              </div>
            </div>
          )}

          {channelId && (
            <div className="w-full p-4 relative">
              <ChatInput channelId={channelId} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
