import {
  RecoilStates,
  useSelector,
} from "../../modules/stemviewer/recoil/helpers/selectors";
import {
  audioState,
  currTimeState,
  durationState,
  loadingState,
  PlayerState,
  playingState,
  visibleState,
  volumeState,
} from "./player.atom";

export const states: RecoilStates<PlayerState> = {
  audio: audioState,
  loading: loadingState,
  visible: visibleState,
  playing: playingState,
  volume: volumeState,
  currTime: currTimeState,
  duration: durationState,
};

export function usePlayerState<K extends keyof PlayerState>(
  keys: K[] = []
): Pick<PlayerState, K> {
  return useSelector(keys, states);
}
