import React from "react";
import { Divider as MantineDivider, DividerProps } from "@mantine/core";

export const Divider: React.FC<DividerProps> = (props) => {
  let className = `border-dark-400 border-opacity-20 ${props.className}`;
  if (props.orientation === "vertical") className = `${className} mx-1 my-auto`;
  else className = `${className} mx-auto`;

  return (
    <MantineDivider
      style={{ margin: "1rem 0" }}
      {...props}
      className={className}
    />
  );
};
