import { getUserColor } from "@common/components";
import { useMantineTheme } from "@mantine/core";
import { UpdateMouseDto } from "@server/modules/project/collaborator/dto/update-mouse.dto";
import React from "react";
import { BsCursorFill } from "react-icons/bs";

interface MouseProps extends Omit<UpdateMouseDto, "projectId"> {}

const Mouse: React.FC<MouseProps> = ({ user, x, y }) => {
  const theme = useMantineTheme();

  const color = getUserColor(user);

  return (
    <div
      className="z-50 absolute transition ease-[cubic-bezier(.5,.33,.5,.66)] duration-500"
      style={{ transform: `translate(${x}px, ${y - 164}px)` }}
    >
      <BsCursorFill
        className="w-5 h-5 -rotate-90 stroke-white stroke-1"
        style={{
          fill: theme.colors[color][6],
        }}
      />
      <div
        className="rounded-sm px-2 py-0.5 text-xs font-medium shadow-lg translate-x-4 -translate-y-2"
        style={{
          background: theme.colors[color][6],
        }}
      >
        {user.firstName} {user.lastName}
      </div>
    </div>
  );
};

export { Mouse };
