import React, { useState } from "react";
import { File } from "@server/entities";
import { Badge, Image, Modal } from "@mantine/core";
import { Card } from "@common/components";
import { useMediaLibrary } from "../MediaLibraryContext";
import { FileMenu } from "./FileMenu";
import { getFilename, getFiletype } from "./FileLibrary";

interface ImageLibraryProps {
  files?: File[];
  onFileSelect?: (file: File) => void;
  projectId?: string;
}

export const ImageLibrary: React.FC<ImageLibraryProps> = ({ files }) => {
  const [viewing, setViewing] = useState<string | null>(null);
  const { onFileDelete } = useMediaLibrary();

  return (
    <div>
      {files?.length === 0 && (
        <div className="text-center text-dark-400 m-0 py-4">
          No images found
        </div>
      )}

      <Modal
        withCloseButton={false}
        size="xl"
        centered
        opened={!!viewing}
        onClose={() => setViewing(null)}
      >
        <Image src={viewing} />
      </Modal>

      <div className="grid grid-cols-4 gap-4">
        {files?.map((file) => (
          <Card
            key={file.id}
            className="bg-dark-900 p-3 rounded-sm cursor-pointer"
            onClick={() => setViewing(file.url)}
          >
            <Image
              src={file.url}
              className="opacity-70"
              height={192}
              withPlaceholder
            />

            <div className="flex items-center gap-2 justify-between mt-3">
              <p
                data-testid="image-file-name"
                className="m-0 truncate text-dark-400"
              >
                {getFilename(file)}
              </p>

              <div className="flex items-center gap-2">
                <Badge
                  data-testid="image-file-type"
                  className="truncate max-w-[4rem]"
                  size="xs"
                  color="teal"
                >
                  {getFiletype(file)}
                </Badge>

                <FileMenu file={file} onFileDelete={onFileDelete} />
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};
