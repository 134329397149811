import { Popover } from "@mantine/core";
import React, { CSSProperties, useState } from "react";
import { CirclePicker, ColorChangeHandler } from "react-color";

interface ColorPickerProps {
  className?: string;
  style?: CSSProperties;
  value: string;
  onChange: ColorChangeHandler;
  colors: string[];
  target?: React.ReactNode;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  className,
  style,
  value,
  onChange,
  colors,
  target,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Popover withinPortal opened={open} onClose={() => setOpen(false)}>
        <Popover.Target>
          <div onClick={() => setOpen(true)}>
            {target || (
              <div
                className={`w-3 h-3 rounded-full ${className}`}
                style={{
                  background: value,
                  ...style,
                }}
              />
            )}
          </div>
        </Popover.Target>

        <Popover.Dropdown>
          <CirclePicker colors={colors} onChange={onChange} />
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};

export { ColorPicker };
