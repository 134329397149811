import React from "react";
import { DotsMenu } from "@common/components";
import { File } from "@server/entities";
import { Share, Trash } from "tabler-icons-react";
import { Download } from "@mui/icons-material";
import { useMediaLibrary } from "../MediaLibraryContext";
import { downloadUrl } from "../../../modules/stemviewer/helpers/download-blob";

interface FileMenuProps {
  file: File;
  onFileDelete: (file: File) => void;
  shared?: boolean;
}

export const FileMenu: React.FC<FileMenuProps> = ({
  file,
  onFileDelete,
  shared,
}) => {
  const { onFilesShare } = useMediaLibrary();

  return (
    <DotsMenu>
      <DotsMenu.Dropdown>
        <DotsMenu.Item
          onClick={() => downloadUrl(file.url, file.filename)}
          icon={<Download className="w-4 h-4" />}
        >
          Download file
        </DotsMenu.Item>
        {!shared && (
          <DotsMenu.Item
            icon={<Share className="w-4 h-4" />}
            onClick={(e) => {
              e.stopPropagation();
              onFilesShare([file]);
            }}
          >
            Share file
          </DotsMenu.Item>
        )}
        {!shared && (
          <DotsMenu.Item
            color="red"
            icon={<Trash className="w-4 h-4" />}
            onClick={() => onFileDelete(file)}
          >
            Delete file
          </DotsMenu.Item>
        )}
      </DotsMenu.Dropdown>
    </DotsMenu>
  );
};
