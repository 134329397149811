import {
  RecoilStates,
  useSelector,
} from "../../modules/stemviewer/recoil/helpers/selectors";
import {
  currentUploadState,
  MediaLibraryState,
  percentState,
  totalState,
  uploadedState,
  uploadingState,
} from "./media-library.atom";

export const states: RecoilStates<MediaLibraryState> = {
  uploading: uploadingState,
  uploaded: uploadedState,
  currentUpload: currentUploadState,
  total: totalState,
  percent: percentState,
};

export function useMediaLibraryState<K extends keyof MediaLibraryState>(
  keys: K[] = []
): Pick<MediaLibraryState, K> {
  return useSelector(keys, states);
}
