import { axiosErrHandler } from "@common/utils/axiosErrHandler";
import {
  UpdateStemDto,
  UpdateStemsDto,
} from "@server/modules/project/stem/dto/update-stem.dto";
import { getRecoil, setRecoil } from "../../../../contexts/RecoilNexus";
import { Controller, PartialSetterOrUpdater } from "../helpers/controller";
import { debounce, uniqBy } from "lodash";
import { v4 } from "uuid";
import {
  deleteStem,
  getStems,
  updateStems,
  uploadStem,
} from "../../../../requests/project/stem";
import {
  fileToHowl,
  getBuffer,
  getStemHash,
  stemToDto,
  stemToFile,
} from "../helpers/stem";
import { StemHowl, StemState } from "./stem.atom";
import { stemState } from "./stem.selector";
import { Stem } from "@server/entities/project";
import { StemViewerState } from "../stemviewer.selector";
import { getUserColor } from "@common/components";

export class StemController extends Controller {
  private readonly _stem: () => StemState;
  private readonly setStem: PartialSetterOrUpdater<StemState>;
  private readonly debouncedUpdateStems: (
    state: StemViewerState,
    setStem: PartialSetterOrUpdater<StemState>
  ) => void;

  get stem() {
    return this._stem();
  }

  constructor() {
    super();
    this._stem = () => getRecoil(stemState);
    this.setStem = (state) => setRecoil(stemState, state);
    this.debouncedUpdateStems = debounce(
      async (
        state: StemViewerState,
        setStem: PartialSetterOrUpdater<StemState>
      ) => {
        if (!state.track.project) return;
        const updateStemsDto: UpdateStemsDto = {
          projectId: state.track.project?.id,
          stems: state.stem.updatesQueue,
        };

        // Emit socket event
        this.socket?.emit("stem:update", updateStemsDto);
        // Update stems
        await updateStems(updateStemsDto);

        setStem({ updatesQueue: [] });
      },
      2000
    );
  }

  findOne(stemId: string) {
    return this.stem.stems.find((stem) => stem.stemId === stemId);
  }

  async loadFromFile(file: File, replace?: Stem) {
    const arrayBuffer = await file.arrayBuffer();

    const newStem: Partial<UpdateStemDto & StemHowl> = {
      stemId: v4(),
      name: file.name.split(".")[0],
      mute: false,
      volume: 1,
      stereo: 0,
      buffer: await getBuffer(arrayBuffer),
      color: this.state.collab.me
        ? getUserColor(this.state.collab.me)
        : "indigo",
      index: this.stem.stems.length,
      creator: this.state.collab.me,
      ...(replace || {}),
    };

    const howl = await fileToHowl(file);
    newStem.howl = howl;
    newStem.duration = howl.duration();

    // Set max duration
    this.setState((state) => ({
      track: {
        duration:
          state.track?.duration < howl.duration()
            ? howl.duration()
            : state.track.duration,
      },
    }));

    // Set stems
    this.setStem((state) => ({
      stems: replace
        ? state.stems.map((stem) =>
            stem.stemId === replace.stemId ? newStem : stem
          )
        : [...state.stems, newStem],
    }));

    if (newStem.mute) howl.mute(newStem.mute);
    if (newStem.volume) howl.volume(newStem.volume);

    if (this.state.track.playing) {
      const currentSeek =
        this.stem.stems.find((x) => !!x.howl)?.howl.seek() ||
        this.state.track.currTime;
      if (currentSeek) {
        howl.seek(currentSeek);
        howl.play();
      }
    }

    return newStem as UpdateStemDto & StemHowl;
  }

  async loadFromServer(stem: Stem) {
    const existingStem = this.stem.stems.find(
      (_stem) => _stem.stemId === stem.stemId
    );
    if (existingStem) return;

    const newStem = {
      ...stem,
      buffer: new AudioBuffer({
        length: 1,
        numberOfChannels: 1,
        sampleRate: 44100,
      }),
      loading: true,
    };

    // Set max duration
    this.setState((state) => ({
      track: {
        duration: Math.max(state.track.duration, stem.duration),
      },
    }));

    // Set stems
    this.setStem((state) => ({
      stems: [...state.stems, newStem],
    }));
  }

  async switchVersion(stemId: string, versionId: string) {
    let stem = this.findOne(stemId);
    if (!stem) return;

    await this.update(stemId, { previewVersionId: versionId }, { local: true });

    stem.howl?.stop();
    stem.howl?.unload();

    stem = this.findOne(stemId);
    if (!stem) return;

    // Load from downloaded file, or download file and load
    if (versionId === stem.versionId && stem.currentFile) {
      await this.loadFromFile(stem.currentFile, stemToDto(stem) as Stem);
    } else if (versionId === stem.previousVersionId && stem.previousFile) {
      await this.loadFromFile(stem.previousFile, stemToDto(stem) as Stem);
    } else {
      try {
        const file = await stemToFile({
          stem: stem,
        });
        await this.loadFromFile(file, stemToDto(stem) as Stem);
        await this.update(
          stemId,
          {
            error: false,
            currentFile: versionId === stem.versionId ? file : undefined,
            previousFile:
              versionId === stem.previousVersionId ? file : undefined,
          },
          { local: true }
        );
      } catch (e) {
        console.error(e);
        await this.update(stem.stemId, { error: true }, { local: true });
      }
    }
  }

  /**
   * Updates a stem
   * @param stemId
   * @param updateStem
   * @param config
   */
  async update(
    stemId: string,
    updateStem: Partial<StemHowl>,
    config?: {
      local?: boolean;
      otherStems?: Partial<StemHowl>;
      upsertOtherStems?: boolean;
      withoutUpsert?: boolean;
    }
  ) {
    const updateOtherStems: UpdateStemDto[] = [];

    this.setStem((state) => ({
      stems: state.stems.map((stem) => {
        if (stem.stemId === stemId) {
          // Update howl
          if ("volume" in updateStem) stem.howl?.volume(updateStem.volume || 0);
          if ("stereo" in updateStem) stem.howl?.stereo(updateStem.stereo || 0);
          if ("mute" in updateStem) stem.howl?.mute(updateStem.mute || false);

          return { ...stem, ...updateStem };
        } else {
          if (!config?.otherStems) return stem;
          const otherStems = config?.otherStems;

          updateOtherStems.push({ stemId: stem.stemId, ...otherStems });

          // Update howl
          if ("volume" in otherStems) stem.howl?.volume(otherStems.volume || 0);
          if ("stereo" in otherStems) stem.howl?.stereo(otherStems.stereo || 0);
          if ("mute" in otherStems) stem.howl?.mute(otherStems.mute || false);

          return { ...stem, ...otherStems };
        }
      }),
    }));

    // Only updates in local state
    if (config?.local) return;

    // Throttle upserting of stem
    if (!config?.withoutUpsert && stemId && this.state.track.project) {
      this.pushUpdatesToQueue([
        { stemId, ...updateStem } as Stem,
        ...updateOtherStems,
      ]);
    }
  }

  pushUpdatesToQueue(updates: UpdateStemDto[]) {
    const allUpdates: UpdateStemDto[] = [...this.stem.updatesQueue, ...updates];

    const updatesQueue = uniqBy(allUpdates, "stemId").map((update) => {
      const otherUpdate = updates.find((stem) => stem.stemId === update.stemId);
      if (otherUpdate)
        return {
          ...update,
          ...otherUpdate,
        };
      else return update;
    });

    this.setStem({ updatesQueue });
    this.debouncedUpdateStems(this.state, this.setStem);
  }

  /**
   * Deletes a stem
   * @param stemId
   * @param local
   */
  async delete(stemId: string, local = false) {
    const stem = this.findOne(stemId);

    stem?.howl?.unload();

    this.setStem((state) => ({
      stems: state.stems.filter((stem) => stem.stemId !== stemId),
    }));

    this.setState((state) => ({
      markup: {
        ...state.markup,
        markups: state.markup.markups.filter(
          (markup) => markup.stem?.stemId !== stemId
        ),
      },
    }));

    // If local, do not send delete event
    if (local) return;

    await deleteStem(stemId);
    this.socket?.emit("stem:delete", { stemId });
  }

  /**
   * Fetches and loads stems from source
   */
  async fetch(signal: AbortSignal) {
    if (!this.state.track.project?.id) return;

    const { data: stems, error } = await getStems(this.state.track.project.id);

    if (error || !stems) {
      this.setStem({ loading: { done: 0, total: 0, percent: 0 } });
      return axiosErrHandler(error);
    }

    this.load(stems, signal).catch();
  }

  /**
   * Loads multiple stems from a stem document
   * @param stems
   * @param signal
   */
  async load(stems: Stem[], signal?: AbortSignal) {
    for (const stem of stems) {
      await this.loadFromServer(stem);
    }

    for (const stem of stems) {
      try {
        const file = await stemToFile({
          stem: stem,
          signal,
        });
        await this.loadFromFile(file, stem);
      } catch (e) {
        console.error(e);
        await this.update(stem.stemId, { error: true }, { local: true });
      }
    }
  }

  /**
   * Upload stems
   * @param files
   */
  async upload(files: File[]) {
    // Start uploading stems
    this.setStem({ uploading: { done: 0, percent: 0, total: files.length } });

    const stems: UpdateStemDto[] = [];

    const totalStems = this.stem.stems.length;

    // Load stems onto stemviewer
    for (const [idx, file] of files.entries()) {
      const stem = await this.loadFromFile(file);
      await this.update(
        stem.stemId,
        {
          uploading: true,
          index: totalStems + idx,
        },
        { local: true }
      );
      stems.push(stemToDto(stem));
    }

    // Upload stems
    for (const [idx, stem] of stems.entries()) {
      // Upsert stem, if project exists
      if (!this.state.track.project) return;

      this.setStem((state) => ({
        uploading: {
          ...state.uploading,
          id: stem.stemId,
          percent: 0,
        },
      }));

      const { data: newStem, error } = await uploadStem(
        {
          ...stem,
          projectId: this.state.track.project.id,
          filekey: `${this.state.track.project.id}/${stem.stemId}`,
          file: files[idx],
          hash: await getStemHash(files[idx]),
          index: totalStems + idx,
        },
        {
          onUploadProgress: (ev: ProgressEvent) =>
            this.setStem((state) => ({
              uploading: {
                ...state.uploading,
                percent: ev.loaded / ev.total,
              },
            })),
        }
      );

      if (error) {
        axiosErrHandler(error);
        continue;
      }

      // Emit stem upload
      this.socket?.emit("stem:upload", { stemId: stem.stemId });

      // Update uploading state
      this.setStem((state) => ({
        uploading: {
          ...state.uploading,
          percent: 0,
          done: state.uploading.done + 1,
        },
      }));

      // Update stem with generated id
      if (newStem)
        await this.update(
          stem.stemId,
          {
            ...newStem,
            uploading: false,
            creator: stem.creator,
          },
          { local: true }
        );
    }

    // Done uploading stems
    this.setStem({ uploading: { done: 0, total: 0, percent: 0 } });
  }

  async replace(stemId: string, file: File) {
    const stem = this.findOne(stemId);
    if (!stem) return;

    // Start uploading stems
    this.setStem({
      uploading: { done: 0, total: 1, percent: 0, id: stem.stemId },
    });

    // Replace stem
    stem.howl?.unload();
    const replacedStem = await this.loadFromFile(file, stemToDto(stem) as Stem);

    // Upsert stem, if project exists
    if (!this.state.track.project) return;

    const { data: newStem, error } = await uploadStem(
      {
        id: replacedStem.id,
        projectId: this.state.track.project.id,
        stemId,
        filekey: `${this.state.track.project.id}/${stem.stemId}`,
        file,
      },
      {
        onUploadProgress: (ev: ProgressEvent) =>
          this.setStem((state) => ({
            uploading: {
              ...state.uploading,
              percent: ev.loaded / ev.total,
            },
          })),
      }
    );

    if (error) return axiosErrHandler(error);

    if (newStem)
      await this.update(
        stem.stemId,
        {
          ...newStem,
          previewVersionId: newStem.versionId,
          file: newStem.file,
          creator: replacedStem.creator,
        },
        { local: true }
      );

    // Emit stem upload
    this.socket?.emit("stem:upload", { stemId: stem.stemId });

    // Done uploading stems
    this.setStem({ uploading: { done: 0, total: 0, percent: 0 } });
  }

  /**
   * Reorders a stem to the required index or delta
   * @param stemId
   * @param delta
   * @param index
   */
  async reorder(stemId: string, delta: number, index?: number) {
    if (!this.state.track.project) return;

    const reorderedStem = this.stem.stems.find(
      (stem) => stem.stemId === stemId
    );

    if (!reorderedStem) return;

    let finalIndex = 0;
    if (!!index) finalIndex = index;
    else finalIndex = reorderedStem.index + delta;

    const stems = this.stem.stems
      .filter((stem) => stem.stemId !== stemId)
      .sort((a, b) => a.index - b.index);

    stems.splice(finalIndex, 0, reorderedStem);

    this.pushUpdatesToQueue(
      stems.map((stem, index) => ({ stemId: stem.stemId, index }))
    );

    this.setStem({ stems: stems.map((stem, index) => ({ ...stem, index })) });
  }
}
