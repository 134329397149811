import { LoginDto, RegisterDto } from "@server/modules/auth/dto";
import { Auth0Profile, User } from "@server/entities";
import { useGet, usePost, useStaleSWR, useSWR } from "../helpers";

export const postLogin = async (data: LoginDto) =>
  usePost<null, LoginDto>("/auth/login", data);

export const postLogout = async () => usePost<null>("/auth/logout");

export const getMe = () => useStaleSWR<User>(`/auth/me`);

export const checkEmail = (email: string) =>
  useGet<boolean>(`/auth/check/email/${email}`);

export const checkUsername = (username: string) =>
  useGet<boolean>(`/auth/check/username/${username}`);

export const postRegister = async (data: RegisterDto) =>
  usePost<null, RegisterDto>("/auth/register", data);

export const postResetPassword = async () =>
  usePost<string>("/auth/auth0/reset-password");

export const postResendVerificationEmail = async (body: { email: string }) =>
  usePost<string>("/auth/auth0/resend-verification-email", body);

export const useAuth0Me = () =>
  useSWR<{ profile: Auth0Profile }>(`/auth/auth0/me`);
