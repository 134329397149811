import React from "react";
import { Textarea as MantineTextarea, TextareaProps } from "@mantine/core";
import { getInputDefaults } from "./Input";

const Textarea: React.FC<
  TextareaProps & {
    tooltip?: string;
  }
> = (props) => {
  const defaults: TextareaProps = getInputDefaults(props);

  return (
    <MantineTextarea {...defaults} {...props} className={defaults.className} />
  );
};

export { Textarea };
