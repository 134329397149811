import React, { useEffect, useMemo } from "react";
import { useProfile } from "../pages/Profile";
import { ThemeIcon, Card, useMantineTheme } from "@mantine/core";
import { CheckIcon } from "@modulz/radix-icons";
import { Hourglass } from "tabler-icons-react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { useLocation } from "react-router";

export const ProfileProgress: React.FC = ({}) => {
  const { user, tracks, isMe } = useProfile();
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") window.scrollTo(0, 0);
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return useMemo(() => {
    if (!user || !isMe) return <></>;
    if (user?.hideProfileProgress) return <></>;

    const progressCards: ProfileProgressCardProps[] = [
      // Add a profile photo
      {
        name: "Add a profile photo",
        completed: !!user.avatar,
        link: `/u/${user.username}/edit`,
      },
      // Add personal details (phoneNumber, address)
      {
        name: "Add personal details",
        completed: !!(user.address && user.phoneNumber),
        link: `/u/${user.username}/edit`,
      },
      // Add a highlight
      {
        name: "Add highlight",
        completed: !!user.highlight,
        link: `/u/${user.username}#highlight`,
      },
      // Add music to your gallery
      {
        name: "Add to your gallery",
        completed: tracks?.length !== 0,
        link: `/u/${user.username}#gallery`,
      },
      // Write your bio
      {
        name: "Write your bio",
        completed: !!user.bio,
        link: `/u/${user.username}#bio`,
      },
      // Add a cover
      {
        name: "Add a cover",
        completed: !isEmpty(user.cover),
        link: `/u/${user.username}?cover=1`,
      },
      // Add artist details (classification, genres, influences, sounds like)
      {
        name: "Add artist details",
        completed:
          user.classifications?.length !== 0 &&
          user.genres?.length !== 0 &&
          user.influences?.length !== 0 &&
          user.soundsLike?.length !== 0,
        link: `/u/${user.username}/edit`,
      },
      // Add linked accounts
      {
        name: "Add linked accounts",
        completed: !!user.linkedAccounts?.some((acc) => !isEmpty(acc.url)),
        link: `/u/${user.username}/edit`,
      },
    ];

    if (progressCards.some((card) => !card.completed))
      return (
        <div className="w-0 min-w-full overflow-x-auto">
          <div className="flex items-stretch gap-4 my-4 min-w-max mr-4">
            {progressCards.map((card, index) => (
              <ProfileProgressCard key={index} {...card} />
            ))}
          </div>
        </div>
      );

    return <></>;
  }, [user, isMe, tracks?.length]);
};

export type ProfileProgressCardProps = {
  name: string;
  completed: boolean;
  link: string;
};

const ProfileProgressCard: React.FC<ProfileProgressCardProps> = ({
  name,
  completed,
  link,
}) => {
  const theme = useMantineTheme();

  return (
    <Link to={link}>
      <Card
        className="flex flex-col justify-center items-center text-center gap-4 w-56 h-32"
        withBorder
        style={
          completed
            ? {
                background: theme.fn.rgba(theme.colors.green[7], 0.2),
              }
            : {}
        }
      >
        <ThemeIcon radius="xl" color={completed ? "green" : "indigo"}>
          {completed ? <CheckIcon /> : <Hourglass className="w-4 h-4" />}
        </ThemeIcon>
        <h4>{name}</h4>
      </Card>
    </Link>
  );
};
