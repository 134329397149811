import React, { useEffect, useState } from "react";
import { Button, Card, Input } from "@common/components";
import { Mail } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";
import { __prod__ } from "@common/utils/constants";
import {
  postResendVerificationEmail,
  useAuth0Me,
} from "../../../requests/auth/auth";
import { useUser } from "../../../contexts/UserContext";
import useAsyncForm from "@common/utils/useAsyncForm";

export const VerifyEmail: React.FC = () => {
  const navigate = useNavigate();
  const { data } = useAuth0Me();
  const { user } = useUser();
  const [resendEmail, setResendEmail] = useState(false);
  const form = useAsyncForm<{ email: string }>({
    initialValues: {
      email: "",
    },
  });

  useEffect(() => {
    if (user) return navigate("/");
    if (data && data.profile !== null) return navigate("/");
  }, [data]);

  const handleResendEmail = async () => {
    const { error } = await form.sendForm(postResendVerificationEmail, {
      successMessage: "Verification email sent!",
    });
    if (!error) setResendEmail(false);
  };

  if (resendEmail)
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-12rem)]">
        <Card>
          <div className="flex flex-col items-center justify-center p-8 space-y-4">
            <Mail className="w-10 h-10 text-purple-500" />
            <h1 className="text-3xl font-bold">Resend verification email</h1>
            <p className="text-center">
              Write your email below and we'll send you a new verification
              email.
            </p>

            <Input
              {...form.getInputProps("email")}
              className="w-full"
              type="email"
              placeholder="Email address"
              label="Email address"
              autoFocus
            />

            <div className="flex items-center gap-2">
              <Button
                color="slate"
                variant="light"
                onClick={() => setResendEmail(false)}
              >
                Cancel
              </Button>

              <Button loading={form.loading} onClick={handleResendEmail}>
                Resend email
              </Button>
            </div>
          </div>
        </Card>
      </div>
    );

  return (
    <div className="flex flex-col items-center justify-center h-[calc(100vh-12rem)]">
      <Card>
        <div className="flex flex-col items-center justify-center p-8 space-y-4">
          <Mail className="w-10 h-10 text-purple-500" />
          <h1 className="text-3xl font-bold">Verify your email</h1>
          <p className="text-center">
            We've sent you an email with a link to verify your account.
            <br /> If you don't see it, check your spam folder. Press continue
            once you&apos;ve verified your email.
          </p>
          <div className="flex items-center gap-2">
            <Button
              color="slate"
              variant="light"
              onClick={() => setResendEmail(true)}
            >
              Resend email
            </Button>
            <a
              href={`${__prod__ ? "" : "http://localhost:5001"}/api/auth/auth0`}
            >
              <Button>Continue</Button>
            </a>
          </div>

          <p className="text-dark-400">
            Or{" "}
            <a
              className="text-dark-400 underline"
              href={`${
                __prod__ ? "" : "http://localhost:5001"
              }/api/auth/logout`}
            >
              sign out
            </a>
          </p>
        </div>
      </Card>
    </div>
  );
};
