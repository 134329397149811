import React from "react";
import { useUser } from "../../../contexts/UserContext";
import { Card } from "@common/components";
import { Switch } from "@mantine/core";
import { UpdateUserDto } from "@server/modules/user/user/dto/update-user.dto";
import useAsyncForm from "@common/utils/useAsyncForm";
import { getUserByUsername, patchUser } from "../../../requests/user/user";
import { FormActionButtons } from "@common/components/FormActionButtons";
import { NotificationSettings as NotificationSettingsEntity } from "@server/entities/user/notification-settings.entity";

type NotificationSettingOption = {
  heading: string;
  description: string;
  value: keyof NotificationSettingsEntity;
};

export const NotificationSettings: React.FC = () => {
  const { user: me } = useUser();
  const { data: user } = getUserByUsername(me?.username as string);

  const form = useAsyncForm<UpdateUserDto>({
    initialValues: {
      notificationSettings: user?.notificationSettings || {},
    },
  });

  const handleSave = async () => {
    if (!user) return;
    await form.sendForm((values) => patchUser(user.id, values), {
      successMessage: "Settings updated!",
      resetInitial: true,
    });
  };

  const options: NotificationSettingOption[] = [
    {
      value: "projectInvite",
      heading: "Project Invite",
      description:
        "This notification informs you when a User has invited you to a New Project.",
    },
    {
      value: "projectUpdated",
      heading: "Project Updated",
      description:
        "This notification informs you when a Collaborator has updated Project details. This includes adding or editing comments and notes.",
    },
    {
      value: "markupAssigned",
      heading: "Markup Assigned",
      description:
        "This notification informs you when a new Markup is created and assigned to you.",
    },
    {
      value: "markupUpdated",
      heading: "Markup Updated",
      description:
        "This notification informs you when Markup details are updated.",
    },
    {
      value: "markupComments",
      heading: "Markup Comments",
      description:
        "This notification informs you when someone has commented on your Markup",
    },
    {
      value: "messages",
      heading: "Messages",
      description:
        "This notification informs you when you receive a new message.",
    },
    {
      value: "friendRequest",
      heading: "Friend Request",
      description:
        "This notification informs you when you receive a new Friend Request.",
    },
  ];

  return (
    <div className="p-10 container">
      <h2 className="mb-4">Notifications</h2>
      <p className="text-gray-400">
        Notifications are sent to{" "}
        <span className="text-indigo-400">{user?.email}</span>. Toggle the
        buttons below to choose your preferences.
      </p>

      <div className="flex flex-col gap-4">
        {options.map((opt) => (
          <Card key={opt.value}>
            <div className="flex items-center justify-between gap-4 -my-1">
              <div>
                <h4>{opt.heading}</h4>
                <p className="text-gray-400 mt-2">{opt.description}</p>
              </div>

              <Switch
                {...form.getInputProps(`notificationSettings.${opt.value}`, {
                  type: "checkbox",
                })}
              />
            </div>
          </Card>
        ))}
      </div>

      <FormActionButtons form={form} onSave={handleSave} />
    </div>
  );
};
