import React, { useEffect, useState } from "react";
import { Badge, ModalProps } from "@mantine/core";
import { File, User } from "@server/entities";
import { ActionIcon, Button, Card, Modal } from "@common/components";
import { SearchFriends } from "../../../modules/profile/components/SearchFriends";
import { uniqBy } from "lodash";
import { getFilename, getFiletype } from "./FileLibrary";
import UserItem from "@common/components/UserItem";
import { Cross1Icon } from "@modulz/radix-icons";
import { shareFiles } from "../../../requests/file";
import { useMediaLibrary } from "../MediaLibraryContext";
import { notification } from "@common/utils/notification";

interface ShareModalProps extends Omit<ModalProps, "opened"> {
  files: File[];
}

export const ShareModal: React.FC<ShareModalProps> = ({ files, ...props }) => {
  const { onFilesShare, mutate } = useMediaLibrary();
  const [user, setUser] = useState<User | null>(null);
  const [sharingUsers, setSharingUsers] = useState<User[]>(
    files.map((x) => x.sharedWith).flat()
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSharingUsers(files.map((x) => x.sharedWith).flat());
  }, [files]);

  const handleChangeUser = (user: User | null) => {
    if (!user) return;
    setUser(user);
    setSharingUsers((prev) => uniqBy([...prev, user], (x) => x.id));
  };

  const handleShare = async () => {
    setLoading(true);
    const { error } = await shareFiles({
      fileIds: files.map((file) => file.id),
      userIds: sharingUsers.map((user) => user.id),
    });

    setLoading(false);

    if (error) return notification.error(error.message);
    notification.success("Files shared!");
    onFilesShare([]);
    await mutate();
  };

  return (
    <Modal
      size="sm"
      opened={files.length > 0}
      {...props}
      title={<h2>Share files</h2>}
    >
      <p>Sharing the following files:</p>

      <div className="flex flex-col gap-2 my-4">
        {files.map((file) => (
          <Card
            key={file.id}
            className="flex justify-between items-center gap-4 p-3"
          >
            <p className="m-0">{getFilename(file)}</p>
            <Badge className="truncate max-w-[4rem]" size="xs" color="teal">
              {getFiletype(file)}
            </Badge>
          </Card>
        ))}
      </div>

      <SearchFriends
        data-testid="search-users"
        label="Share with"
        value={user}
        onChange={handleChangeUser}
      />

      <div className="flex flex-wrap gap-2 gap-y-0 mt-4">
        {sharingUsers.map((user) => (
          <div key={user.id} className="flex items-center">
            <UserItem size="xs" user={user} />

            <ActionIcon
              radius="sm"
              size="xs"
              color="dark"
              variant="transparent"
              onClick={() =>
                setSharingUsers((prev) => prev.filter((x) => x.id !== user.id))
              }
            >
              <Cross1Icon />
            </ActionIcon>
          </div>
        ))}
      </div>

      <div className="flex justify-end mt-4">
        <Button
          data-testid="share-button"
          onClick={handleShare}
          loading={loading}
        >
          Share
        </Button>
      </div>
    </Modal>
  );
};
