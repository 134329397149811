import { UpdateStemsDto } from "@server/modules/project/stem/dto/update-stem.dto";
import { Stem } from "@server/entities/project";
import { useSockets } from "../../../../contexts/SocketContext";
import { useSocketListener } from "../../../../requests/helpers/use-socket-listener";
import { useStemViewer } from "../../contexts/StemViewerContext";

export const useStemSocket = () => {
  const { svSocket: socket } = useSockets();
  const { stem } = useStemViewer().controllers;

  // Handle stem uploads
  useSocketListener(socket, "stem:upload", (data: Stem) => {
    return stem.load([data]);
  });

  // Handle stem updates
  useSocketListener(socket, "stem:update", async (data: UpdateStemsDto) => {
    for (const updateStemDto of data.stems) {
      await stem.update(updateStemDto.stemId, updateStemDto, { local: true });
    }
  });

  // Handle stem deletes
  useSocketListener(socket, "stem:delete", (stemId: string) => {
    return stem.delete(stemId, true);
  });
};
