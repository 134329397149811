import "./styles/index.scss";
import "filepond/dist/filepond.min.css";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/scrollbar";
import "swiper/scss/pagination";
import dayjs from "dayjs";
import React from "react";
import { withContexts } from "./contexts";
import { withChatContext } from "./contexts/ChatContext";
import { withSocketContext } from "./contexts/SocketContext";
import { useUser, withUserContext } from "./contexts/UserContext";
import "./modules/projects/view/ProjectContext";
import { Sidebar } from "./modules/sidebar/Sidebar";
import { Header } from "./modules/header/Header";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { withMediaLibraryContext } from "./contexts/MediaLibrary/MediaLibraryContext";
import { withQueryParamsContext } from "./contexts/QueryParamsContext";
import { AppRoutes } from "./modules/routes/AppRoutes";
import { Helmet } from "react-helmet";
import { useFavicon } from "@common/utils/use-favicon";
import { withQuickAssistContext } from "./contexts/QuickAssist/QuickAssistContext";
import { AxiomRouteLogger } from "@common/utils/axiom-route-logger";

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

export function AppComponent() {
  const { user } = useUser();
  useFavicon();

  return (
    <div className="h-screen">
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link rel="icon" sizes="48x48" href="/favicon.ico" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#040711" />
      </Helmet>
      <Header />

      <AxiomRouteLogger />

      <div className="pt-[77px] h-full overflow-y-auto overflow-x-hidden">
        {user ? (
          <div className="flex w-full h-full">
            <div
              id="scroll-container"
              className="w-full h-full overflow-auto transition-width ease-in-out duration-500"
            >
              <AppRoutes />
            </div>

            <Sidebar />
          </div>
        ) : (
          <AppRoutes />
        )}
      </div>
    </div>
  );
}

const App = withContexts(
  [
    withMediaLibraryContext,
    withChatContext,
    withSocketContext,
    withQuickAssistContext,
    withUserContext,
    withQueryParamsContext,
  ],
  AppComponent
);

export { App };
