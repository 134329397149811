import React from "react";
import { Image } from "@mantine/core";

import imageSrc from "../../res/images/redirects/401.svg";
import { HttpExceptionClient } from "../../requests/helpers";

export function Unauthorized({ error }: { error?: HttpExceptionClient }) {
  return (
    <div className="container max-w-2xl p-10 md:p-20 lg:p-32 text-center">
      <Image width={300} className="inline-block mx-auto" src={imageSrc} />
      <h2 className="text-4xl my-4">Unauthorized!</h2>
      <p className="text-xl">
        {error?.message ||
          `Sorry! You don't have the permissions to access this page!`}
      </p>
    </div>
  );
}
