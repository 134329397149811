import React from "react";
import { Image } from "@mantine/core";

import imageSrc from "../../res/images/redirects/404.svg";

export function NotFound() {
  return (
    <div className="container max-w-2xl p-10 md:p-20 lg:p-32 text-center">
      <Image width={300} className="inline-block mx-auto" src={imageSrc} />
      <h2 className="text-4xl my-4">Not found.</h2>
      <p className="text-xl">
        Sorry, the page you're looking for does not exist or has been removed.
      </p>
    </div>
  );
}
