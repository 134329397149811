import { ModalProps } from "@mantine/core";
import React from "react";
import { DropzoneProps, Modal } from "@common/components";
import Dropzone from "@common/components/Dropzone";

type UploadFilesModalProps = ModalProps &
  Partial<Omit<DropzoneProps, "onUpload">> & {
    onUpload: (file: File[]) => void;
  };

const UploadFilesModal: React.FC<UploadFilesModalProps> = ({
  maxFiles,
  allowMultiple,
  button,
  onUpload,
  ...props
}) => {
  const handleUploadFiles = async (files: File[]) => {
    onUpload && onUpload(files);
    props.onClose();
  };

  const dropzoneProps = { maxFiles, allowMultiple, button };

  return (
    <Modal {...props}>
      <Dropzone onUpload={handleUploadFiles} {...dropzoneProps} />
    </Modal>
  );
};

export { UploadFilesModal };
