import { Avatar } from "@common/components";
import { useMantineTheme } from "@mantine/core";
import { Markup } from "@server/entities/project";
import React, { useRef } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { selectedState } from "../../stemviewer/recoil/markup/markup.atom";
import {
  getMarkupColor,
  MarkupStatus,
} from "../../stemviewer/view/markup/components/MarkupStatus";
import { useNavigate } from "react-router-dom";
import { projectState } from "../../stemviewer/recoil/track";

interface MarkupCardProps {
  markup: Markup;
}

const MarkupCard: React.FC<MarkupCardProps> = ({ markup }) => {
  const navigate = useNavigate();
  const selected = useRecoilValue(selectedState);
  const setSelected = useSetRecoilState(selectedState);
  const project = useRecoilValue(projectState);
  const theme = useMantineTheme();
  const hoveredRef = useRef(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (!project) return;

    hoveredRef.current = true;
    timeoutRef.current = setTimeout(() => {
      if (hoveredRef.current) {
        const rect = document
          .getElementById(markup.id)
          ?.getBoundingClientRect();
        const trackBody = document.getElementById("track_view");

        if (!rect || !trackBody) return;

        trackBody.scroll({
          top: trackBody.scrollTop + rect.top - trackBody.clientHeight / 2,
          left: trackBody.scrollLeft + rect.left - trackBody.clientWidth / 2,
          behavior: "smooth",
        });
        setSelected(markup.id);
      }
    }, 250);
  };

  const handleMouseLeave = () => {
    if (!project) return;

    hoveredRef.current = false;
    timeoutRef.current && clearTimeout(timeoutRef.current);
  };

  const handleClick = () => {
    navigate(`/p/${markup.project?.permalink}/viewer/markup/${markup.id}`);
  };

  return (
    <div
      data-testid="markup-card"
      className="bg-dark-800 ml-1 px-4 py-3 rounded cursor-pointer"
      style={{
        boxShadow: `-0.25rem 0 0 0 ${
          theme.colors[getMarkupColor(markup.status)][5]
        }`,
        borderLeft: `1px solid ${
          markup.id === selected
            ? theme.colors[getMarkupColor(markup.status)][5]
            : theme.colors["dark"][8]
        }`,
      }}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex items-center justify-between gap-1">
        <p className="m-0 select-none">{markup.title}</p>
        <Avatar size="sm" placeholder user={markup.reporter} />
      </div>

      <MarkupStatus className="mt-1" size="xs" value={markup.status} />
    </div>
  );
};

export { MarkupCard };
