import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";
import React from "react";
import { RedDot } from "../../header/components/RedDot";
import { useUser } from "../../../contexts/UserContext";
import { Divider, Menu } from "@common/components";
import { Loader } from "@mantine/core";
import { Channel } from "@server/entities/social/channel.entity";
import { useChat } from "../../../contexts/ChatContext";
import { User } from "@server/entities";
import { Link } from "react-router-dom";
import { MessageItem } from "./MessageItem";

export const getUnreadCount = (channel?: Channel, user?: User) => {
  return (
    channel?.channelUsers?.find((v) => v.userId === user?.id)?.unreadCount || 0
  );
};

export const getOtherUser = (channel?: Channel, user?: User) =>
  channel?.channelUsers?.find((v) => v.userId !== user?.id)?.user;

const MessagesMenu: React.FC = () => {
  const { user } = useUser();
  const { open, channels, unreadCount, setActive } = useChat();

  const handleClick = (channel?: Channel) => {
    if (!channel?.id) return;
    setActive(channel.id);
    open(channel.id);
  };

  return (
    <div className="relative" data-quick-assist-id="messages-menu">
      <Menu width={380}>
        <Menu.Target>
          <div>
            {unreadCount && unreadCount > 0 ? (
              <RedDot data-testid="messages-count" count={unreadCount} />
            ) : null}
            <ChatBubbleOvalLeftEllipsisIcon className="cursor-pointer text-dark-300 w-6 h-6" />
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          <h3 className="font-bold m-2">Messages</h3>

          <Divider className="border-dark-600" />

          {channels ? (
            channels.length > 0 ? (
              channels
                .filter((channel) => getOtherUser(channel, user))
                .map((channel, index) => (
                  <Menu.Item
                    key={index}
                    sx={{
                      background:
                        getUnreadCount(channel, user) === 0
                          ? "transparent"
                          : "rgba(255,217,122,0.1)",
                      "&:hover": {
                        background:
                          getUnreadCount(channel, user) === 0
                            ? "rgba(255,255,255,0.05)"
                            : "rgba(255,217,122,0.15)",
                      },
                    }}
                    className="my-1"
                    onClick={() => handleClick(channel)}
                  >
                    <MessageItem
                      channel={channel}
                      otherUser={getOtherUser(channel, user)}
                      unreadCount={getUnreadCount(channel, user)}
                    />
                  </Menu.Item>
                ))
            ) : (
              <div>
                <p className="m-2">No new messages</p>
              </div>
            )
          ) : (
            <div className="flex justify-center my-8">
              <Loader size="sm" variant="bars" />
            </div>
          )}

          <Link to="/messages">
            <Menu.Item className="text-center text-primary-400 bg-gray-500 bg-opacity-10 hover:bg-gray-200 hover:bg-opacity-10">
              View all messages
            </Menu.Item>
          </Link>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export { MessagesMenu };
