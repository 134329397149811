import { uniq } from "lodash";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { ChatPopup } from "../modules/messages/chat/ChatPopup";
import { WithContext } from "./with-contexts";
import { useSocketListener } from "../requests/helpers/use-socket-listener";
import { useSockets } from "./SocketContext";
import { Channel } from "@server/entities/social/channel.entity";
import { useLocation } from "react-router";
import { Message } from "@server/entities/social/message.entity";
import {
  createChannel,
  useChannels,
  useUnread,
} from "../requests/social/channel";
import { notification } from "@common/utils/notification";

export interface ChatContextType {
  chats: string[];
  openUser: (userId: string) => Promise<void>;
  open: (channelId: string) => void;
  close: (channelId: string) => void;
  channels?: Channel[];
  unreadCount: number;
  mutate: () => Promise<void>;
  active: string | null;
  setActive: Dispatch<SetStateAction<string | null>>;
}

const initialState = {} as ChatContextType;

const ChatContext = createContext<ChatContextType>(initialState);
ChatContext.displayName = "ChatContext";

export const useChat = () => useContext(ChatContext);

export const withChatContext: WithContext = (Component) => (props) => {
  const [active, setActive] = useState<string | null>(null);
  const location = useLocation();
  const { appSocket } = useSockets();
  const { data: channels, mutate: mutateChannels } = useChannels();
  const { data: unreadCount, mutate: mutateUnread } = useUnread();
  const [chats, setChats] = useState<string[]>([]);

  const openUser = async (userId: string) => {
    const { data, error } = await createChannel({
      channelUsers: [{ userId }],
    });

    if (!data)
      return notification.error(error?.message || "Something went wrong");

    return open(data.id);
  };

  const open = (channelId: string) => {
    setChats((chats) => uniq([channelId, ...chats]));
  };

  const close = (channelId: string) => {
    setActive(null);
    setChats((chats) => chats.filter((_id) => _id !== channelId));
  };

  const mutate = async () => {
    await mutateChannels();
    await mutateUnread();
  };

  useSocketListener(
    appSocket,
    "message:created",
    (data: Message) => {
      mutate().then();
      if (location.pathname.startsWith("/messages")) return;
      if (data.channelId) open(data.channelId);
    },
    [location.pathname]
  );

  return (
    <ChatContext.Provider
      value={{
        chats,
        open,
        openUser,
        close,
        channels,
        unreadCount: unreadCount || 0,
        mutate,
        active,
        setActive,
      }}
    >
      {chats.map((id, index) => (
        <ChatPopup key={id} index={index} id={id} />
      ))}
      <Component {...props} />
    </ChatContext.Provider>
  );
};
