import React from "react";
import { Circle, CircleOutlined } from "@mui/icons-material";

interface RadioGroupProps<T extends { value: string }> {
  options: Array<T>;
  value: string;
  onChange: (value: string) => void;
  renderOption: (option: T, active: boolean) => React.ReactNode;
}

function RadioGroup<T extends { value: string } = any>(
  props: RadioGroupProps<T>
) {
  let { options, renderOption, value, onChange } = props;

  if (!renderOption)
    renderOption = (option, active) => (
      <div className={`radio-group-option ${active ? "active" : ""}`}>
        <CircleOutlined />
        <Circle
          className={`absolute ${
            active ? "scale-50" : "scale-0"
          } transition-transform ease-in-out duration-500 `}
        />
        <div className="ml-4">{option.value}</div>
      </div>
    );

  return (
    <div>
      {options.map((option, idx) => {
        return (
          <div
            key={idx}
            onClick={() => onChange(option.value)}
            className="cursor-pointer"
          >
            {renderOption(option, value === option.value)}
          </div>
        );
      })}
    </div>
  );
}

export default RadioGroup;
