import { Menu, Divider, Avatar, Button } from "@common/components";
import { Notification } from "@server/entities";
import React, { useState } from "react";
import { Loader } from "@mantine/core";
import { BellIcon } from "@heroicons/react/24/solid";
import { Link, useNavigate } from "react-router-dom";
import { getNotifications, seeNotifications } from "../../../requests";
import { RedDot } from "./RedDot";
import {
  acceptFriendRequest,
  rejectFriendRequest,
  useFriendship,
} from "../../../requests/social/friendship";
import { Check } from "tabler-icons-react";
import { useSocketListener } from "../../../requests/helpers/use-socket-listener";
import { useSockets } from "../../../contexts/SocketContext";
import dayjs from "dayjs";
import { useCollab } from "../../../requests/project/collaborator";

export function Notifications() {
  const { appSocket } = useSockets();
  const { data, mutate } = getNotifications();
  const navigate = useNavigate();

  const handleSee = () => setTimeout(() => seeNotifications(), 1000);

  useSocketListener(appSocket, "notification:created", async () => {
    await mutate();
  });

  return (
    <Menu
      width={380}
      onOpen={handleSee}
      onClose={mutate}
      data-quick-assist-id="notifications-menu"
    >
      <Menu.Target data-testid="notifications-icon">
        <div>
          {data && data.count > 0 ? <RedDot count={data.count} /> : <></>}
          <BellIcon className="cursor-pointer text-dark-300 w-6 h-6" />
        </div>
      </Menu.Target>

      <Menu.Dropdown>
        <h3 className="font-bold m-2">Notifications</h3>

        <Divider className="border-dark-600" />

        {data?.notifications ? (
          data.notifications.length > 0 ? (
            data.notifications.map((notif, index) => (
              <Menu.Item
                key={index}
                onClick={() => notif.url && navigate(notif.url)}
                sx={{
                  background: notif.seen
                    ? "transparent"
                    : "rgba(255,217,122,0.1)",
                  "&:hover": {
                    background: notif.seen
                      ? "rgba(255,255,255,0.05)"
                      : "rgba(255,217,122,0.15)",
                  },
                }}
                className="my-1"
              >
                {renderNotification(notif)}
              </Menu.Item>
            ))
          ) : (
            <div>
              <p className="m-2">No new notifications</p>
            </div>
          )
        ) : (
          <div className="flex justify-center">
            <Loader variant="bars" />
          </div>
        )}
      </Menu.Dropdown>
    </Menu>
  );
}

const renderNotification = (notification: Notification) => {
  switch (notification.type) {
    case "project_invite":
      return <NotifProjectInvite notification={notification} />;
    case "friend_request":
      return <NotifFriendRequest notification={notification} />;
    case "markup-due-date":
      return <NotifMarkupDue notification={notification} />;
    default:
      return <NotifDefault notification={notification} />;
  }
};

interface NotificationProps {
  notification: Notification;
}

const NotifProjectInvite: React.FC<NotificationProps> = ({ notification }) => {
  const { data } = useCollab(notification.aggregateId as string);

  return (
    <div className="flex items-center">
      <Avatar user={notification.sender} />

      <div className="mx-5">
        <p
          data-testid="notification-project-invite-body"
          className="text-md my-0 span:text-primary-400"
        >
          <span className="font-bold">
            {notification.sender?.firstName} {notification.sender?.lastName}
          </span>{" "}
          {notification.body}
        </p>

        <p className="text-xs text-gray-500">
          {dayjs(notification.date).fromNow()}
        </p>
      </div>

      <Link to={`${notification.url}`}>
        <Button
          data-testid="notification-project-invite-button"
          size="xs"
          color={data?.active ? "dark" : "primary"}
          leftIcon={data?.active ? <Check className="w-4 h-4" /> : undefined}
        >
          {data?.active ? "Joined" : "Join"}
        </Button>
      </Link>
    </div>
  );
};

const NotifFriendRequest: React.FC<NotificationProps> = ({ notification }) => {
  const [loading, setLoading] = useState(false);
  const { data, mutate } = useFriendship(notification.sender?.id as string);

  const handleAccept = async () => {
    setLoading(true);
    if (data) await acceptFriendRequest(data.id);
    await mutate();
    setLoading(false);
  };

  const handleReject = async () => {
    setLoading(true);
    if (data) await rejectFriendRequest(data.id);
    await mutate();
    setLoading(false);
  };

  return (
    <>
      <div className="flex items-center gap-4">
        <Avatar user={notification.sender} />

        <div className="flex-1">
          <p className="text-md mb-2 my-0 span:text-primary-400">
            <span className="font-bold">
              {notification.sender?.firstName} {notification.sender?.lastName}
            </span>{" "}
            {notification.body}
          </p>
          {data?.status === "accepted" ? (
            <Button
              leftIcon={<Check className="w-4 h-4" />}
              color="dark"
              variant="subtle"
              size="xs"
            >
              Accepted
            </Button>
          ) : (
            <div className="flex items-center gap-2">
              <Button
                loading={loading}
                variant="light"
                size="xs"
                onClick={handleAccept}
              >
                Accept
              </Button>
              <Button
                loading={loading}
                size="xs"
                color="dark"
                variant="light"
                onClick={handleReject}
              >
                Decline
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const NotifMarkupDue: React.FC<NotificationProps> = ({ notification }) => {
  return (
    <div className="flex items-center">
      <Avatar user={notification.sender} />

      <div className="mx-5">
        <p className="text-md my-0 span:text-primary-400">
          {notification.body}
        </p>
        <p className="text-xs text-gray-500">
          {dayjs(notification.date).fromNow()}
        </p>
      </div>
    </div>
  );
};

const NotifDefault: React.FC<NotificationProps> = ({ notification }) => {
  return (
    <div className="flex items-center">
      <Avatar user={notification.sender} />

      <div className="mx-5">
        <p
          data-testid="notification-default-body"
          className="text-md my-0 span:text-primary-400"
        >
          <span className="font-bold">
            {notification.sender?.firstName} {notification.sender?.lastName}
          </span>{" "}
          {notification.body}
        </p>
        <p className="text-xs text-gray-500">
          {dayjs(notification.date).fromNow()}
        </p>
      </div>
    </div>
  );
};
