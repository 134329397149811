import { ToolbarControl } from "@mantine/rte/lib/components/Toolbar/controls";

export const isEmpty = (body) => {
  if (!body) return true;

  const strippedBody = body.replace(/<[^>]*>/g, "").trim();

  return strippedBody === "";
};

export const CONTROLS: ToolbarControl[][] = [
  ["bold", "italic", "underline", "strike", "clean"],
  ["unorderedList", "orderedList"],
  ["link", "image", "video"],
  ["codeBlock", "blockquote"],
];
