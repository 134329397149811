import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Button,
  Divider,
  DotsMenu,
  Input,
  Select,
  Tooltip,
} from "@common/components";
import {
  Loader,
  Pagination,
  Popover,
  Progress,
  ScrollArea,
  useMantineTheme,
} from "@mantine/core";
import { File as FileEntity } from "@server/entities";
import Dropzone from "@common/components/Dropzone";
import { useFileStorage } from "../../requests/file";
import {
  Camera,
  Disc,
  File,
  Music,
  FileMusic,
  Search,
  Share,
  Trash,
} from "tabler-icons-react";
import { AudioLibrary } from "./components/AudioLibrary";
import { FileLibrary } from "./components/FileLibrary";
import { useDebouncedValue } from "@mantine/hooks";
import { ImageLibrary } from "./components/ImageLibrary";
import { useMediaLibrary } from "./MediaLibraryContext";
import { Download } from "@mui/icons-material";
import { SharedLibrary } from "./components/SharedLibrary";
import { downloadBlob } from "../../modules/stemviewer/helpers/download-blob";
import dayjs from "dayjs";
import JSZip from "jszip";
import axios from "axios";
import { formatBytes } from "@common/utils/constants";
import { QuestionMarkCircledIcon } from "@modulz/radix-icons";

interface MediaLibraryProps {
  projectId?: string;
  queryable?: boolean;
  onFileSelect?: (file: FileEntity) => void;
}

export const MediaLibrary: React.FC<MediaLibraryProps> = ({
  projectId,
  queryable,
  onFileSelect,
  ...props
}) => {
  const theme = useMantineTheme();
  const {
    files,
    mutate,
    loading,
    query,
    setQuery,
    checked,
    onBulkDelete,
    onFilesShare,
    uploadFiles,
  } = useMediaLibrary();
  const [uploading, setUploading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebouncedValue(search, 200);

  const { data: storage, mutate: mutateStorage } = useFileStorage();

  useEffect(() => {
    setQuery((query) => ({
      ...query,
      query: debouncedSearch,
      projectId,
    }));
  }, [debouncedSearch]);

  const handleUpload = async (files: File[]) => {
    setUploading(true);

    await uploadFiles(
      files.map((file) => ({ file, filetype: query.type || "file", projectId }))
    );

    mutate && (await mutate());
    await mutateStorage();
    setUploading(false);
  };

  const handleChangeType = (type?: FileEntity["type"]) => {
    if (!queryable) return;
    setQuery((query) => ({ ...query, type, page: 1, sharedWithMe: undefined }));
  };

  const handleShared = () => {
    if (!queryable) return;
    setQuery((query) => ({
      ...query,
      page: 1,
      type: undefined,
      sharedWithMe: true,
    }));
  };

  const handleDownloadSelected = async () => {
    if (!files?.data) return;
    const zip = new JSZip();

    setDownloadProgress(0);

    if (checked.length === 0) return;
    if (checked.length === 1) {
      const { data } = await axios.get(checked[0].url, {
        responseType: "blob",
      });
      downloadBlob(data, checked[0].filename);
      return;
    }

    for (const [idx, file] of checked.entries()) {
      try {
        const { data } = await axios.get(file.url, {
          responseType: "blob",
          onDownloadProgress: (e) => {
            setDownloadProgress(
              (e.loaded / e.total + idx) * (100 / checked.length)
            );
          },
        });
        zip.file(file.filename, data);
      } catch (e) {
        console.error(e);
      }
    }
    setDownloadProgress(100);

    return new Promise<void>((resolve) => {
      zip.generateAsync({ type: "blob" }).then((content) => {
        downloadBlob(content, `${dayjs().format("YYYYMMDDHHmm")}-files.zip`);
        resolve();
      });
    });
  };

  // Default rendering of files
  let library = (
    <FileLibrary
      files={files?.data}
      onFileSelect={onFileSelect}
      projectId={projectId}
    />
  );

  if (query.sharedWithMe)
    library = <SharedLibrary files={files?.data} onFileSelect={onFileSelect} />;

  // Rendering for audio clips
  if (
    query.type === "audio" ||
    query.type === "stem" ||
    query.type === "mixdown"
  )
    library = (
      <AudioLibrary
        files={files?.data}
        onFileSelect={onFileSelect}
        projectId={projectId}
      />
    );

  // Rendering for images
  if (query.type === "image")
    library = (
      <ImageLibrary
        files={files?.data}
        onFileSelect={onFileSelect}
        projectId={projectId}
      />
    );

  let acceptedFileTypes: string[] | undefined = undefined;
  if (query.type === "audio") acceptedFileTypes = ["audio/*"];
  if (query.type === "image") acceptedFileTypes = ["image/*"];

  return (
    <div
      data-testid="media-library"
      className="flex items-start gap-6"
      {...props}
    >
      <div
        className="flex flex-col gap-2 items-stretch w-40 xl:w-64 pt-6"
        data-quick-assist-id="ml-tabs"
      >
        <Button
          data-testid="all-files-tab"
          className="flex"
          variant={!query.type && !query.sharedWithMe ? "filled" : "subtle"}
          leftIcon={<File className="w-4" />}
          onClick={() => handleChangeType(undefined)}
        >
          All files
        </Button>

        <Button
          data-testid="mixdown-tab"
          className="flex"
          variant={query.type === "mixdown" ? "filled" : "subtle"}
          leftIcon={<Music className="w-4" />}
          onClick={() => handleChangeType("mixdown")}
        >
          Mixdowns
        </Button>

        <Button
          data-testid="audio-tab"
          className="flex"
          variant={query.type === "audio" ? "filled" : "subtle"}
          leftIcon={<FileMusic className="w-4" />}
          onClick={() => handleChangeType("audio")}
        >
          Audio
        </Button>

        <Button
          data-testid="stems-tab"
          className="flex"
          variant={query.type === "stem" ? "filled" : "subtle"}
          leftIcon={<Disc className="w-4" />}
          onClick={() => handleChangeType("stem")}
        >
          Stems
        </Button>

        <Button
          data-testid="images-tab"
          className="flex"
          variant={query.type === "image" ? "filled" : "subtle"}
          leftIcon={<Camera className="w-4" />}
          onClick={() => handleChangeType("image")}
        >
          Images
        </Button>

        {!projectId && (
          <Button
            data-testid="shared-tab"
            className="flex"
            variant={query.sharedWithMe ? "filled" : "subtle"}
            leftIcon={<Share className="w-4" />}
            onClick={handleShared}
          >
            Shared
          </Button>
        )}
      </div>

      <div className="flex-1 min-w-0">
        {storage && !projectId && (
          <div className="flex flex-row-reverse items-center gap-4 mb-4">
            <div className="flex items-center gap-2 text-center mb-4">
              <div>
                <h4>Storage used</h4>
                <p className="text-xs text-gray-500 -mb-4">
                  {formatBytes(
                    storage.stem + storage.audio + storage.image + storage.file,
                    1
                  )}{" "}
                  / {formatBytes(storage.total, 0)}
                </p>
              </div>

              <Tooltip
                label={
                  "This amount is not live and may take a few minutes to update."
                }
              >
                <QuestionMarkCircledIcon className="text-gray-500" />
              </Tooltip>
            </div>

            <Progress
              data-quick-assist-id="ml-storage"
              className="flex-1"
              classNames={{
                label: "text-xs font-medium",
              }}
              size={24}
              sections={[
                {
                  value: (storage.image / storage.total) * 100,
                  color: "cyan",
                  tooltip: `Images - ${formatBytes(storage.image)}`,
                  label:
                    storage.image / storage.total > 0.05 ? "Images" : undefined,
                },
                {
                  value: (storage.audio / storage.total) * 100,
                  color: "blue",
                  tooltip: `Audio - ${formatBytes(storage.audio)}`,
                  label:
                    storage.audio / storage.total > 0.05 ? "Audio" : undefined,
                },
                {
                  value: (storage.stem / storage.total) * 100,
                  color: "indigo",
                  tooltip: `Stems - ${formatBytes(storage.stem)}`,
                  label:
                    storage.stem / storage.total > 0.05 ? "Stems" : undefined,
                },
                {
                  value: (storage.file / storage.total) * 100,
                  color: "violet",
                  tooltip: `Other - ${formatBytes(storage.file)}`,
                  label:
                    storage.file / storage.total > 0.05 ? "Other" : undefined,
                },
              ]}
            />
          </div>
        )}

        {query.type !== "stem" && (
          <Dropzone
            loading={uploading}
            onUpload={handleUpload}
            acceptedFileTypes={acceptedFileTypes}
          />
        )}

        {downloadProgress !== 0 && (
          <Progress
            size="xs"
            color={theme.colors.indigo[7]}
            value={downloadProgress}
            className="mt-4"
          />
        )}

        <div className="flex items-center gap-4 relative mt-4">
          {files && checked.length > 0 && (
            <div className="absolute z-50 top-5 bg-dark-900 px-4 rounded shadow-xl flex items-center animate-opacity">
              <p data-testid="files-selected" className="m-0 mr-4">
                {checked.length} selected
              </p>

              <Divider orientation="vertical" className="h-10" />

              <ActionIcon
                loading={downloadProgress !== 0 && downloadProgress !== 100}
                variant="subtle"
                color="red"
                onClick={onBulkDelete}
              >
                <Trash className="w-4 h-4" />
              </ActionIcon>

              <Divider orientation="vertical" className="h-10" />

              <DotsMenu data-testid="selected-dots-menu" className="-mr-3">
                <DotsMenu.Dropdown>
                  <DotsMenu.Item
                    data-testid="download-selected"
                    onClick={handleDownloadSelected}
                    icon={<Download className="w-4 h-4" />}
                  >
                    Download
                  </DotsMenu.Item>
                  <DotsMenu.Item
                    data-testid="share-selected"
                    onClick={() => onFilesShare(checked)}
                    icon={<Share className="w-4 h-4" />}
                  >
                    Share
                  </DotsMenu.Item>
                </DotsMenu.Dropdown>
              </DotsMenu>
            </div>
          )}

          <Input
            className="flex-1"
            icon={loading ? <Loader size="xs" /> : <Search className="w-5" />}
            placeholder="Search files"
            value={search}
            onChange={({ target }) => {
              setSearch(target.value);
            }}
          />

          <Popover radius="xs" position="bottom-end">
            <Popover.Target>
              <Button
                size="sm"
                variant="filled"
                color={query.sortBy && query.sortDirection ? "indigo" : "dark"}
                data-quick-assist-id="ml-sort"
              >
                Sort
              </Button>
            </Popover.Target>

            <Popover.Dropdown p="xs">
              <div className="flex gap-2">
                <Select
                  className="w-24"
                  size="sm"
                  data={[
                    { label: "Name", value: "filename" },
                    { label: "Date", value: "createdAt" },
                    { label: "Size", value: "size" },
                  ]}
                  value={query.sortBy}
                  onChange={(value) =>
                    setQuery((query) => ({
                      ...query,
                      sortBy: value || undefined,
                    }))
                  }
                />

                <Select
                  className="w-36"
                  size="sm"
                  data={[
                    { label: "Ascending", value: "ASC" },
                    { label: "Descending", value: "DESC" },
                  ]}
                  value={query.sortDirection}
                  onChange={(value) =>
                    setQuery((query) => ({
                      ...query,
                      sortDirection: value || undefined,
                    }))
                  }
                />
              </div>
            </Popover.Dropdown>
          </Popover>
        </div>

        <ScrollArea
          className="mt-4"
          scrollbarSize={6}
          offsetScrollbars
          type="always"
          sx={(theme) => ({
            ".mantine-ScrollArea-thumb": {
              backgroundColor: theme.colors.primary[7],
            },
          })}
        >
          <div className="w-full min-w-[56rem]">{library}</div>
        </ScrollArea>

        <div className="flex justify-center">
          <Pagination
            className="mt-4"
            size="sm"
            page={query.page}
            onChange={(page) => setQuery((query) => ({ ...query, page }))}
            total={files?.pages || 1}
          />
        </div>
      </div>
    </div>
  );
};
