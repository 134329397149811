import { AvatarProps } from "@mantine/core";
import { User } from "../../../../server/src/entities";
import React, { MouseEventHandler } from "react";
import { Avatar } from "./Avatar";

interface UserItemProps extends AvatarProps {
  user?: User;
  className?: string;
  children?: React.ReactElement;
  showActive?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const UserItem: React.FC<UserItemProps> = (props) => {
  const {
    user,
    className,
    children,
    showActive = false,
    onClick,
    ..._props
  } = props;

  // Return nothing if user null or undefined
  if (user === null || user === undefined) return <></>;

  return (
    <div
      data-testid={`user-item-${user.id}`}
      className={`relative flex items-center p-2 my-2 rounded-xl bg-transparent transition ${
        onClick ? "hover:bg-dark-900 cursor-pointer" : ""
      } ${className}`}
      onClick={onClick ? onClick : undefined}
    >
      {user.active && showActive ? (
        <div className="absolute z-10 w-4 h-4 bg-green-500 rounded-full translate-x-8 translate-y-3 border-2 border-solid border-dark-900" />
      ) : (
        <></>
      )}

      <Avatar user={user} className="mr-4" radius="xl" alt="dp" {..._props} />

      <p className="m-0 overflow-hidden whitespace-nowrap text-ellipsis">
        {user.displayName}
      </p>

      {children}
    </div>
  );
};

export default UserItem;
