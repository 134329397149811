import React from "react";
import { ActionIcon } from "@common/components/ActionIcon";
import { Popover } from "@mantine/core";
import { AddReaction } from "@mui/icons-material";

type EmojiType = "heart" | "fire" | "party" | "cry" | "laugh" | "thumbs-up";

interface EmojiSelectProps {
  onEmojiClick: (data: { emoji: string; type: EmojiType }) => void;
}

const emojis: Array<{ emoji: string; type: EmojiType }> = [
  { emoji: "❤️", type: "heart" },
  { emoji: "🔥", type: "fire" },
  { emoji: "🎉", type: "party" },
  { emoji: "😭", type: "cry" },
  { emoji: "😂", type: "laugh" },
  { emoji: "👍", type: "thumbs-up" },
];

export const EmojiSelect: React.FC<EmojiSelectProps> = ({ onEmojiClick }) => {
  return (
    <Popover position="bottom-start">
      <Popover.Target>
        <ActionIcon className="w-10" variant="outline" radius="lg">
          <AddReaction className="w-4 h-4" />
        </ActionIcon>
      </Popover.Target>

      <Popover.Dropdown>
        <div className="flex items-center gap-1">
          {emojis.map((item) => (
            <ActionIcon
              key={item.type}
              variant="subtle"
              color="dark"
              radius="lg"
              className="pt-2 hover:scale-125 transition"
              onClick={() => onEmojiClick(item)}
            >
              {item.emoji}
            </ActionIcon>
          ))}
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};
