import { useEffect } from "react";
import { Socket } from "socket.io-client";

export function useSocketListener<T>(
  socket: Socket,
  ev: Parameters<Socket["on"]>[0],
  listener: Parameters<Socket["on"]>[1],
  deps: Array<any> = [],
  cleanup?: () => void
) {
  useEffect(() => {
    socket.on(ev, listener);

    return () => {
      socket.off(ev, listener);
      cleanup && cleanup();
    };
  }, deps);
}
