export function downloadBlob(blob: Blob, filename: string) {
  const a = document.createElement("a");
  // a.style = "display: none";
  a.href = (window.URL || window.webkitURL).createObjectURL(blob);
  a.download = filename || `${a.href}.${blob.type.split("/")[1]}`;
  a.click();
  return a;
}

export function downloadUrl(url: string, filename: string) {
  fetch(url, {
    headers: new Headers(),
    mode: "cors",
  })
    .then((response) => response.blob())
    .then((blob) => {
      downloadBlob(blob, filename);
    })
    .catch((e) => console.error(e));
}
