import React from "react";
import { getLogs } from "../../../requests/project/logs";
import { Avatar, Card } from "@common/components";
import { Badge, Timeline } from "@mantine/core";
import dayjs from "dayjs";
import { getLogColor } from "../../stemviewer/view/markup/components/MarkupLogs";
import { humanize } from "underscore.string";
import { useNavigate } from "react-router-dom";

export const RecentLogs: React.FC = () => {
  const navigate = useNavigate();
  const { data } = getLogs();

  return (
    <Card
      className="col-span-3 lg:col-span-1 p-4"
      data-quick-assist-id="recent-logs"
    >
      <div className="p-4">
        <h2 className="mb-10">Recent logs</h2>

        <Timeline
          classNames={{
            itemBody:
              "-translate-y-4 rounded-md transition cursor-pointer hover:bg-dark-900 p-2 -m-2 -mb-4",
          }}
          lineWidth={2}
          active={data?.data.length}
        >
          {data && data?.data.length !== 0 ? (
            data?.data.map((log) => (
              <Timeline.Item
                data-testid="log-item"
                key={log.id}
                bullet={<Avatar size="sm" user={log.user} />}
                bulletSize={20}
                color={getLogColor(log.type)}
                title={
                  <p className="text-gray-500 text-xs mb-0">
                    {dayjs(log.createdAt).fromNow()}
                  </p>
                }
                onClick={() =>
                  navigate(
                    `/p/${log.markup?.project?.permalink}/viewer/markup/${log.markup?.id}`
                  )
                }
              >
                <p className="mb-2 -mt-1" data-testid="log-body">
                  {log.user?.displayName} {log.body}
                </p>
                <div className="flex items-center gap-1">
                  <Badge
                    size="xs"
                    variant="light"
                    radius="sm"
                    color="dark"
                    className="bg-gray-800"
                  >
                    {log.markup?.project?.name}
                  </Badge>
                  <Badge
                    size="xs"
                    variant="dot"
                    radius="sm"
                    color={getLogColor(log.type)}
                  >
                    {humanize(log.type)}
                  </Badge>
                </div>
              </Timeline.Item>
            ))
          ) : (
            <p className="text-gray-500 -m-3 -mt-6">No logs yet!</p>
          )}
        </Timeline>
      </div>
    </Card>
  );
};
