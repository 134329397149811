import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useMantineTheme } from "@mantine/core";

interface PlaceholderProps {
  className?: string;
  icon?: React.ReactNode;
  content?: string;
  dropzone?: boolean;
  onDrop?: (file: File) => any;
  onClick?: () => any;
  disabled?: boolean;
}

const Placeholder: React.FC<PlaceholderProps> = (props) => {
  const theme = useMantineTheme();
  const { className, icon, content, dropzone, disabled, onDrop, onClick } =
    props;
  const [dragging, setDragging] = useState(false);

  let rootProps = {},
    inputProps = {};

  if (dropzone) {
    const handleDrop = (files: File[]) => {
      setDragging(false);
      if (onDrop) onDrop(files[0]);
    };

    const { getInputProps, getRootProps } = useDropzone({
      noKeyboard: true,
      accept: ".png, .jpg, .jpeg, .webp",
      // onDrop: handleDrop,
      onDragEnter: () => setDragging(true),
      onDragLeave: () => setDragging(false),
      onDropAccepted: handleDrop,
    });

    rootProps = getRootProps();
    inputProps = getInputProps();
  }

  return (
    <div
      className={`flex flex-col items-center text-center justify-center w-full h-40 rounded-xl transition ${className}`}
      style={{
        backgroundColor: dragging ? theme.colors.dark[7] : theme.colors.dark[6],
        border: dragging
          ? "2px dashed rgba(255,255,255,0.2)"
          : "0px dashed rgba(255,255,255,0.2)",
      }}
      {...rootProps}
    >
      {dropzone ? (
        <input data-testid="placeholder-dropzone" {...inputProps} />
      ) : null}

      <div
        className={`transition ease-in-out hover:scale-105 hover:text-primary-400 cursor-pointer`}
        onClick={onClick}
      >
        {icon}
        <p>{content}</p>
      </div>
    </div>
  );
};

export default Placeholder;
