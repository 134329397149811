import { ActionIcon, Button, Card, Tooltip } from "@common/components";
import { LinkIcon, MicrophoneIcon } from "@heroicons/react/24/solid";
import { useClipboard } from "@mantine/hooks";
import { Warning } from "@mui/icons-material";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useProject } from "../ProjectContext";
import { useNavigate } from "react-router-dom";
import { ExitIcon } from "@modulz/radix-icons";
import Confirm from "@common/components/Confirm";
import { removeCollab } from "../../../../requests/project/collaborator";
import { useUser } from "../../../../contexts/UserContext";
import { notification } from "@common/utils/notification";

const ProjectHotbar: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { project, view } = useProject();
  const clipboard = useClipboard({ timeout: 500 });
  const [copied, setCopied] = useState(false);
  const [leave, setLeave] = useState(false);

  const animations = (delay) => ({
    initial: { opacity: 0, translateY: "10px" },
    animate: { opacity: 1, translateY: "0px" },
    transition: { duration: 0.3, delay: delay },
  });

  const handleLeave = async () => {
    if (!project?.id) return;
    const collab = project.collaborators.find(
      (collab) => collab.userId === user?.id
    );
    if (!collab) return;

    setLeave(false);

    const { error } = await removeCollab(collab.id);
    if (error) return notification.error(error.message);
    navigate("/projects");
  };

  const handleCopyPermalink = () => {
    clipboard.copy("https://www.synqup.com.au/p/" + project?.permalink);
    setCopied(true);
  };

  const handleStemViewer = () => {
    navigate(`/p/${project?.permalink}/viewer`);
  };

  return (
    <div className="hidden md:block">
      <Confirm
        color="red"
        icon={<Warning fontSize="medium" />}
        title="Leave this project?"
        content="Are you sure you want to leave this project? You will lose access to all associated data."
        opened={leave}
        onClose={() => setLeave(false)}
        onConfirm={handleLeave}
      />

      <Card className="flex gap-4 justify-center px-12 shadow-2xl">
        <Tooltip label="Leave project">
          <motion.div {...animations(0)}>
            <ActionIcon
              data-testid="leave-project"
              data-quick-assist-id="leave-project"
              color="cyan"
              className="my-4"
              onClick={() => setLeave(true)}
            >
              <ExitIcon className="w-5 h-5" />
            </ActionIcon>
          </motion.div>
        </Tooltip>

        <Tooltip
          label={copied ? "Link copied ✓" : "Copy permalink to clipboard"}
          positionDependencies={[copied]}
        >
          <motion.div {...animations(0.1)}>
            <ActionIcon
              data-testid="copy-permalink"
              data-quick-assist-id="copy-permalink"
              className="my-4"
              onClick={handleCopyPermalink}
            >
              <LinkIcon className="w-5 h-5" />
            </ActionIcon>
          </motion.div>
        </Tooltip>

        <motion.div {...animations(0.2)}>
          <Button
            data-testid="launch-stemviewer"
            data-quick-assist-id="launch-stemviewer"
            leftIcon={<MicrophoneIcon className="w-5 h-5" />}
            variant="gradient"
            className="my-4"
            size="xs"
            onClick={handleStemViewer}
          >
            Launch Stemviewer
          </Button>
        </motion.div>
      </Card>
    </div>
  );
};

export { ProjectHotbar };
