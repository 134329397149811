import { Button, Input } from "@common/components";
import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getProjectNotes } from "../../../../requests/project/note";
import { useProject } from "../ProjectContext";
import { NoteListItem } from "./NoteListItem";
import { Note } from "@server/entities/project";

const ProjectNotes: React.FC = () => {
  const { project } = useProject();
  const { data: notes } = getProjectNotes(project?.id as string);

  const [query, setQuery] = useState("");

  const notesFilter = (note: Note) =>
    note.title?.toLowerCase().includes(query.toLowerCase());

  return (
    <div>
      <div className="flex items-center gap-4">
        <Input
          data-testid="search-threads"
          className="flex-1"
          placeholder="Search threads"
          value={query}
          onChange={({ target: { value } }) => setQuery(value)}
        />

        <Link to={`/p/${project?.permalink}/threads/create`}>
          <Button
            data-testid="project-thread-create"
            data-quick-assist-id="project-thread-create"
          >
            Start a thread
          </Button>
        </Link>
      </div>

      <div className="flex flex-col gap-2 mt-4 p-0">
        {notes && notes.filter(notesFilter).length > 0 ? (
          notes
            ?.filter(notesFilter)
            .map((note) => <NoteListItem key={note.id} note={note} />)
        ) : (
          <div className="text-center text-indigo-400 p-6">
            <DocumentMagnifyingGlassIcon className="w-10 h-10" />
            <p>No threads found!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export { ProjectNotes };
