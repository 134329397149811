import React from "react";
import { Divider } from "./Divider";
import { Button } from "./Button";
import { Modal } from "./Modal";

interface ConfirmProps {
  loading?: boolean;
  title: string;
  icon?: React.ReactNode;
  color?: string;
  content: string;
  opened: boolean;
  onClose: (props?: any) => any;
  onConfirm: (props?: any) => any;
}

const Confirm: React.FC<ConfirmProps> = (props) => {
  const { loading, title, icon, color, content, opened, onClose, onConfirm } =
    props;

  return (
    <Modal opened={opened} onClose={onClose} size="sm">
      <h2 className={`flex items-center gap-2 text-${color}-400`}>
        {icon}
        {title}
      </h2>

      <Divider />

      <p>{content}</p>

      <Button
        loading={loading}
        onClick={onConfirm}
        className="float-right ml-2"
        color={color}
        size="sm"
        data-testid="confirm-button"
      >
        Confirm
      </Button>

      <Button
        onClick={onClose}
        variant="subtle"
        className="float-right"
        color={color}
        size="sm"
      >
        Cancel
      </Button>
    </Modal>
  );
};

export default Confirm;
