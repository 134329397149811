import { Card } from "@common/components";
import UserItem from "@common/components/UserItem";
import React, { useEffect } from "react";
import { useChat } from "../../contexts/ChatContext";
import { useFriends } from "../../requests/social/friendship";
import { Friendship } from "@server/entities/social/friendship.entity";
import { notification } from "@common/utils/notification";

const FriendsList: React.FC = () => {
  const { openUser } = useChat();
  const { data: friends, mutate } = useFriends();

  const handleClick = async (friendship: Friendship) => {
    if (!friendship.destUser) return notification.error("Something went wrong");
    await openUser(friendship.destUser.id);
  };

  // Mutate the friends chat every minute
  useEffect(() => {
    const interval = setInterval(async () => {
      await mutate();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Card className="flex-1 overflow-y-auto" data-quick-assist-id="connections">
      <h3>Connections</h3>

      {friends?.data.length && friends?.data.length > 0 ? (
        friends?.data.map((friendship, index) => (
          <UserItem
            key={index}
            user={friendship.destUser}
            onClick={() => handleClick(friendship)}
            showActive
          />
        ))
      ) : (
        <p className="mt-4">
          No connections yet. Use the search bar to find your connections.
        </p>
      )}
    </Card>
  );
};

export { FriendsList };
