import React from "react";
import { ActionIcon, Button, Input, Tooltip } from "@common/components";
import { Link1Icon } from "@modulz/radix-icons";
import { SelectRole } from "./SelectRole";
import { useProject } from "../ProjectContext";
import useAsyncForm from "@common/utils/useAsyncForm";
import { createShareableCode } from "../../../../requests/project/project";
import { CollaboratorRole } from "@server/entities/project";
import { notification } from "@common/utils/notification";
import { Copy } from "tabler-icons-react";
import { useClipboard } from "@mantine/hooks";

export const GetShareableLink: React.FC = () => {
  const { project, mutate } = useProject();
  const clipboard = useClipboard({ timeout: 500 });
  const form = useAsyncForm<{ role: CollaboratorRole }>({
    initialValues: {
      role: "editor",
    },
  });

  const generateLink = async () => {
    if (!project) return;
    const { error } = await createShareableCode(project.id, form.values.role);
    if (error) return notification.error(error.message);
    await mutate();
    notification.success("Shareable link generated!");
  };

  const handleCopy = () => {
    clipboard.copy(
      `https://www.synqup.com.au/p/${project?.permalink}?scode=${project?.shareableCode}`
    );
    notification.success("Link copied");
  };

  return (
    <div className="flex items-end gap-4">
      {project?.shareableCode ? (
        <>
          <Tooltip label="Copy link">
            <ActionIcon
              color="green"
              variant="light"
              className="mb-4"
              onClick={handleCopy}
            >
              <Copy className="w-5 h-5" />
            </ActionIcon>
          </Tooltip>

          <Input
            className="flex-1"
            value={`https://www.synqup.com.au/p/${project.permalink}?scode=${project.shareableCode}`}
          />
        </>
      ) : (
        <Button
          variant="light"
          className="mb-2"
          leftIcon={<Link1Icon className="w-5 h-5" />}
          onClick={generateLink}
        >
          Get shareable link
        </Button>
      )}

      <SelectRole
        description="Invitee role"
        className="w-32"
        {...form.getInputProps("role")}
      />
    </div>
  );
};
