import React, { useRef } from "react";
import {
  ActionIcon,
  NumberInput as MantineNumber,
  NumberInputHandlers,
  NumberInputProps,
} from "@mantine/core";

const Input: React.FC<
  NumberInputProps & {
    buttons?: boolean;
  }
> = (props) => {
  const className = `${props.error ? "animate-shake" : ""} ${props.className}`;
  const handlers = useRef<NumberInputHandlers>();

  const defaults: NumberInputProps = {
    size: "md",
    radius: "sm",
    className: className,
    classNames: { error: "text-lg" },
  };

  if (props.buttons)
    return (
      <div className="flex items-center gap-1">
        <ActionIcon
          size={props.size}
          variant="default"
          onClick={() => handlers?.current?.decrement()}
        >
          –
        </ActionIcon>

        <MantineNumber
          hideControls
          handlersRef={handlers}
          styles={{ input: { width: 54, textAlign: "center" } }}
          {...defaults}
          {...props}
        />

        <ActionIcon
          size={props.size}
          variant="default"
          onClick={() => handlers?.current?.increment()}
        >
          +
        </ActionIcon>
      </div>
    );

  return <MantineNumber {...defaults} {...props} className={className} />;
};

export default Input;
