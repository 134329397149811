import React from "react";
import { File } from "@server/entities";
import { Badge, Table } from "@mantine/core";
import { formatBytes } from "@common/utils/constants";
import { useMediaLibrary } from "../MediaLibraryContext";
import Checkbox from "@common/components/Checkbox";
import dayjs from "dayjs";
import { FileMenu } from "./FileMenu";
import { getFilename, getFiletype } from "./FileLibrary";
import UserItem from "@common/components/UserItem";

interface SharedLibraryProps {
  files?: File[];
  onFileSelect?: (file: File) => void;
}

export const SharedLibrary: React.FC<SharedLibraryProps> = ({
  files,
  onFileSelect,
}) => {
  const { closeMediaLibrary, isFileChecked, onFileDelete, onFileChecked } =
    useMediaLibrary();

  return (
    <Table>
      <thead>
        <tr>
          <th className="w-10">
            <Checkbox
              radius="sm"
              checked={files?.every((file) => isFileChecked(file.id))}
              onChange={() => onFileChecked("all")}
            />
          </th>
          <th>File name</th>
          <th className="w-48">Shared by</th>
          <th className="w-48">Date added</th>
          <th className="w-32">Size</th>
          <th className="w-5" />
        </tr>
      </thead>
      <tbody>
        {files?.length === 0 && (
          <tr>
            <td colSpan={6}>
              <p className="text-center text-dark-400 m-0 py-4">
                No files found
              </p>
            </td>
          </tr>
        )}

        {files?.map((file) => (
          <tr key={file.id}>
            <td className="w-10">
              <Checkbox
                radius="sm"
                checked={isFileChecked(file.id)}
                onChange={() => onFileChecked(file.id)}
              />
            </td>
            <td>
              <div className="flex items-center gap-2">
                <p data-testid="shared-file-name" className="m-0">
                  {getFilename(file)}
                </p>
                <Badge
                  data-testid="shared-file-type"
                  className="truncate max-w-[4rem]"
                  size="xs"
                  color="teal"
                >
                  {getFiletype(file)}
                </Badge>
              </div>
            </td>
            <td data-testid="shared-file-user">
              <UserItem user={file.user} size="sm" className="py-0" />
            </td>
            <td>
              <p className="text-dark-400 m-0">
                {dayjs(file.createdAt).format("LL")}
              </p>
            </td>
            <td>
              <p className="text-dark-400 m-0 mr-4">{formatBytes(file.size)}</p>
            </td>
            <td>
              <div className="flex items-center gap-2">
                {/*{onFileSelect && (*/}
                {/*  <ActionIcon*/}
                {/*    variant="filled"*/}
                {/*    color="dark"*/}
                {/*    onClick={() => {*/}
                {/*      onFileSelect(file);*/}
                {/*      closeMediaLibrary();*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <ArrowRight />*/}
                {/*  </ActionIcon>*/}
                {/*)}*/}

                <FileMenu file={file} onFileDelete={onFileDelete} shared />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
