import { UpdateStemsDto } from "@server/modules/project/stem/dto/update-stem.dto";
import { UploadStemDto } from "@server/modules/project/stem/dto/upload-stem.dto";
import { Stem } from "@server/entities/project";
import { useDelete, useGet, useMultipartForm, usePatch } from "../helpers";
import { AxiosRequestConfig } from "axios";

export const uploadStem = async (
  data: UploadStemDto,
  config?: AxiosRequestConfig
) =>
  useMultipartForm<Stem, UploadStemDto>(
    "/project/stem",
    {
      filetype: "stem",
      ...data,
    },
    config
  );

export const updateStems = async (data: UpdateStemsDto) =>
  usePatch<null, UpdateStemsDto>("/project/stem", data);

export const deleteStem = (id: string) =>
  useDelete<null>(`/project/stem/${id}`);

export const getStems = (projectId: string) =>
  useGet<Stem[]>(`/project/stem/${projectId}`);

// Sockets
const onUploadStem = (data: Stem) => {};
