import { Search, SearchProps } from "@common/components";
import UserItem from "@common/components/UserItem";
import { User } from "@server/entities";
import React from "react";
import { useFriends } from "../../../requests/social/friendship";

interface SearchFriendsProps
  extends Omit<SearchProps<User>, "data" | "onChange" | "value"> {
  value: User | null;
  onChange: (user: User | null) => void;
}

export function SearchFriends({
  value,
  onChange,
  ...props
}: SearchFriendsProps) {
  const { data, isValidating } = useFriends();

  return (
    <div>
      <Search<User>
        loading={isValidating}
        name="search-users"
        nothingFound="No users found"
        placeholder="Search users"
        data={
          data?.data.map((user) => ({
            label: `${user.destUser?.displayName}`,
            value: user.destUserId,
            data: user.destUser,
          })) || []
        }
        renderOption={(option) => (
          <UserItem user={option.data} className="m-0 py-0" size="sm" />
        )}
        value={value?.id}
        onChange={(value) => {
          onChange &&
            onChange(
              data?.data.find((user) => user.destUserId === value)?.destUser ||
                null
            );
        }}
        {...props}
      />
    </div>
  );
}
