import React from "react";
import { MarkupStatus, MarkupStatusProps } from "./MarkupStatus";
import { Markup } from "@server/entities/project";
import { Menu } from "@common/components";

interface MarkupStatusSelectProps extends MarkupStatusProps {
  onChange: (value: Markup["status"]) => void;
}

export const MarkupStatusSelect: React.FC<MarkupStatusSelectProps> = ({
  value,
  onChange,
  ...props
}) => {
  const options: Markup["status"][] = ["pending", "needs_approval", "approved"];

  return (
    <Menu withinPortal position="bottom-start">
      <Menu.Target>
        <div
          data-quick-assist-id="sv-markup-status"
          data-testid="sv-markup-status"
        >
          <MarkupStatus value={value} {...props} />
        </div>
      </Menu.Target>

      <Menu.Dropdown>
        {options.map((option) => (
          <Menu.Item
            key={option}
            data-testid={`sv-markup-status-${option}`}
            p={4}
            onClick={() => onChange(option)}
          >
            <MarkupStatus value={option} className="cursor-pointer" />
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
