import React from "react";
import { File } from "@server/entities";
import { Badge, Table } from "@mantine/core";
import { formatBytes } from "@common/utils/constants";
import { ActionIcon, Avatar, AvatarsGroup, Tooltip } from "@common/components";
import { PlayArrow } from "@mui/icons-material";
import { ArrowRight } from "tabler-icons-react";
import { usePlayer } from "../../Player/PlayerContext";
import { useMediaLibrary } from "../MediaLibraryContext";
import Checkbox from "@common/components/Checkbox";
import { FileMenu } from "./FileMenu";
import { getFilename, getFiletype } from "./FileLibrary";
import dayjs from "dayjs";

interface AudioLibraryProps {
  files?: File[];
  onFileSelect?: (file: File) => void;
  projectId?: string;
}

export const AudioLibrary: React.FC<AudioLibraryProps> = ({
  files,
  onFileSelect,
  projectId,
}) => {
  const { closeMediaLibrary, isFileChecked, onFileDelete, onFileChecked } =
    useMediaLibrary();
  const { load } = usePlayer();

  return (
    <Table>
      <thead>
        <tr>
          <th className="w-10">
            <Checkbox
              radius="sm"
              checked={files?.every((file) => isFileChecked(file.id))}
              onChange={() => onFileChecked("all")}
            />
          </th>
          <th>File name</th>
          <th className="w-32">Project</th>
          <th className="w-32">Shared with</th>
          {projectId && <th className="w-24 text-center">Owner</th>}
          <th className="w-32">Date added</th>
          <th className="w-32">Size</th>
          <th className="w-10" />
        </tr>
      </thead>
      <tbody>
        {files?.length === 0 && (
          <tr>
            <td colSpan={6}>
              <p className="text-center text-dark-400 m-0 py-4">
                No audio files found
              </p>
            </td>
          </tr>
        )}

        {files?.map((file) => (
          <tr key={file.id}>
            <td className="w-10">
              <Checkbox
                radius="sm"
                checked={isFileChecked(file.id)}
                onChange={() => onFileChecked(file.id)}
              />
            </td>
            <td>
              <div className="flex items-center gap-2">
                <p data-testid="audio-file-name" className="m-0">
                  {getFilename(file)}
                </p>
                <Badge
                  data-testid="audio-file-type"
                  className="truncate max-w-[4rem]"
                  size="xs"
                  color="teal"
                >
                  {getFiletype(file)}
                </Badge>
              </div>
            </td>
            <td data-testid="audio-file-project">
              {file.project?.name || "-"}
            </td>
            <td>
              <AvatarsGroup size="sm" limit={2} users={file.sharedWith} />
            </td>
            {projectId && (
              <td data-testid="file-owner">
                <Avatar size="sm" className="mx-auto" user={file.user} />
              </td>
            )}
            <td>
              <p className="text-dark-400 m-0">
                {dayjs(file.createdAt).format("D/M/YY")}
              </p>
            </td>
            <td>
              <p className="text-dark-400 m-0 mr-4">{formatBytes(file.size)}</p>
            </td>
            <td>
              <div className="flex items-center gap-2">
                <Tooltip withinPortal zIndex={1000} label="Listen to audio">
                  <ActionIcon
                    variant="gradient"
                    color="indigo"
                    onClick={() =>
                      load({
                        src: file.url,
                        artist: ``,
                        name: file.filename,
                        format: file.format,
                      })
                    }
                  >
                    <PlayArrow />
                  </ActionIcon>
                </Tooltip>

                {onFileSelect && (
                  <Tooltip withinPortal zIndex={1000} label="Select file">
                    <ActionIcon
                      variant="filled"
                      color="dark"
                      onClick={() => {
                        onFileSelect(file);
                        closeMediaLibrary();
                      }}
                    >
                      <ArrowRight />
                    </ActionIcon>
                  </Tooltip>
                )}

                <FileMenu file={file} onFileDelete={onFileDelete} />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
