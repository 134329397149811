import { Select, SelectProps } from "@common/components";
import { Collaborator } from "@server/entities/project";
import React from "react";

interface SelectRoleProps extends Omit<SelectProps, "data"> {
  value: Collaborator["role"];
  onChange: (role: Collaborator["role"]) => void;
}

const SelectRole: React.FC<SelectRoleProps> = ({ ...props }) => {
  const data = [
    {
      value: "viewer",
      label: "Viewer",
      data: "viewer",
    },
    {
      value: "editor",
      label: "Editor",
      data: "editor",
    },
    {
      value: "admin",
      label: "Admin",
      data: "admin",
    },
  ];

  return <Select {...props} data={data} />;
};

export { SelectRole };
