import React from "react";
import { UseAsyncFormReturnType } from "@common/utils/useAsyncForm";
import { UpdateUserDto } from "@server/modules/user/user/dto/update-user.dto";
import { ActionIcon, Button, Divider, Select } from "@common/components";
import { Plus, Trash } from "tabler-icons-react";
import { useProfile } from "../pages/Profile";
import { SearchArtists } from "@common/components/SearchArtists";
import { SearchGenres } from "../../projects/components/SearchGenres";
import { CLASSIFICATIONS } from "@common/utils/constants";

interface EditProfileArtistDetailsProps {
  form: UseAsyncFormReturnType<UpdateUserDto>;
}

export const EditProfileArtistDetails: React.FC<
  EditProfileArtistDetailsProps
> = ({ form }) => {
  const { mutate } = useProfile();

  const handleAddClassification = async () => {
    form.insertListItem("classifications", {
      name: "Other",
      level: "emerging",
    });
    await mutate();
  };

  const handleDeleteClassification = async (index: number) => {
    form.removeListItem("classifications", index);
    await mutate();
  };

  return (
    <div className="flex flex-col">
      <h3 className="mb-2">Classification</h3>

      {form.values.classifications?.map((cls, index) => (
        <div key={index} className="flex gap-4 items-center">
          <Select
            withinPortal
            size="sm"
            data={CLASSIFICATIONS}
            {...form.getInputProps(`classifications.${index}.name`)}
          />

          {/*<Select*/}
          {/*  withinPortal*/}
          {/*  size="sm"*/}
          {/*  data={LEVELS}*/}
          {/*  renderOption={(option) => (*/}
          {/*    <ClassificationBadge*/}
          {/*      className="m-2"*/}
          {/*      classification={{ name: option.label, level: option.value }}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*  {...form.getInputProps(`classifications.${index}.level`)}*/}
          {/*/>*/}

          <ActionIcon
            size="sm"
            color="red"
            variant="light"
            onClick={() => handleDeleteClassification(index)}
          >
            <Trash />
          </ActionIcon>
        </div>
      ))}

      <Button
        variant="light"
        size="sm"
        className="mr-auto mt-4"
        leftIcon={<Plus className="w-4 h-4" />}
        onClick={handleAddClassification}
      >
        Add
      </Button>

      <Divider />

      <h3 className="mb-2">Genres</h3>

      <SearchGenres {...form.getInputProps("genres")} size="sm" radius="sm" />

      <Divider />

      <h3 className="mb-2">Influences</h3>

      <SearchArtists
        {...form.getInputProps("influences")}
        size="md"
        radius="md"
        inputProps={{ size: "sm" }}
      />

      <Divider />

      <h3 className="mb-2">Sounds like</h3>

      <SearchArtists
        {...form.getInputProps("soundsLike")}
        size="md"
        radius="md"
        inputProps={{ size: "sm" }}
      />
    </div>
  );
};
