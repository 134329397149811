import { InternalError } from "../../modules/redirects/InternalError";
import { NotFound } from "../../modules/redirects/NotFound";
import { Unauthorized } from "../../modules/redirects/Unauthorized";
import { HttpExceptionClient } from "../../requests/helpers";

export const errorNavigator = (
  error: { response?: HttpExceptionClient | undefined } | undefined = undefined
) => {
  if (!error) return <InternalError />;
  if (error.response?.status === 404) return <NotFound />;
  if (error.response?.status === 401)
    return <Unauthorized error={error.response} />;
  return <InternalError />;
};
