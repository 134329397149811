import React from "react";
import { MediaLibrary } from "../../../contexts/MediaLibrary/MediaLibrary";
import { Card } from "@common/components";
import { RecentLogs } from "../components/RecentLogs";
import { Tabs } from "@mantine/core";
import { MyMarkups } from "../components/MyMarkups";
import { Helmet } from "react-helmet";
import { RecentProjects } from "../components/RecentProjects";
import { withAuth } from "../../routes/ProtectedRoute";

export const Home: React.FC = withAuth(() => {
  return (
    <div className="grid grid-cols-3 gap-4 p-4">
      <Helmet>
        <title>Dashboard | Synqup</title>
      </Helmet>

      <RecentLogs />

      <Card className="col-span-3 lg:col-span-2">
        <Tabs defaultValue="recent" classNames={{ tabLabel: "font-semibold" }}>
          <Tabs.List>
            <Tabs.Tab value="recent" data-quick-assist-id="recent-projects-0">
              Recent projects
            </Tabs.Tab>
            <Tabs.Tab
              value="assigned-to-me"
              data-quick-assist-id="assigned-to-me"
              data-testid="assigned-to-me"
            >
              Assigned to me
            </Tabs.Tab>
            <Tabs.Tab
              value="assigned-by-me"
              data-quick-assist-id="assigned-by-me"
              data-testid="assigned-by-me"
            >
              Assigned by me
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="recent">
            <RecentProjects />
          </Tabs.Panel>
          <Tabs.Panel value="assigned-to-me">
            <MyMarkups assignedToMe />
          </Tabs.Panel>
          <Tabs.Panel value="assigned-by-me">
            <MyMarkups assignedByMe />
          </Tabs.Panel>
        </Tabs>
      </Card>

      <Card
        id="media-library"
        data-quick-assist-id="media-library"
        className="col-span-3 p-8"
      >
        <h2>Media Library</h2>
        <MediaLibrary queryable={true} />
      </Card>
    </div>
  );
});
