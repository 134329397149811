import { DefaultValue, selector, useRecoilValue, RecoilState } from "recoil";

export type RecoilStates<T> = {
  [K in keyof T]: RecoilState<T[K]>;
};

/**
 * Groups an array of atoms together under a parent selector state.
 * You can then set each individual atom state without updating
 * the other atoms
 * @param key - key of the selector
 * @param states - array of atoms
 */
export function genericSelector<T extends object>(
  key: string,
  states: RecoilStates<T>
) {
  return selector<T>({
    key,
    get: ({ get }) => {
      const getState = {} as T;
      // @ts-ignore
      [...Object.keys(states)].forEach((k) => (getState[k] = get(states[k])));
      return getState;
    },
    set: ({ set, reset }, newValue) => {
      if (newValue instanceof DefaultValue) {
        // @ts-ignore
        [...Object.values(states)].forEach((state) => reset(state));
        return;
      }

      [...Object.keys(newValue)].forEach((k) => {
        if (Object.hasOwn(newValue, k)) set(states[k], newValue[k]);
      });
    },
    dangerouslyAllowMutability: true,
  });
}

/**
 * Hooks into a subset of atoms from a parent selector state.
 * Will not update based on any other atoms in the parent
 * selector state.
 * @param keys - array of keys to select from the parent selector state
 * @param states - parent selector state
 */
export function useSelector<T, K extends keyof T>(
  keys: K[] = [],
  states: RecoilStates<T>
): Pick<T, K> {
  if (keys.length === 0) return {} as T;

  const pickedSelectors: Pick<T, K> = {} as T;

  for (const k of keys) {
    // @ts-ignore
    pickedSelectors[k] = useRecoilValue(states[k]);
  }

  return pickedSelectors;
}
