import React from "react";
import { useUser } from "../../../contexts/UserContext";
import { RouterTabs } from "@common/components";
import {
  UserCircleIcon,
  UserIcon,
  UsersIcon,
  BellIcon,
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import { ProfileSettings } from "./ProfileSettings";
import UserItem from "@common/components/UserItem";
import { Crown } from "tabler-icons-react";
import { withAuth } from "../../routes/ProtectedRoute";
import { AccountSettings } from "./AccountSettings";
import { MembershipSettings } from "./MembershipSettings";
import { LinkedAccountSettings } from "./LinkedAccountSettings";
import { PrivacySettings } from "./PrivacySettings";
import { NotificationSettings } from "./NotificationSettings";
import { Helmet } from "react-helmet";

interface SettingsAndPrivacyProps {}

export const SettingsAndPrivacy: React.FC<SettingsAndPrivacyProps> = withAuth(
  () => {
    const { user } = useUser();

    return (
      <div className="h-full p-4">
        <Helmet>
          <title>Settings and Privacy | Synqup</title>
        </Helmet>

        <RouterTabs
          basePath={`/settings`}
          color="dark"
          orientation="vertical"
          defaultValue="account"
          variant="pills"
          classNames={{
            root: "h-full",
            tabsList:
              "w-72 p-4 pr-8 border-0 border-r border-solid border-dark-700",
            tab: "rounded",
          }}
          tabs={[
            {
              icon: <UserIcon className="w-4 h-4" />,
              label: "Account",
              value: "account",
              component: <AccountSettings />,
              home: true,
            },
            {
              icon: <Crown className="w-4 h-4 fill-gray-300" />,
              label: "Membership",
              value: "membership",
              component: <MembershipSettings />,
              home: true,
            },
            {
              icon: <UsersIcon className="w-4 h-4" />,
              label: "Linked Accounts",
              value: "linked-accounts",
              component: <LinkedAccountSettings />,
              home: true,
            },
            {
              icon: <BellIcon className="w-4 h-4" />,
              label: "Notifications",
              value: "notifications",
              component: <NotificationSettings />,
              home: true,
            },
            {
              icon: <UserCircleIcon className="w-4 h-4" />,
              label: "Profile",
              value: "profile",
              component: <ProfileSettings />,
              home: true,
            },
            {
              icon: <LockClosedIcon className="w-4 h-4" />,
              label: "Privacy",
              value: "privacy",
              component: <PrivacySettings />,
              home: true,
            },
          ]}
          verticalHeader={
            <UserItem className="text-lg font-bold" user={user} />
          }
        />
      </div>
    );
  }
);
