import { Tabs as MantineTabs, TabsProps } from "@mantine/core";
import React from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

export interface RouterTab {
  home?: boolean;
  value: string;
  label: string;
  icon: React.ReactNode;
  component: React.ReactNode;
  ["data-testid"]?: string;
  ["data-quick-assist-id"]?: string;
}

interface RouterTabsProps extends Omit<TabsProps, "children"> {
  tabs: RouterTab[];
  basePath?: string;
  verticalHeader?: any | any[];
}

const RouterTabs = ({
  tabs,
  basePath: _basePath,
  verticalHeader,
  ...props
}: RouterTabsProps) => {
  const navigate = useNavigate();
  const { tab } = useParams<{ tab?: string }>();

  const basePath =
    _basePath ||
    location.pathname
      .split("/")
      .slice(0, tab ? -1 : undefined)
      .join("/");

  return (
    <MantineTabs
      data-testid="router-tabs"
      value={tab}
      onTabChange={(value) => navigate(`${basePath}/${value}`)}
      {...props}
    >
      <MantineTabs.List>
        {verticalHeader}
        {tabs.map((tab, index) => (
          <MantineTabs.Tab
            data-testid={tab["data-testid"]}
            data-quick-assist-id={tab["data-quick-assist-id"]}
            key={index}
            value={tab.value}
            icon={tab.icon}
          >
            {tab.label}
          </MantineTabs.Tab>
        ))}
      </MantineTabs.List>

      {tabs.map((tab, index) => (
        <MantineTabs.Panel pt={16} key={index} value={tab.value}>
          {tab.component}
        </MantineTabs.Panel>
      ))}
    </MantineTabs>
  );
};

RouterTabs.Tab = MantineTabs.Tab;
RouterTabs.displayName = MantineTabs.displayName;

export { RouterTabs };
