import Confirm from "@common/components/Confirm";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { FileDownload, Replay } from "@mui/icons-material";
import React, { createContext, useCallback, useContext, useState } from "react";
import { ContextMenu } from "@common/components";
import { useSetRecoilState } from "recoil";
import { WithContext } from "../../../contexts";
import { replaceState } from "../recoil/stem";
import { ReplaceStem } from "../view/stem/components/ReplaceStem";
import { useStemViewer } from "./StemViewerContext";

export interface ContextMenuType {
  open: (
    ev: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
    id: string
  ) => void;
}

export interface ContextMenuAction {
  name: string;
  execute: () => void;
  icon: React.ReactElement;
  disabled?: boolean;
  color?: string;
  [key: string]: any;
}

const initialState = {} as ContextMenuType;

const ContextMenuContext = createContext<ContextMenuType>(initialState);
ContextMenuContext.displayName = "ContextMenu";

export const useContextMenu = () => useContext(ContextMenuContext);

export const withContextMenu: WithContext = (Component) => (props) => {
  const { stem, exports, markup } = useStemViewer().controllers;
  const { stems, replace } = useStemViewer().stem(["stems", "replace"]);
  const [position, setPosition] = useState({
    top: 0,
    left: 0,
  });
  const [id, setId] = useState<string | null>(null);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const setReplace = useSetRecoilState(replaceState);

  const open = (
    ev: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
    id: string
  ) => {
    ev.preventDefault();
    ev.stopPropagation();
    setPosition({ top: ev.clientY, left: ev.clientX });
    setId(id);
  };

  // Context value
  const value: ContextMenuType = {
    open,
  };

  /**
   * Moves a stem up
   */
  const handleMoveUp = useCallback(() => {
    if (!id) return;

    return stem.reorder(id, -1);
  }, [id]);

  /**
   * Moves a stem down
   */
  const handleMoveDown = useCallback(() => {
    if (!id) return;

    return stem.reorder(id, 1);
  }, [id]);

  /**
   * Exports a single selected stem to wav file
   */
  const handleExport = useCallback(() => {
    if (!id) return;

    return exports.export({
      stems: stems.filter((stem) => stem.stemId === id),
      individual: true,
      type: "mp3",
    });
  }, [id, stems]);

  /**
   * Toggles replace stem modal
   */
  const handleReplace = useCallback(() => {
    if (!id) return;

    setReplace(id);
    setId(null);
  }, [id]);

  /**
   * Deletes a stem
   */
  const handleDelete = useCallback(() => {
    if (!id) return;

    // Check if markup exists
    if (markup.markup.markups.some((markup) => markup.stemId === id))
      return setDeleteId(id);

    return stem.delete(id);
  }, [id]);

  const stemActions: ContextMenuAction[] = [
    {
      name: "Move stem up",
      execute: handleMoveUp,
      icon: <ChevronUpIcon className="w-4 h-4" />,
      disabled: !id,
    },
    {
      name: "Move stem down",
      execute: handleMoveDown,
      icon: <ChevronDownIcon className="w-4 h-4" />,
      disabled: !id,
    },
    {
      name: "Export",
      execute: handleExport,
      icon: <FileDownload className="w-4 h-4" />,
      disabled: !id,
    },
    {
      name: "Replace",
      execute: handleReplace,
      icon: <Replay className="w-4 h-4" />,
      disabled: !id,
      "data-testid": "sv-replace-stem-button",
    },
    {
      name: "Delete",
      color: "red",
      execute: handleDelete,
      icon: <TrashIcon className="w-4 h-4" />,
      disabled: !id,
    },
  ];

  return (
    <ContextMenuContext.Provider value={value}>
      <ReplaceStem id={replace} onClose={() => setReplace(null)} />

      <Confirm
        color="red"
        icon={<TrashIcon className="w-5" />}
        title="Delete stem"
        content="This stem is connected to unresolved markups. Are you sure you want to delete it?"
        opened={!!deleteId}
        onClose={() => setDeleteId(null)}
        onConfirm={async () => {
          if (!deleteId) return;
          await stem.delete(deleteId);
          setDeleteId(null);
        }}
      />

      <ContextMenu
        context={{ ...position }}
        opened={!!id}
        onChange={(opened) => !opened && setId(null)}
        items={stemActions.map((action) => ({
          ...action,
          onClick: () => {
            action.execute();
            setId(null);
          },
        }))}
      />

      <Component {...props} />
    </ContextMenuContext.Provider>
  );
};
