import { useMouse } from "@mantine/hooks";
import React from "react";
import { useUser } from "../../../../contexts/UserContext";
import { useStemViewer } from "../../contexts/StemViewerContext";
import { Mouse } from "./components/Mouse";
import { useDeepEffect } from "@common/utils/use-deep-effect";
import { User } from "@server/entities";

const Mice: React.FC = () => {
  const mouse = useMouse();
  const { user } = useUser();
  const { collab } = useStemViewer().controllers;
  const { mice, joined } = useStemViewer().collab(["mice", "joined"]);
  const trackBody = document.getElementById("track_view");

  useDeepEffect(() => {
    if (user && joined.length > 1)
      collab.sendMouse(
        user as User,
        (trackBody?.scrollLeft || 0) + mouse.x,
        mouse.y
      );
  }, [mouse]);

  return (
    <tr>
      <td>
        {mice.map((mouse, index) => (
          <Mouse key={index} {...mouse} />
        ))}
      </td>
    </tr>
  );
};

export { Mice };
