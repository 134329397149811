import { atom } from "recoil";
import { UploadFileDto } from "@server/modules/file/dto/upload-file.dto";

export type MediaLibraryState = {
  uploading: boolean;
  uploaded: number;
  currentUpload: UploadFileDto | null;
  total: number;
  percent: number;
};

export const uploadingState = atom<boolean>({
  key: "mediaLibraryUploadingState",
  default: false,
});

export const uploadedState = atom<number>({
  key: "uploadedState",
  default: 0,
});

export const currentUploadState = atom<UploadFileDto | null>({
  key: "currentUploadState",
  default: null,
});

export const totalState = atom<number>({
  key: "totalUploadsState",
  default: 0,
});

export const percentState = atom<number>({
  key: "percentUploadState",
  default: 0,
});
