import { Search, SearchProps } from "@common/components";
import React from "react";
import { useStemViewer } from "../../../contexts/StemViewerContext";
import { DotFilledIcon } from "@modulz/radix-icons";
import { Stem } from "@server/entities/project";

type MinimalStem = Pick<Stem, "id" | "name" | "color">;

interface SearchStemsProps
  extends Omit<SearchProps<MinimalStem>, "data" | "onChange" | "value"> {
  value: MinimalStem | null;
  onChange: (stem: MinimalStem | null) => void;
}

export function SearchStems({ value, onChange, ...props }: SearchStemsProps) {
  const { stems } = useStemViewer().stem(["stems"]);

  const minimalStems =
    stems
      ?.filter((stem) => !!stem.id)
      .map(
        (stem) =>
          ({
            id: stem.id,
            name: stem.name,
            color: stem.color,
          } as MinimalStem)
      ) || [];

  return (
    <div>
      <Search<Stem>
        name="search-stems"
        rightSection={<></>}
        nothingFound="No stems found"
        placeholder="Search stems"
        data={minimalStems.map((stem) => ({
          label: stem.name,
          value: stem.id,
          data: stem,
        }))}
        renderOption={(option) => (
          <div
            data-testid="sv-markup-stem-option"
            className="flex items-center gap-2 p-2"
          >
            <DotFilledIcon color={option.data.color} />
            <p>{option.data.name}</p>
          </div>
        )}
        value={value?.id}
        onChange={(value) => {
          onChange &&
            onChange(
              (minimalStems?.find((stem) => stem?.id === value) as Stem) || null
            );
        }}
        {...props}
      />
    </div>
  );
}
