import React from "react";
import { ModalProps, Tabs } from "@mantine/core";
import { Avatar, Card, Modal } from "@common/components";
import { useProfile } from "./Profile";
import { EditProfileUserDetails } from "../components/EditProfileUserDetails";
import { EditProfileArtistDetails } from "../components/EditProfileArtistDetails";
import useAsyncForm from "@common/utils/useAsyncForm";
import { UpdateUserDto } from "@server/modules/user/user/dto/update-user.dto";
import { FormActionButtons } from "@common/components/FormActionButtons";
import { patchUser } from "src/requests/user/user";
import dayjs from "dayjs";
import { UploadImageModal } from "@common/components/UploadImageModal";
import { notification } from "@common/utils/notification";
import { EditProfileLinkedAccounts } from "../components/EditProfileLinkedAccounts";
import { Music, User } from "tabler-icons-react";
import { Link1Icon } from "@modulz/radix-icons";
import { unionBy } from "lodash";
import { LinkedAccount } from "@server/entities/user";

interface EditProfileModalProps extends ModalProps {}

export const EditProfileModal: React.FC<EditProfileModalProps> = ({
  ...props
}) => {
  const { user, mutate } = useProfile();
  const form = useAsyncForm<UpdateUserDto>({
    initialValues: {
      ...user,
      linkedAccounts: unionBy(
        user?.linkedAccounts,
        [
          { type: "instagram" },
          { type: "spotify" },
          { type: "tiktok" },
          { type: "youtube" },
          { type: "soundcloud" },
        ] as LinkedAccount[],
        "type"
      ),
    } as UpdateUserDto,
  });

  const handleUpload = async (url: string) => {
    if (!user) return;
    await patchUser(user.id, { avatar: url });
    await mutate();
  };

  const handleSave = async () => {
    if (!user?.id) return;
    const { error } = await form.sendForm(
      ({ highlight: _, ...values }) => patchUser(user.id, values),
      { resetInitial: true }
    );
    if (error)
      return notification.error(
        error.errors.map((value) => value.message).join("\n")
      );
    await mutate();
  };

  return (
    <Modal size="xl" {...props}>
      <div className="flex gap-6 items-start">
        <Card className="w-96 hidden md:flex flex-col items-center">
          <h2 className="font-semibold">
            {user?.firstName} {user?.lastName}
          </h2>
          <p className="opacity-70">@{user?.username}</p>

          <UploadImageModal
            targetWidth={1280}
            targetHeight={1280}
            onUpload={handleUpload}
          >
            <Avatar user={user} size="xl" />
          </UploadImageModal>

          <p className="opacity-60 text-xs mt-4">
            Member since {dayjs(user?.createdAt).format("LL")}
          </p>
        </Card>

        <Card className="flex-1">
          <h2 className="mb-4">Edit Profile</h2>

          <Tabs defaultValue="user">
            <Tabs.List>
              <Tabs.Tab value="user" icon={<User className="w-4 h-4" />}>
                User Details
              </Tabs.Tab>
              <Tabs.Tab value="artist" icon={<Music className="w-4 h-4" />}>
                Artist Details
              </Tabs.Tab>
              <Tabs.Tab value="linkedAccounts" icon={<Link1Icon />}>
                Linked Accounts
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="user" pt={16}>
              <EditProfileUserDetails form={form} />
            </Tabs.Panel>

            <Tabs.Panel value="artist" pt={16}>
              <EditProfileArtistDetails form={form} />
            </Tabs.Panel>

            <Tabs.Panel value="linkedAccounts" pt={16}>
              <EditProfileLinkedAccounts form={form} />
            </Tabs.Panel>
          </Tabs>

          <FormActionButtons form={form} onSave={handleSave} />
        </Card>
      </div>
    </Modal>
  );
};
