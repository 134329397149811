import { useGet, useMultipartForm } from "../helpers";
import { CreateMessageDto } from "@server/modules/social/message/dto/create-message.dto";
import { QueryMessageDto } from "@server/modules/social/message/dto/query-message.dto";
import { Message } from "@server/entities/social/message.entity";
import { useEffect, useState } from "react";
import { PaginatedListDto } from "@server/dto/paginated-list.dto";
import { notification } from "@common/utils/notification";
import { useSocketListener } from "../helpers/use-socket-listener";
import { useSockets } from "../../contexts/SocketContext";

export const createMessage = (data: CreateMessageDto) => {
  return useMultipartForm<Message>("/social/message", {
    ...data,
    filetype: "file",
  });
};

export const useMessages = ({ page = 1, ...query }: QueryMessageDto) => {
  const { appSocket } = useSockets();
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const [pages, setPages] = useState(0);

  const fetchMessages = async () => {
    if (page >= pages && pages !== 0) return;

    setLoading(true);

    const { data, error } = await useGet<PaginatedListDto<Message>>(
      "/social/message",
      { ...query, page }
    );

    if (error) {
      setLoading(false);
      return notification.error(error.message);
    }

    if (!data) return;

    setMessages((messages) => [...data.data.reverse(), ...messages]);
    setLoading(false);
    setPages(data.pages);
  };

  useEffect(() => {
    fetchMessages().catch();
  }, [page]);

  useSocketListener(appSocket, "message:created", (message: Message) => {
    if (message.channelId === query.channelId)
      setMessages((messages) => [...messages, message]);
  });

  return { messages, loading };
};
