import { FunctionComponent } from "react";

export type WithContext = <T>(component: any) => FunctionComponent<T>;

/**
 * Chains context providers together
 * @param withContexts
 * @param component
 */
export const withContexts = <T>(
  withContexts: WithContext[],
  component: FunctionComponent<T>
): FunctionComponent<T> => {
  let output = component;
  for (const withContext of withContexts) {
    output = withContext(output);
  }
  return output;
};
