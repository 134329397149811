import React, { MouseEventHandler, useState } from "react";
import { Note } from "@server/entities/project";
import { ActionIcon, Avatar, Card, Textarea } from "@common/components";
import { useProject } from "../../../../projects/view/ProjectContext";
import { Pencil, Trash } from "tabler-icons-react";
import { AnimatePresence, motion } from "framer-motion";
import useAsyncForm from "@common/utils/useAsyncForm";
import { UpdateNoteDto } from "@server/modules/project/note/dto";
import { useUser } from "../../../../../contexts/UserContext";
import { FormActionButtons } from "@common/components/FormActionButtons";
import { patchNote } from "../../../../../requests/project/note";
import { getNoteTitleBody } from "../NotesDrawer";
import { useStemViewer } from "../../../contexts/StemViewerContext";
import { stripTags } from "underscore.string";
import { useNavigate } from "react-router-dom";

interface NoteCardProps {
  note: Note;
  isTrack?: boolean;
  activeStem?: any;
  onDelete: (id: string) => void;
}

export const NoteCard: React.FC<NoteCardProps> = ({
  note,
  isTrack,
  activeStem,
  onDelete,
}) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { project, mutate } = useProject();
  const { stem } = useStemViewer().controllers;
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [del, setDel] = useState(false);

  const form = useAsyncForm<UpdateNoteDto>({
    initialValues: {
      title: note.title,
      body: stripTags(note.body),
    },
  });

  const handleEdit: MouseEventHandler = (e) => {
    e.stopPropagation();
    setEdit(true);
  };

  const handleSave = async () => {
    if (!form.values.body) return;
    if (form.values.body.trim().length === 0) return;
    const { title, body } = getNoteTitleBody(form.values.body);

    const { data, error } = await form.sendForm((values) =>
      patchNote(note.id, { ...values, title, body })
    );

    if (error) return;
    if (isTrack) {
      await stem.update(
        activeStem.stemId,
        {
          notes: activeStem.notes.map((n) =>
            n.id === note.id ? { ...data, user } : n
          ),
        },
        { local: true }
      );
    } else await mutate();

    setEdit(false);
  };

  if (edit)
    return (
      <Card className="bg-dark-900 flex flex-col gap-4" p="sm">
        <Textarea
          data-testid="note-card-edit-body"
          autosize
          minRows={3}
          {...form.getInputProps("body")}
        />
        <FormActionButtons
          data-testid="note-card-edit-save"
          form={form}
          onSave={handleSave}
          onCancel={() => setEdit(false)}
          size="sm"
          disabled={false}
        />
      </Card>
    );

  return (
    <Card
      data-testid="note-card"
      data-quick-assist-id="note-card"
      className="bg-dark-900 flex items-end justify-between cursor-pointer"
      p="sm"
      onClick={() => setOpen((open) => !open)}
      onDoubleClick={() =>
        navigate(`/p/${project?.permalink}/threads/${note.id}`)
      }
    >
      <div>
        <div className="flex items-start gap-4">
          <Avatar user={note.user} size="sm" />
          <div>
            <p className="m-0" data-testid="note-card-title">
              {note.title}
            </p>
            <AnimatePresence>
              {open && (
                <motion.div
                  data-testid="note-card-body"
                  className="children:text-slate-300"
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, height: "auto" },
                    collapsed: { opacity: 0, height: 0 },
                  }}
                  transition={{ type: "tween", duration: 0.15 }}
                >
                  <p
                    className="m-0 pt-1"
                    dangerouslySetInnerHTML={{
                      __html: note.body,
                    }}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-2">
        {note.userId === user?.id && (
          <ActionIcon
            data-testid="note-card-edit"
            onClick={handleEdit}
            variant="light"
            size="sm"
          >
            <Pencil />
          </ActionIcon>
        )}

        <ActionIcon
          data-testid="note-card-delete"
          onClick={() => onDelete(note.id)}
          color="red"
          variant="light"
          size="sm"
        >
          <Trash />
        </ActionIcon>
      </div>
    </Card>
  );
};
