export const colourHandler = {
  get: (target, name) =>
    target.hasOwnProperty(name)
      ? target[name]
      : {
          stem: "rgba(94,69,255,0.3)",
          track: "#1c1a26",
          panel: "#6542ff",
        },
};

export const colors = {
  red: {
    stem: "rgba(203,115,116,0.3)",
    track: "#26000e",
    panel: "#dc332c",
  },
  orange: {
    stem: "rgba(210,145,88,0.3)",
    track: "#1e0f0d",
    panel: "#b67a37",
  },
  yellow: {
    stem: "rgba(196,192,111,0.3)",
    track: "#2a2818",
    panel: "#a4a443",
  },
  green: {
    stem: "rgba(112,234,125,0.3)",
    track: "#192c22",
    panel: "#48c779",
  },
  lime: {
    stem: "rgba(128,234,112,0.3)",
    track: "#192c1d",
    panel: "#48c74a",
  },
  teal: {
    stem: "rgba(112,234,187,0.3)",
    track: "#192c29",
    panel: "#48c7a1",
  },
  cyan: {
    stem: "rgba(116,253,255,0.3)",
    track: "#0e2026",
    panel: "#34c0c5",
  },
  blue: {
    stem: "rgba(59,132,255,0.3)",
    track: "#0f1326",
    panel: "#1f61d6",
  },
  indigo: {
    stem: "rgba(94,69,255,0.3)",
    track: "#1c1a26",
    panel: "#6542ff",
  },
  purple: {
    stem: "rgba(229,115,232,0.3)",
    track: "#1f1a23",
    panel: "#9860c0",
  },
  grape: {
    stem: "rgba(232,115,222,0.3)",
    track: "#201823",
    panel: "#ad5bc4",
  },
  pink: {
    stem: "rgba(195,131,234,0.3)",
    track: "#150b13",
    panel: "#e169dc",
  },
};

export const themePicker = new Proxy(colors, colourHandler);
