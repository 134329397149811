import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { SearchUsers } from "@common/components";
import { useUser } from "../../contexts/UserContext";
import { Button } from "@common/components";
import { HeaderProfile } from "./components";
import { MessagesMenu } from "../messages/components/MessagesMenu";
import { Notifications } from "./components";
import { LOGIN_URL } from "@common/utils/constants";
import { MusicNote } from "@mui/icons-material";
import logoSvg from "../../res/images/logo-v4.svg";

function Header(props) {
  const navigate = useNavigate();
  const { user } = useUser();

  return (
    <div className="fixed flex w-full p-3 top-0 z-50 items-center bg-dark-1000">
      <Link to="/">
        <div className="relative flex items-center gap-4 mx-8">
          <img className="w-10 h-10" src={logoSvg} alt="Synqup logo" />
          <h1 className="text-3xl font-normal m-0 mb-1">
            synq
            <span className="font-bold bg-gradient-to-tr from-purple-400 to-blue-400 bg-clip-text text-transparent">
              up
            </span>
          </h1>
          <span className="px-2 py-0.5 text-xs font-bold text-white rounded-lg bg-gradient-to-br from-pink-500 to-purple-600 scale-75 -ml-4">
            BETA
          </span>
        </div>
      </Link>

      {user && (
        <div>
          <Link to="/projects">
            <Button
              data-testid="header-projects-button"
              data-quick-assist-id="header-projects-button"
              leftIcon={<MusicNote className="w-4 h-4 ml-1" />}
              variant="light"
            >
              Projects
            </Button>
          </Link>
        </div>
      )}

      {user ? (
        <div className="hidden md:flex items-center ml-auto mr-4">
          <div className="flex items-center ml-auto gap-8 mr-8 translate-y-1 children:text-primary-400">
            <SearchUsers
              data-quick-assist-id="search-users"
              className="hidden w-96 sm:block"
              onChange={(user) => user && navigate(`/u/${user.username}`)}
              creatable
              // onCreate={() => navigate("/find-collaborators")}
              // getCreateLabel={() => (
              //   <div className="flex items-center gap-2">
              //     <Search className="w-4 h-4" />
              //     <p className="m-0">Find collaborators</p>
              //   </div>
              // )}
              {...props}
            />

            <MessagesMenu />

            <Notifications />
          </div>

          <HeaderProfile {...props} />
        </div>
      ) : (
        <a className="hidden md:block ml-auto py-2" href={LOGIN_URL}>
          <Button size="sm" color="dark" variant="light">
            Sign In
          </Button>
        </a>
      )}
    </div>
  );
}

export { Header };
