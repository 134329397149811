import React, { FunctionComponent, useEffect } from "react";
import { useUser } from "../../contexts/UserContext";
import { LOGIN_URL } from "@common/utils/constants";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { user, loading } = useUser();

  useEffect(() => {
    if (!loading && !user) window.location.replace(LOGIN_URL);
  }, [user, loading]);

  if (loading || !user) return <></>;

  return <>{children}</>;
};

/**
 * Only allows authorized to a route
 * @param Component
 */
export const withAuth = (Component: FunctionComponent): FunctionComponent => {
  return (props, context) => (
    <ProtectedRoute>
      <Component {...props} {...context} />
    </ProtectedRoute>
  );
};

/**
 * Only allows authorized to a route conditionally
 * @param Component
 * @param condition
 */
export function withAuthConditional<T>(
  Component: FunctionComponent<T>,
  condition: (props: T) => boolean
): FunctionComponent<T> {
  return (props, context) => {
    if (condition(props)) {
      return (
        <ProtectedRoute>
          <Component {...props} {...context} />
        </ProtectedRoute>
      );
    } else {
      return <Component {...props} {...context} />;
    }
  };
}
