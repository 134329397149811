import React from "react";

import { RichText, RichTextProps } from "@common/components/RichText";

type RichTextReadOnlyProps = Omit<RichTextProps, "onChange">;

export const RichTextReadOnly: React.FC<RichTextReadOnlyProps> = (props) => {
  return (
    <RichText
      readOnly
      className="bg-transparent border-none -m-2"
      classNames={{ toolbar: "hidden" }}
      {...props}
      onChange={() => {}}
    />
  );
};
