import React from "react";
import { ProjectCard } from "../../projects/components/ProjectCard";
import { getProjects } from "../../../requests/project/project";
import { useUser } from "../../../contexts/UserContext";
import { Button } from "@common/components";
import { Link } from "react-router-dom";

export const RecentProjects: React.FC = () => {
  const { starred } = useUser();
  const { data } = getProjects({
    recent: true,
    sortBy: "updatedAt",
    dir: "DESC",
    limit: 6,
  });

  return (
    <div
      className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 mt-4"
      data-quick-assist-id="recent-projects-1"
    >
      {data ? (
        data.data.length > 0 ? (
          <>
            {starred.map((project) => (
              <ProjectCard
                key={project.id}
                project={project}
                className="bg-dark-900 border-l border-l-indigo-600"
              />
            ))}
            {data.data
              .filter((project) => !starred.some((p) => p.id === project.id))
              .map((project) => (
                <ProjectCard
                  data-testid="project-card"
                  key={project.id}
                  project={project}
                  className="bg-dark-900"
                />
              ))}
          </>
        ) : (
          <div className="text-center col-span-3 py-6">
            <p>No recent projects...</p>
            <Link to="/projects/create">
              <Button data-testid="create-project" variant="light">
                Create a project
              </Button>
            </Link>
          </div>
        )
      ) : null}
    </div>
  );
};
