import * as seedrandom from "seedrandom";

export function getRandomColor(seed: string) {
  const colors = [
    "blue",
    "cyan",
    "grape",
    "red",
    "pink",
    "violet",
    "green",
    "lime",
    "teal",
    "indigo",
    "yellow",
    "orange",
  ];

  const random = seedrandom(seed);
  const diff = Math.floor(random() * colors.length);

  return colors[diff];
}
