import { Avatar, Button } from "@common/components";
import { Skeleton } from "@mantine/core";
import { ImageIcon } from "@modulz/radix-icons";
import React from "react";
import { ProfileFriendRequest } from "./ProfileFriendRequest";
import { useProfile } from "../pages/Profile";
import { UploadImageModal } from "@common/components/UploadImageModal";
import { patchUser } from "../../../requests/user/user";
import { notification } from "@common/utils/notification";
import SpotifySvg from "@res/images/spotify.svg";
import InstagramSvg from "@res/images/instagram.svg";
import TikTokSvg from "@res/images/tiktok.svg";
import YoutubeSvg from "@res/images/youtube.svg";
import SoundcloudSvg from "@res/images/soundcloud.svg";
import { isEmpty } from "lodash";
import { LinkedAccount } from "@server/entities/user";
import { DEFAULT_COVER } from "@common/utils/constants";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import { BooleanParam, useQueryParams } from "use-query-params";

interface ProfileHeaderProps {}

const ProfileHeader: React.FC<ProfileHeaderProps> = () => {
  const { user, openEditProfile, isMe, mutate } = useProfile();
  const [query, setQuery] = useQueryParams({
    cover: BooleanParam,
  });

  const handleUpload = async (url: string) => {
    if (!user || !isMe) return;

    const { error } = await patchUser(user.id, { cover: url });

    if (error) notification.error(error.message);
    setQuery({ cover: false });
    await mutate();
  };

  const getLinkedAccountUrl = (type: LinkedAccount["type"]) => {
    return user?.linkedAccounts?.find((acc) => acc.type === type)?.url;
  };

  return (
    <div>
      <div className="relative overflow-hidden rounded">
        {user ? (
          <>
            {user.private && (
              <div className="absolute flex flex-col items-center justify-center w-full h-full text-white z-50 backdrop-blur bg-black bg-opacity-75">
                <LockClosedIcon className="w-6 h-6 mb-4" />
                <h3>This profile is private</h3>
              </div>
            )}
            <div className="relative h-[196px]">
              <div className="absolute w-full h-full bg-gradient-to-t from-[#00000080]" />
              <img
                className="w-full h-[196px] object-cover rounded"
                src={user?.cover || DEFAULT_COVER}
                alt="profile-cover"
              />
            </div>

            {isMe && (
              <>
                <UploadImageModal
                  opened={!!query.cover}
                  onClose={() => setQuery({ cover: false })}
                  size="xl"
                  targetWidth={2145}
                  targetHeight={480}
                  onUpload={handleUpload}
                />

                <Button
                  className="absolute top-4 right-4"
                  leftIcon={<ImageIcon />}
                  size="sm"
                  variant="light"
                  onClick={() => setQuery({ cover: true })}
                >
                  Upload cover
                </Button>
              </>
            )}
          </>
        ) : (
          <Skeleton className="w-full" height={240} />
        )}
      </div>

      <div className="relative flex flex-col gap-4 items-center text-center -translate-y-14 z-50">
        <Avatar style={{ margin: "0 auto" }} size="xl" user={user} />

        <Skeleton visible={!user}>
          <h1 className="font-bold pb-2">
            {user?.pseudonym
              ? user?.pseudonym
              : user?.firstName + " " + user?.lastName}
          </h1>
        </Skeleton>

        <Skeleton className="-mt-2" visible={!user}>
          <h2 className="text-gray-400 font-normal">@{user?.username}</h2>
        </Skeleton>

        <div className="flex items-center justify-center gap-6 mt-4">
          {!isEmpty(getLinkedAccountUrl("instagram")) && (
            <a href={getLinkedAccountUrl("instagram")} target="_blank">
              <img className="w-8 h-8" src={InstagramSvg} alt="instagram" />
            </a>
          )}
          {!isEmpty(getLinkedAccountUrl("spotify")) && (
            <a href={getLinkedAccountUrl("spotify")} target="_blank">
              <img className="w-8 h-8" src={SpotifySvg} alt="spotify" />
            </a>
          )}
          {!isEmpty(getLinkedAccountUrl("tiktok")) && (
            <a href={getLinkedAccountUrl("tiktok")} target="_blank">
              <img className="w-8 h-8" src={TikTokSvg} alt="tiktok" />
            </a>
          )}
          {!isEmpty(getLinkedAccountUrl("youtube")) && (
            <a href={getLinkedAccountUrl("youtube")} target="_blank">
              <img className="w-8 h-8" src={YoutubeSvg} alt="youtube" />
            </a>
          )}
          {!isEmpty(getLinkedAccountUrl("soundcloud")) && (
            <a href={getLinkedAccountUrl("soundcloud")} target="_blank">
              <img className="w-8 h-8" src={SoundcloudSvg} alt="soundcloud" />
            </a>
          )}
        </div>

        {!user ? (
          <Skeleton className="mx-auto" width={128} height={36} />
        ) : (
          <ProfileFriendRequest />
        )}

        {isMe && (
          <Button
            className="absolute top-16 right-4"
            size="sm"
            variant="light"
            color="dark"
            onClick={openEditProfile}
          >
            Edit Profile
          </Button>
        )}
      </div>
    </div>
  );
};

export { ProfileHeader };
