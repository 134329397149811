import { Axiom } from "@axiomhq/js";

export class AxiomLogger {
  private axiom: Axiom;

  constructor() {
    this.axiom = new Axiom({
      token: "xaat-b426d614-7b47-46b7-9d4a-38b999c74645",
    });
  }

  public async log(event: any) {
    if (process.env.NODE_ENV !== "production") {
      return;
    }

    this.axiom.ingest("synqup-frontend", [event]);
    await this.axiom.flush();
  }
}

export const axiomLogger = new AxiomLogger();
