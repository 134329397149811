import { Modal } from "@common/components";
import { Button } from "@common/components/Button";
import Checkbox from "@common/components/Checkbox";
import { Fade } from "react-reveal";
import { ModalProps, Progress, Table } from "@mantine/core";
import React, { useState } from "react";
import { useStemViewer } from "../../../contexts/StemViewerContext";
import { StemHowl } from "../../../recoil/stem";
import { Download } from "@mui/icons-material";

interface ExportTracksProps extends ModalProps {}

const ExportTracks: React.FC<ExportTracksProps> = ({ ...props }) => {
  const { stems, exporting } = useStemViewer().stem(["stems", "exporting"]);
  const { exports } = useStemViewer().controllers;
  const [isExporting, setExporting] = useState(false);
  const [selected, setSelected] = useState<StemHowl[]>([]);
  const [percent, setPercent] = useState<number | null>(null);
  const [original, setOriginal] = useState(false);

  const individualDownload = async () => {
    if (isExporting) return;

    setExporting(true);
    setPercent(0);
    await exports.export({
      stems: selected,
      individual: true,
      original,
      type: "wav",
    });
    setPercent(100);
  };

  const handleClose = () => {
    if (percent && percent >= 100) setExporting(false);
    props.onClose();
  };

  const handleChecked = (stem: StemHowl, { target: { checked } }) => {
    setSelected((selected) =>
      checked ? selected.concat(stem) : selected.filter((i) => i.id !== stem.id)
    );
  };

  const isChecked = (trackId) => selected.some((i) => i.id === trackId);

  const isAllChecked = stems.length > 0 && stems.every((i) => isChecked(i.id));

  const handleAllChecked = ({ target: { checked } }) => {
    setSelected(checked ? stems : []);
  };

  return (
    <Modal
      title={
        <div className="flex items-center">
          <Download className="mr-2 w-5 h-5" />
          <h3>Export Tracks</h3>
        </div>
      }
      {...props}
      onClose={handleClose}
    >
      <Table>
        <thead>
          <tr>
            <th>
              <Checkbox
                data-testid="export-select-all"
                size="sm"
                radius="sm"
                checked={isAllChecked}
                onChange={handleAllChecked}
              />
            </th>
            <th>Select all</th>
          </tr>
        </thead>
        <tbody>
          {stems.map((stem) => (
            <tr key={stem.id}>
              <td className="w-6">
                <Checkbox
                  data-testid="export-select"
                  size="sm"
                  radius="sm"
                  checked={isChecked(stem.id)}
                  onChange={(e) => handleChecked(stem, e)}
                />
              </td>
              <td>{stem.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Fade when={isExporting} delay={300} collapse>
        <div className="my-4">
          <Progress
            size="xl"
            color={exporting.done !== exporting.total ? "blue" : "green"}
            label={
              (((exporting.percent + exporting.done) / exporting.total) * 100)
                ?.toFixed(1)
                ?.toString() + "%"
            }
            value={
              ((exporting.percent + exporting.done) / exporting.total) * 100
            }
          />
        </div>
      </Fade>

      <div className="flex items-end justify-between">
        <Checkbox
          radius="sm"
          label="Export stems in original quality"
          checked={original}
          onChange={({ target: { checked } }) => setOriginal(checked)}
        />

        <Button
          data-testid="export-button"
          className="mt-8 float-right"
          variant="light"
          disabled={stems.length === 0 || selected.length === 0 || isExporting}
          onClick={individualDownload}
        >
          Export
        </Button>
      </div>
    </Modal>
  );
};

export { ExportTracks };
