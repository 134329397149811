import React, { useEffect } from "react";
import { Progress } from "@mantine/core";

export default function FakeProgress({
  loading,
  percent,
  setPercent,
  predictedTime,
}) {
  const sigmoid = (t) => 1 / (1 + Math.exp(-19 * t + 5));

  useEffect(() => {
    if (!loading) return;

    const startTime = new Date();

    const interval = setInterval(() => {
      const t = startTime
        ? (new Date().getTime() - startTime.getTime()) / 1000
        : 0;

      const percent = Math.round(99 * sigmoid(t / predictedTime));

      if (Math.random() < 0.9 || percent >= 100)
        setPercent((p) => {
          if (p >= 100) {
            clearInterval(interval);
            return 100;
          } else return percent;
        });

      if (percent >= 99) return clearInterval(interval);
    }, 500);

    return () => clearInterval(interval);
  }, [loading]);

  return (
    <Progress
      size="xl"
      color={percent >= 100 ? "blue" : "green"}
      label={percent?.toFixed(1)?.toString() + "%"}
      value={percent}
    />
  );
}
