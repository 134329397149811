import { useGet } from "../helpers";
import { QueryDto } from "@server/dto";

export type SpotifyArtist = {
  external_urls: {
    spotify: string;
  };
  followers: {
    href: string;
    total: number;
  };
  genres: string[];
  href: string;
  id: string;
  images: [
    {
      url: string;
      height: 300;
      width: 300;
    }
  ];
  name: string;
  popularity: 0;
  type: string;
  uri: string;
};

export const searchSpotify = (query: QueryDto) =>
  useGet<SpotifyArtist[]>(`/spotify/search`, query);
