import React from "react";
import { useStemViewer } from "../../../contexts/StemViewerContext";
import { stemPanelWidth } from "../../../helpers/constants";
import { createPortal } from "react-dom";

const Region: React.FC = () => {
  const { region } = useStemViewer().track(["region"]);
  const { track } = useStemViewer().controllers;

  if (!region) return null;

  return (
    <>
      <div
        className="absolute h-[calc(100%+8rem)] pointer-events-none z-20
          bg-sky-500 bg-opacity-20
          border-solid border-0 border-l border-r border-sky-600 border-opacity-30"
        style={{
          left: stemPanelWidth + track.timeToPixels(region[0]),
          width: track.timeToPixels(region[1] - region[0]),
          top: -19.5,
        }}
      />
      {createPortal(
        <div
          data-testid="sv-region"
          className="absolute h-full pointer-events-none
          bg-sky-500 bg-opacity-20
          border-solid border-0 border-l border-r border-sky-600 border-opacity-30 rounded-tl-sm rounded-tr-sm"
          style={{
            left: track.timeToPixels(region[0]),
            width: track.timeToPixels(region[1] - region[0]),
            top: 0,
          }}
        />,
        document.getElementById("track-header-col-2") || document.body
      )}
    </>
  );
};

export { Region };
