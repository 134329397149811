import { useGet, usePost, useStickyStaleSWR, useSWR } from "../helpers";
import { Channel } from "@server/entities/social/channel.entity";
import { CreateChannelDto } from "@server/modules/social/channel/dto/create-channel.dto";
import { QueryChannelDto } from "@server/modules/social/channel/dto/query-channel.dto";

export const createChannel = (data: CreateChannelDto) => {
  return usePost<Channel>("/social/channel", data);
};

export const readChannel = (id: string) => {
  return usePost(`/social/channel/read/${id}`);
};

export const useChannels = (query?: QueryChannelDto) =>
  useStickyStaleSWR<Channel[]>(`/social/channel`, query);

export const useChannel = (id: string) =>
  useSWR<Channel>(`/social/channel/${id}`);

export const getChannel = (id: string) =>
  useGet<Channel>(`/social/channel/${id}`);

export const useUnread = () =>
  useStickyStaleSWR<number>(`/social/channel/unread`);
