import React, { createContext, useContext, useState } from "react";
import { io, Socket } from "socket.io-client";
import { WithContext } from "./with-contexts";
import { useUser } from "./UserContext";
import { useDeepEffect } from "@common/utils/use-deep-effect";

export interface SocketContextType {
  appSocket: Socket;
  svSocket: Socket;
  loggedIn: boolean;
}

const initialState = {} as SocketContextType;

const SocketContext = createContext<SocketContextType>(initialState);
SocketContext.displayName = "SocketContext";

const appSocket = io({
  autoConnect: false,
});
const svSocket = io("/project", {
  autoConnect: false,
});

export const useSockets = () => useContext(SocketContext);

export const withSocketContext: WithContext = (Component) => (props) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const { user } = useUser();

  useDeepEffect(() => {
    setLoggedIn(false);
    if (!user) return;

    appSocket.connect();
    svSocket.connect();

    appSocket.on("connect", () => {
      appSocket.emit("login");
      setLoggedIn(true);
    });

    appSocket.on("disconnect", () => {
      setLoggedIn(false);
    });

    return () => {
      appSocket.off("connect");
      appSocket.off("disconnect");
      appSocket.disconnect();
      svSocket.disconnect();
    };
  }, [user]);

  return (
    <SocketContext.Provider
      value={{
        appSocket,
        svSocket,
        loggedIn,
      }}
    >
      <Component {...props} />
    </SocketContext.Provider>
  );
};
