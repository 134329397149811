import React from "react";
import { useStemViewer } from "../../../contexts/StemViewerContext";
import { useDrag } from "@use-gesture/react";
import { useViewportSize } from "@mantine/hooks";
import { stemPanelWidth } from "../../../helpers/constants";
import { clamp } from "lodash";

interface ScrollbarProps {}

export const Scrollbar: React.FC<ScrollbarProps> = ({}) => {
  const { scroll, duration } = useStemViewer().track([
    "scale",
    "scroll",
    "duration",
  ]);
  useViewportSize();
  useStemViewer().stem(["stems"]);
  const { track } = useStemViewer().controllers;

  const bindScrollbar = useDrag(
    ({ delta }) => {
      const trackBody = document.getElementById("track_view");
      if (!trackBody) return;
      const newLeft = clamp(
        scroll[0] +
          (delta[0] * track.timeToPixels(duration)) / trackBody.clientWidth,
        0,
        trackBody.scrollWidth - trackBody.clientWidth
      );
      trackBody.scrollTo({
        left: newLeft,
      });
      track.scroll({ left: newLeft });
    },
    { axis: "x" }
  );

  let left = scroll[0];
  let width = 0;
  const trackBody = document.getElementById("track_view");

  if (trackBody) {
    left = (scroll[0] * trackBody.clientWidth) / track.timeToPixels(duration);
    width =
      (trackBody.clientWidth * (trackBody.clientWidth - stemPanelWidth)) /
      track.timeToPixels(duration);
  }

  return (
    <div className="relative h-1 overflow-hidden bg-dark-1000">
      <div
        className="absolute bg-primary-700 hover:bg-primary-600 h-2 top-0 touch-none"
        style={{
          width,
          left,
        }}
        {...bindScrollbar()}
      />
    </div>
  );
};
