import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { PlayerProvider } from "./contexts/Player/PlayerContext";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { App } from "./app";
import { theme, defaultProps } from "./mantine.config";
import { RecoilRoot } from "recoil";
import RecoilNexus from "./contexts/RecoilNexus";
import { createTheme, ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://9292f7cb2a81f216aca0f34a43242783@o4506931894812672.ingest.us.sentry.io/4506931896123392",
  environment: process.env.REACT_APP_ENVIRONMENT || "local",
  release: process.env.REACT_APP_VERSION_NO || "local",
  enabled: process.env.NODE_ENV === "production",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const router = createBrowserRouter(
  createRoutesFromElements(<Route path="/*" element={<App />} />)
);

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <RecoilNexus />
      <MantineProvider
        theme={theme}
        defaultProps={defaultProps}
      >
        <ThemeProvider theme={createTheme({})}>
          <NotificationsProvider position="top-center">
            <PlayerProvider>
              <RouterProvider router={router} />
            </PlayerProvider>
          </NotificationsProvider>
        </ThemeProvider>
      </MantineProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
