import React from "react";
import { Checkbox as MantineCheckbox, CheckboxProps } from "@mantine/core";

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const defaults: CheckboxProps = {
    classNames: { label: "cursor-pointer", input: "cursor-pointer" },
  };

  return <MantineCheckbox {...defaults} {...props} />;
};

export default Checkbox;
