import { usePinch, useScroll } from "@use-gesture/react";
import { throttle } from "lodash";
import React, { useEffect, useRef } from "react";
import { stemPanelWidth } from "../../helpers/constants";
import { useStemViewer } from "../../contexts/StemViewerContext";
import { TrackBody, TrackFooter, TrackHeader } from "./components";
import { useDropzone } from "react-dropzone";
import { Overlay } from "@mantine/core";
import { Files } from "tabler-icons-react";
import { useMergedRef } from "@mantine/hooks";

const Track: React.FC = () => {
  const { currTime, scale } = useStemViewer().track(["currTime", "scale"]);
  const { track, stem } = useStemViewer().controllers;
  const pinchingRef = useRef(false);
  const gestureRef = useRef(null);
  const { getRootProps, isDragActive } = useDropzone({
    noClick: true,
    onDrop: (acceptedFiles) => {
      stem.upload(acceptedFiles).catch();
    },
  });
  const { ref: dropzoneRef, ...rootProps } = getRootProps();
  const ref = useMergedRef(dropzoneRef, gestureRef);

  // Zooming functionality
  usePinch(
    ({ event, pinching }) => {
      pinchingRef.current = !!pinching;
      if (pinching) {
        event.preventDefault();
        if ("deltaY" in event)
          track.scale(event.deltaY > 0 ? scale + 1 : scale - 1);
      }
    },
    { target: gestureRef }
  );

  // Scrolling functionality
  useScroll(
    ({ xy }) => {
      throttle((xy) => track.scroll({ left: xy[0], top: xy[1] }), 500)(xy);
    },
    { target: gestureRef }
  );

  // Scroll to current time
  useEffect(() => {
    const trackBody = document.getElementById("track_view");
    if (!trackBody) return;

    trackBody.scrollLeft =
      track.timeToPixels(currTime) -
      (trackBody.clientWidth - stemPanelWidth) / 2;
  }, [scale]);

  return (
    <div
      data-quick-assist-id="track-body"
      id="track_view"
      className="relative w-full h-[calc(100vh-12rem)] overflow-auto sv-scrollbar"
      ref={ref}
      {...rootProps}
    >
      {isDragActive && (
        <Overlay
          opacity={0.5}
          zIndex={50}
          color={"#000"}
          className="flex flex-col items-center justify-center gap-2"
        >
          <Files className="w-8 h-8" />
          Drop files to upload
        </Overlay>
      )}

      <table
        className="min-w-full min-h-full border-separate bg-dark-1000"
        cellSpacing={0}
        cellPadding={0}
      >
        <TrackHeader pinchingRef={pinchingRef} />

        <TrackBody />

        <TrackFooter />
      </table>
    </div>
  );
};

export { Track };
