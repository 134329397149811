import { useDrag } from "@use-gesture/react";
import React, { useCallback } from "react";
import { useStemViewer } from "../../../contexts/StemViewerContext";
import { stemPanelWidth } from "../../../helpers/constants";
import { Mice } from "../../collab/Mice";
import { StemRow } from "../../stem";
import { Region } from "./Region";

const TrackBody: React.FC = () => {
  const { stems } = useStemViewer().stem(["stems"]);
  const { region } = useStemViewer().track(["region"]);
  const { track } = useStemViewer().controllers;
  const trackBody = document.getElementById("track_view");

  const bind = useDrag(
    ({ initial, movement, event, ...props }) => {
      if (!trackBody || initial[0] < stemPanelWidth) return;
      const pixels = initial[0] - stemPanelWidth + trackBody.scrollLeft;

      if (movement[0] > 15 || movement[0] < -15)
        track.region(pixels, movement[0]);
    },
    {
      axis: "x",
    }
  );

  const handleClick: React.MouseEventHandler = useCallback(
    ({ clientX }) => {
      if (!trackBody) return;
      const pixels = clientX - stemPanelWidth + trackBody.scrollLeft;

      let emptySpaceSelected = false;

      if (region)
        emptySpaceSelected =
          (pixels < track.timeToPixels(region[0]) &&
            pixels > stemPanelWidth + trackBody.scrollLeft) ||
          pixels > track.timeToPixels(region[1]);

      if (emptySpaceSelected) track.clearRegion();
    },
    [region, trackBody]
  );

  return (
    <tbody
      {...bind()}
      className="relative touch-none select-none"
      data-testid="track-body"
      onMouseDown={handleClick}
    >
      <Mice />
      <Region />

      {stems
        .sort((a, b) => a.index - b.index)
        .map((stem) => (
          <StemRow key={stem.id} stem={stem} />
        ))}
    </tbody>
  );
};

export { TrackBody };
