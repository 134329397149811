import React, { useState } from "react";
import { Markup } from "@server/entities";
import { useHover } from "@use-gesture/react";
import { motion } from "framer-motion";
import { Avatar } from "@common/components";
import { clamp } from "lodash";

interface MixdownMarkupProps {
  markup: Markup;
  duration: number;
  width: number;
}

export const MixdownMarkup: React.FC<MixdownMarkupProps> = ({
  markup,
  duration,
  width,
}) => {
  const [hovered, setHovered] = useState(false);
  const bind = useHover(({ hovering }) => setHovered(!!hovering));

  if (!markup.start) return null;

  return (
    <div
      {...bind()}
      data-testid="sv-markup-bubble"
      data-quick-assist-id="sv-markup-bubble"
      className="absolute bottom-1/2 translate-y-1/2 cursor-pointer"
      style={{
        left:
          Math.round(
            clamp((markup.start / duration) * (width - 16), 0, width)
          ) + 22,
        zIndex: hovered ? 50 : 40,
      }}
    >
      <motion.div
        className="relative bg-dark-900 rounded-[2rem] rounded-bl-none overflow-hidden p-1"
        transition={{ type: "tween", duration: 0.15 }}
      >
        <div className="flex items-center gap-3 h-full">
          <Avatar
            className="transition duration-[0.15s]"
            size="sm"
            user={markup.reporter}
          />

          {hovered && (
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, width: "auto", height: "auto" },
                collapsed: { opacity: 0, width: 0, height: 0 },
              }}
              transition={{ type: "tween", duration: 0.15 }}
            >
              <div className="flex flex-col gap-1">
                <p className="text-[1rem] max-w-[250px] pr-2 truncate">
                  {markup.title}
                </p>
              </div>
            </motion.div>
          )}
        </div>
      </motion.div>
    </div>
  );
};
