// eslint-disable-next-line @typescript-eslint/no-var-requires
const colors = require("tailwindcss/colors");

module.exports = {
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  theme: {
    screens: {
      sm: "480px",
      md: "768px",
      lg: "976px",
      xl: "1440px",
    },
    container: {
      center: true,
    },
    fontFamily: {
      sans: ["San Francisco", "sans-serif"],
      neue: ["San Francisco"],
      sf: ["San Francisco"],
      serif: ["San Francisco", "serif"],
    },
    primaryColor: 'purple',
    extend: {
      fontSize: {
        'xs': '0.9rem',
        'sm': '1rem',
      },
      colors: {
        blue: {
          1100: "#1e242f",
        },
        dark: {
          100: "#f2f2ff",
          200: "#9eabde",
          300: "#7787c4",
          400: "#4e5c8d",
          500: "#232f59",
          600: "#1a213d",
          700: "#13182E",
          800: "#070c1f",
          900: "#060a1c",
          1000: "#040711",
        },
        indigo: {
          600: "#312DF8",
          700: "#2926D4",
          800: "#211FAB",
          900: "#191782",
          1000: "#191782",
        },
        purple: {
          200: "#dcd9ee",
          300: "#a181ff",
          400: "#9167fa",
          500: "#8354fb",
          600: "#6b47d6",
          700: "#4e39ad",
          800: "#3f2c85",
          900: "#2b1e5c",
        },
        red: {
          100: "rgb(255,255,255)",
          200: "rgb(249,211,210)",
          300: "rgb(243,167,165)",
          400: "rgb(238,123,119)",
          500: "rgb(232,79,74)",
          600: "rgb(225,68,62)",
          700: "rgb(176,47,43)",
          800: "rgb(140,46,42)",
          900: "rgb(131,43,40)",
          1000: "rgb(98,40,39)",
        },
        slate: {
          100: "#B5C2F4",
          200: "#9BA6D1",
          300: "#7D85A8",
          400: "#5E657F",
          700: "#3c3e4d",
          800: "#2e323d",
          900: "#1d1f25",
          1000: "#191a1f",
          1100: "#131317",
        },
        primary: colors.purple,
      },
      animation: {
        opacity: "kf_opacity 0.6s ease-out forwards",
        up: "kf_up 0.4s ease-out forwards",
        "bg-blur": "kf_blur 0.8s ease-out forwards",
        modal: "kf_modal 0.5s ease-out",
        shake: "kf_shake 0.5s cubic-bezier(.36,.07,.19,.97) both",
      },
      keyframes: {
        kf_modal: {
          "0%": {
            // filter: "blur(16px)",
            transform: "translateY(30px)",
          },
          // "50%": {
          //   filter: "blur(1px)",
          //   transform: "translateY(-5px) scale(1.05)",
          // },
          // "75%": {
          //   filter: "blur(0px)",
          //   transform: "translateY(5px) scale(0.98)",
          // },
          "100%": {
            filter: "blur(0px)",
            transform: "translateY(0px)",
          },
        },
        kf_blur: {
          "0%": { backdropFilter: "blur(0px)" },
          "100%": { backdropFilter: "blur(10px)" },
        },
        kf_opacity: {
          "0%": { opacity: 0 },
          "100%": { opacity: 1 },
        },
        kf_up: {
          "0%": { opacity: 0, transform: 'translateY(16px)' },
          "100%": { opacity: 1, transform: 'translateY(0)' },
        },
        kf_shake: {
          "10%, 90%": { transform: "translate3d(-1px, 0, 0)" },
          "20%, 80%": { transform: "translate3d(2px, 0, 0)" },
          "30%, 50%, 70%": { transform: "translate3d(-4px, 0, 0)" },
          "40%, 60%": { transform: "translate3d(4px, 0, 0)" },
        },
      },
    },
  },
  plugins: [
    function ({ addVariant }) {
      addVariant("children", "& *");
    },
    function ({ addVariant }) {
      addVariant("span", "& > span");
    },
    require('@tailwindcss/line-clamp'),
  ],
  corePlugins: {
    preflight: false,
  },
  important: true,
};

function getColorArray(rgbStr) {
  const rgb = rgbStr.slice(4, -1);
  const [r, g, b] = rgb.split(",").map((i) => Number(i));
  
  const [h, s] = rgbToHsl(r, g, b);
  
  const colorArray = [];
  
  const x = [0.15, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
  const lightnessArray = [];
  x.forEach((x) =>
    lightnessArray.push(x)
  );
  
  lightnessArray.forEach((l) => {
    const [r, g, b] = hslToRgb(h, s, l);
    colorArray.push(`rgb(${r},${g},${b})`);
  });
  
  const colorObj = {};
  
  colorArray.reverse().forEach((color, idx) => {
    colorObj[(idx + 1) * 100] = color
  });
  
  return colorObj;
}

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   {number}  h       The hue
 * @param   {number}  s       The saturation
 * @param   {number}  l       The lightness
 * @return  {Array}           The RGB representation
 */
function hslToRgb(h, s, l) {
  let r, g, b;
  
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    let hue2rgb = function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    
    let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    let p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 *
 * @param   {number}  r       The red color value
 * @param   {number}  g       The green color value
 * @param   {number}  b       The blue color value
 * @return  {Array}           The HSL representation
 */
function rgbToHsl(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;
  
  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  
  return [h, s, l];
}
