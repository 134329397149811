import { Avatar, Card } from "@common/components";
import React from "react";
import { Markup } from "@server/entities/project";
import { Badge, MantineColor, Timeline } from "@mantine/core";
import { humanize } from "underscore.string";
import dayjs from "dayjs";
import { ActivityLog } from "@server/entities/project/activity-log.entity";

interface MarkupLogsProps {
  markup?: Markup;
}

export const getLogColor = (type: ActivityLog["type"]): MantineColor => {
  switch (type) {
    case "changes_requested":
      return "yellow";
    case "stem_replaced":
      return "orange";
    default:
      return "gray";
  }
};

const MarkupLogs: React.FC<MarkupLogsProps> = ({ markup }) => {
  if (!markup?.logs) return <></>;

  return (
    <Card className="bg-dark-900">
      <Timeline lineWidth={2} active={markup.logs.length}>
        {markup.logs.length > 0 ? (
          markup.logs.map((log) => (
            <Timeline.Item
              key={log.id}
              bullet={<Avatar size="sm" user={log.user} />}
              bulletSize={20}
              color={getLogColor(log.type)}
              title={
                <div className="flex justify-between">
                  <Badge size="md" variant="dot" color={getLogColor(log.type)}>
                    {humanize(log.type)}
                  </Badge>
                  <p className="text-xs">{dayjs(log.createdAt).fromNow()}</p>
                </div>
              }
            >
              <p className="text-gray-400">
                {log.user?.displayName} {log.body}
              </p>
            </Timeline.Item>
          ))
        ) : (
          <p className="text-gray-500">No logs yet!</p>
        )}
      </Timeline>
    </Card>
  );
};

export { MarkupLogs };
