import React, { useCallback, useState } from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import { useProfile } from "../pages/Profile";
import { useMediaLibrary } from "../../../contexts/MediaLibrary/MediaLibraryContext";
import { AddTrackModal } from "../pages/AddTrackModal";
import { TrackCard } from "./TrackCard";
import { Track } from "@server/entities/user";
import { EditTrackModal } from "../pages/EditTrackModal";
import Confirm from "@common/components/Confirm";
import { deleteTrack } from "../../../requests/user/track";
import { notification } from "@common/utils/notification";
import { Trash } from "tabler-icons-react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Navigation, Scrollbar } from "swiper";

interface ProfileGalleryProps {}

const ProfileGallery: React.FC<ProfileGalleryProps> = () => {
  const { isMe, tracks, mutate } = useProfile();
  const { openMediaLibrary } = useMediaLibrary();

  const [trackSrc, setTrackSrc] = useState<string | null>(null);
  const [editing, setEditing] = useState<Track | undefined>();
  const [deleting, setDeleting] = useState<Track | undefined>();

  const handleSetEdit = useCallback(
    (track: Track) => {
      setEditing(track);
    },
    [tracks]
  );

  const handleSetDelete = useCallback(
    (track: Track) => {
      setDeleting(track);
    },
    [tracks]
  );

  const handleDelete = async () => {
    if (!deleting) return;

    const { error } = await deleteTrack(deleting.id);

    if (error) return notification.error(error.message);
    setDeleting(undefined);
    await mutate();
  };

  if (!isMe && tracks?.length === 0) return <></>;

  return (
    <section id="gallery" className="py-10">
      <h2 className="mb-4">Gallery</h2>
      {isMe && (
        <>
          <AddTrackModal src={trackSrc} onClose={() => setTrackSrc(null)} />

          <EditTrackModal
            track={editing}
            onClose={() => setEditing(undefined)}
          />

          <Confirm
            color="red"
            icon={<Trash />}
            title="Delete track"
            content="Are you sure you want to delete this"
            opened={!!deleting}
            onClose={() => setDeleting(undefined)}
            onConfirm={handleDelete}
          />
        </>
      )}

      <Swiper
        className="w-0 min-w-full"
        modules={[Navigation, Mousewheel, Scrollbar]}
        navigation
        mousewheel={{ forceToAxis: true }}
        scrollbar={{ hide: true }}
        slidesPerView="auto"
      >
        {tracks?.map((item, index) => (
          <SwiperSlide key={index} className="w-52 h-72">
            <TrackCard
              track={item}
              onEdit={handleSetEdit}
              onDelete={handleSetDelete}
            />
          </SwiperSlide>
        ))}

        {!tracks &&
          Array(3)
            .fill(0)
            .map((_, index) => (
              <SwiperSlide key={index} className="w-52 h-72">
                <TrackCard />
              </SwiperSlide>
            ))}

        {isMe && (
          <SwiperSlide className="w-52 h-72">
            <div
              onClick={() =>
                openMediaLibrary("audio", (file) => setTrackSrc(file.url))
              }
              className="w-fit h-52 p-6 rounded-xl bg-dark-900
          border border-solid border-dark-900 transition hover:border-dark-800 cursor-pointer"
            >
              <h2 className="flex items-center w-[128px] h-full justify-center opacity-70 gap-2 font-normal">
                <BsPlusCircleFill className="opacity-70 w-20 h-20" />
              </h2>
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </section>
  );
};

export { ProfileGallery };
