import React, {
  createContext,
  memo,
  useContext,
  useEffect,
  useRef,
} from "react";
import moment from "moment";
import "rc-slider/assets/index.css";
import { Avatar, Transition, useMantineTheme } from "@mantine/core";
import { ActionIcon, Slider } from "@common/components";
import {
  PauseRounded,
  PlayArrowRounded,
  Shuffle,
  VolumeDown,
} from "@mui/icons-material";
import { Cross1Icon } from "@modulz/radix-icons";
import { Repeat } from "tabler-icons-react";
import { DEFAULT_ARTWORK } from "@common/utils/constants";
import { useRecoilState } from "recoil";
import {
  audioState,
  currTimeState,
  durationState,
  loadingState,
  playingState,
  visibleState,
  volumeState,
} from "./player.atom";
import { useHotkeys, useMediaQuery } from "@mantine/hooks";
import { TbRewindBackward10, TbRewindForward10 } from "react-icons/tb";
import axios from "axios";

export interface PlayerContextType {
  load: (audio: PlayerAudio) => void;
  unload: () => void;
  playPause: () => void;
  seek: (time: number) => void;
  setVisible: (visible: boolean) => void;
}

const initialState = {} as PlayerContextType;

const PlayerContext = createContext<PlayerContextType>(initialState);
PlayerContext.displayName = "PlayerContext";

export const usePlayer = () => useContext(PlayerContext);

export type PlayerAudio = {
  src: string;
  name: string;
  artist?: string;
  artwork?: string;
  format?: string;
  file?: File;
  projectId?: string;
};

function Provider({ children }) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [visible, setVisible] = useRecoilState(visibleState);
  const [audio, setAudio] = useRecoilState<PlayerAudio | null>(audioState);
  const [playing, setPlaying] = useRecoilState(playingState);
  const [currTime, setCurrTime] = useRecoilState(currTimeState);
  const [duration, setDuration] = useRecoilState(durationState);
  const [volume, setVolume] = useRecoilState(volumeState);

  const seeking = useRef<boolean>(false);
  const audioRef = useRef<HTMLAudioElement>();

  const load = (newAudioState: PlayerAudio) => {
    // If track being loaded is the same as the one that is currently
    // playing, pause/play, otherwise, stop current track and play new one
    if (audio?.src === newAudioState.src) return playPause();

    setLoading(true);
    setVisible(true);
    setCurrTime(0);

    if (!audioRef.current) {
      audioRef.current = document.createElement("audio");
      document.body.appendChild(audioRef.current);

      audioRef.current.setAttribute("src", newAudioState.src);
      audioRef.current.setAttribute("title", newAudioState.name);
      audioRef.current.setAttribute("crossOrigin", "anonymous");
      audioRef.current.setAttribute("preload", "metadata");
      audioRef.current.load();

      audioRef.current.addEventListener("loadedmetadata", (data) => {
        console.log(data);
        setDuration(audioRef.current?.duration || 0);
        setLoading(false);
        setPlaying(true);
        audioRef.current?.play();
      });

      audioRef.current.addEventListener("error", async () => {
        setLoading(false);
        setPlaying(false);
        setAudio(null);
        setVisible(false);

        const response = await axios.get(newAudioState.src).catch((err) => {
          console.log(JSON.stringify(err));
        });
        console.log(response?.data);
      });

      audioRef.current.addEventListener("ended", () => setPlaying(false));
      audioRef.current.addEventListener("play", () => setPlaying(true));
      audioRef.current.addEventListener("pause", () => setPlaying(false));

      setAudio(newAudioState);
    } else {
      audioRef.current.setAttribute("src", newAudioState.src);
      audioRef.current.load();
      setAudio(newAudioState);
    }

    if ("mediaSession" in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: newAudioState.name,
        artist: newAudioState.artist || "Unknown",
        artwork: [
          {
            src: newAudioState.artwork || DEFAULT_ARTWORK,
          },
        ],
      });
    }
  };

  const unload = () => {
    audioRef.current?.pause();
    audioRef.current?.remove();
    setAudio(null);
    setCurrTime(0);
    setDuration(0);
    setVisible(false);
  };

  const playPause = async () => {
    if (playing) {
      audioRef.current?.pause();
    } else {
      await audioRef.current?.play();
    }
  };

  // Play or pause the audio with spacebar
  useHotkeys([
    [
      "space",
      (event) => {
        event.preventDefault();
        playPause();
      },
    ],
  ]);

  const seek = (time: number) => {
    handleSeekCommitted(null, time);
  };

  const handleSeek = (_, value: number) => {
    if (!seeking.current) seeking.current = true;
    setCurrTime(value);
  };

  const handleSeekCommitted = (_, value: number) => {
    if (seeking.current) seeking.current = false;
    if (audioRef.current) {
      setCurrTime(value);
      audioRef.current.currentTime = value;
    }
  };

  const handleClose = () => {
    audioRef.current?.pause();
    audioRef.current?.remove();
    setAudio(null);
    setVisible(false);
  };

  const handleVolume = (_, value: number) => {
    setVolume(value);
    if (audioRef.current) audioRef.current.volume = value;
  };

  // Update Time
  useEffect(() => {
    if (!playing || !audioRef.current) return;

    const interval = setInterval(() => {
      if (seeking.current) return;
      setCurrTime(audioRef.current?.currentTime || 0);
    }, 200);

    return () => clearInterval(interval);
  }, [playing, currTime]);

  const handleTenForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime += 10;
    }
  };

  const handleTenBackward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime -= 10;
    }
  };

  return (
    <PlayerContext.Provider
      value={{
        load,
        unload,
        playPause,
        seek,
        setVisible,
      }}
    >
      <Transition mounted={visible} transition="slide-up" duration={300}>
        {(styles) => (
          <div
            data-testid="player"
            className="fixed bottom-0 md:bottom-10 left-1/2 -translate-x-1/2 z-[1000] p-0 md:p-6"
          >
            <div
              className="w-screen md:w-[calc(100vw-4rem)] max-w-6xl p-4 md:p-6 flex gap-6 items-stretch
              bg-black bg-opacity-50 backdrop-blur-lg border border-solid border-dark-600  md:rounded-xl"
              style={styles}
            >
              <div className="flex items-center gap-4 mr-6">
                <Avatar
                  radius="md"
                  size={isMobile ? "md" : "lg"}
                  src={audio?.artwork || DEFAULT_ARTWORK}
                />

                <div>
                  <p className="m-0 font-medium max-w-[8rem] whitespace-pre-wrap">
                    {audio?.name}
                  </p>
                  <span className="text-xs font-medium md:text-sm text-primary-400">
                    {audio?.artist || "Unknown"}
                  </span>
                </div>
              </div>

              <div className="flex-1 flex flex-col items-center gap-4">
                <div className="flex items-center gap-2">
                  {!isMobile && (
                    <>
                      <ActionIcon variant="light">
                        <Shuffle />
                      </ActionIcon>

                      <ActionIcon variant="light" onClick={handleTenBackward}>
                        <TbRewindBackward10 />
                      </ActionIcon>
                    </>
                  )}

                  <ActionIcon
                    className="absolute right-8 top-1/2 -translate-y-1/2 md:top-0 md:right-0 md:relative md:translate-y-0"
                    color={!playing ? "purple" : undefined}
                    variant={!playing ? "filled" : "light"}
                    onClick={playPause}
                    loading={loading}
                  >
                    {playing ? <PauseRounded /> : <PlayArrowRounded />}
                  </ActionIcon>

                  {!isMobile && (
                    <>
                      <ActionIcon variant="light" onClick={handleTenForward}>
                        <TbRewindForward10 />
                      </ActionIcon>

                      <ActionIcon variant="light">
                        <Repeat />
                      </ActionIcon>
                    </>
                  )}
                </div>

                <div className="flex flex-1 items-center w-full gap-4">
                  <p className="hidden md:block m-0">
                    {moment.utc(currTime * 1000).format("mm:ss")}
                  </p>

                  <Slider
                    className="absolute bottom-0 left-0 p-0 w-full md:relative"
                    color={theme.colors.purple[6]}
                    value={currTime}
                    min={0}
                    max={duration}
                    step={1}
                    size="small"
                    valueLabelDisplay="off"
                    onChange={handleSeek}
                    onChangeCommitted={handleSeekCommitted}
                  />

                  <p className="hidden md:block m-0">
                    {moment.utc(duration * 1000).format("mm:ss")}
                  </p>
                </div>
              </div>

              <div className="hidden md:flex items-end gap-2 w-24 mt-8">
                <VolumeDown className="-translate-y-1" />

                <Slider
                  color={theme.colors.purple[6]}
                  value={volume}
                  min={0}
                  max={1}
                  step={0.05}
                  size="small"
                  valueLabelDisplay="off"
                  onChange={handleVolume}
                />
              </div>

              <ActionIcon
                className="invisible md:visible absolute top-4 right-4"
                onClick={handleClose}
                variant="transparent"
                size="sm"
              >
                <Cross1Icon />
              </ActionIcon>
            </div>
          </div>
        )}
      </Transition>

      {children}
    </PlayerContext.Provider>
  );
}

export const PlayerProvider = memo(Provider);
