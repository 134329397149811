import { Avatar } from "@mantine/core";
import React from "react";
import { useProfile } from "../pages/Profile";

interface ProfileInfluencesProps {}

const ProfileInfluences: React.FC<ProfileInfluencesProps> = () => {
  const { user } = useProfile();

  if (!user?.influences) return <></>;
  if (user.influences.length === 0) return <></>;

  return (
    <div className="py-10">
      <h2 className="mb-4">Influences</h2>

      <div className="flex flex-wrap items-center gap-8 mt-4">
        {user.influences.map((artist, index) => (
          <div key={index} className="flex flex-col items-center gap-4">
            <Avatar src={artist.dp} size="xl" />
            <p className="text-xl">{artist.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export { ProfileInfluences };
