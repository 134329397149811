// Global StemViewer State
import { atom } from "recoil";
import { Socket } from "socket.io-client";
import { CollabState } from "./collab/collab.atom";
import { collabState } from "./collab/collab.selector";
import { stemState, StemState } from "./stem";
import { MarkupState } from "./markup/markup.atom";
import { markupState } from "./markup/markup.selector";
import { trackState } from "./track/track.selector";
import { TrackState } from "./track";
import { genericSelector } from "./helpers/selectors";

export type StemViewerState = {
  stem: StemState;
  track: TrackState;
  markup: MarkupState;
  collab: CollabState;
};

export const socketState = atom<Socket | null>({
  key: "socket",
  default: null,
  dangerouslyAllowMutability: true,
});

export const svState = genericSelector<StemViewerState>("svState", {
  track: trackState,
  stem: stemState,
  markup: markupState,
  collab: collabState,
});
