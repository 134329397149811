import {
  useDelete,
  useGet,
  usePatch,
  usePost,
  useStaleSWR,
  useStickyStaleSWR,
} from "../helpers";
import { QueryFriendsDto } from "@server/modules/social/friendship/dto/query-friends.dto";
import { Friendship } from "@server/entities/social/friendship.entity";
import { PaginatedListDto } from "@server/dto/paginated-list.dto";

export const sendFriendRequest = async (userId: string) =>
  usePost<null>(`/social/friendship/${userId}`);

export const acceptFriendRequest = async (id: string) =>
  usePatch<null>(`/social/friendship/accept/${id}`);

export const rejectFriendRequest = async (id: string) =>
  usePatch<null>(`/social/friendship/reject/${id}`);

export const deleteFriendship = async (id: string) =>
  useDelete<null>(`/social/friendship/${id}`);

export const useFriendship = (userId: string) =>
  useStaleSWR<Friendship | null>(`/social/friendship/${userId}`, undefined);

export const getFriendship = (userId: string) =>
  useGet<Friendship | null>(`/social/friendship/${userId}`, undefined);

export const useFriends = (query?: QueryFriendsDto) =>
  useStickyStaleSWR<PaginatedListDto<Friendship>>("/social/friendship", query);

export const useFriendRequests = (query?: QueryFriendsDto) =>
  useStickyStaleSWR<PaginatedListDto<Friendship>>(
    "/social/friendship/requests",
    query
  );
