import { ActionIcon, Avatar, Card, Input } from "@common/components";
import useAsyncForm from "@common/utils/useAsyncForm";
import { Portal } from "@mantine/core";
import { useClickOutside } from "@mantine/hooks";
import { ArrowRightIcon } from "@modulz/radix-icons";
import { CreateMarkupDto } from "@server/modules/project/markup/dto/create-markup.dto";
import React, { KeyboardEventHandler, useCallback } from "react";
import { useUser } from "../../../../contexts/UserContext";
import { createMarkup } from "../../../../requests/project/markup";
import { useStemViewer } from "../../contexts/StemViewerContext";
import { notification } from "@common/utils/notification";

interface CreateMarkupProps {
  opened: boolean;
  onClose: () => void;
}

const CreateMarkup: React.FC<CreateMarkupProps> = ({ opened, onClose }) => {
  const { user } = useUser();
  const { stems } = useStemViewer().stem(["stems"]);
  const { region, currTime, project } = useStemViewer().track([
    "region",
    "currTime",
    "project",
  ]);
  const { track, markup } = useStemViewer().controllers;
  const ref = useClickOutside(onClose);

  const activeStem = stems.find((stem) => stem.active);

  const form = useAsyncForm<CreateMarkupDto>({
    initialValues: {
      stemId: activeStem?.id,
      projectId: project?.id,
      start: region ? region[0] : currTime,
      end: region ? region[1] : undefined,
    } as CreateMarkupDto,
  });

  const handleSubmit = async () => {
    if (!form.values.title || form.values.title.trim() === "") return;
    const { data, error } = await form.sendForm(createMarkup);
    if (error) notification.error(error.message);
    if (data) markup.create(data);
    onClose();
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.key === "Enter") handleSubmit().catch();
    },
    [form]
  );

  const stem = document.getElementById(`stem-${activeStem?.id}`);

  if (!opened || !stem) return <></>;

  return (
    <Portal target={stem}>
      <Card
        ref={ref}
        className="absolute z-50 w-80 -translate-y-20 p-3 rounded-2xl rounded-bl-none rounded-tl-3xl"
        style={{
          left: region
            ? track.timeToPixels(region[1])
            : track.timeToPixels(currTime),
        }}
      >
        <div className="flex items-center gap-4">
          <Avatar className="ml-2 mt-1" size="sm" user={user} />

          <Input
            data-testid="create-markup-input"
            {...form.getInputProps("title")}
            autoFocus
            size="sm"
            className="-my-1"
            onKeyDown={handleKeyDown}
          />

          <ActionIcon
            data-testid="create-markup-submit"
            className="rounded-xl mr-2"
            loading={form.loading}
            onClick={handleSubmit}
          >
            <ArrowRightIcon />
          </ActionIcon>
        </div>
      </Card>
    </Portal>
  );
};

export { CreateMarkup };
