import { Avatar, ModalProps, Table } from "@mantine/core";
import React, { useState } from "react";
import { Button, Modal } from "@common/components";
import useAsyncForm from "@common/utils/useAsyncForm";
import { UpdateUserDto } from "@server/modules/user/user/dto/update-user.dto";
import { useProfile } from "./Profile";
import { patchUser } from "../../../requests/user/user";
import { FormActionButtons } from "@common/components/FormActionButtons";
import dayjs from "dayjs";
import Checkbox from "@common/components/Checkbox";
import { object, string } from "yup";
import { Plus } from "tabler-icons-react";
import { useMediaLibrary } from "../../../contexts/MediaLibrary/MediaLibraryContext";
import { AddTrackModal } from "./AddTrackModal";
import { Track } from "@server/entities/user";

interface AddProfileHighlightModalProps extends ModalProps {}

export const AddProfileHighlightModal: React.FC<
  AddProfileHighlightModalProps
> = ({ ...props }) => {
  const { user, mutate, tracks } = useProfile();
  const { openMediaLibrary } = useMediaLibrary();

  const [trackSrc, setTrackSrc] = useState<string | null>(null);

  const form = useAsyncForm<UpdateUserDto>({
    initialValues: {} as UpdateUserDto,
    schema: object({
      highlight: object({
        id: string().required("Please choose a track to highlight"),
      }),
    }),
  });

  const handleTrackClick = (trackId: string) => {
    if (form.values.highlight?.id === trackId) {
      form.setValues({});
    } else {
      form.setValues({
        highlight: { id: trackId } as Track,
      });
    }
  };

  const handleSave = async () => {
    if (!user) return;

    const { error } = await form.sendForm((values) =>
      patchUser(user.id, values)
    );

    if (error) return;

    await mutate();
    props.onClose();
  };

  return (
    <Modal size="lg" title={<h2>Add Profile Highlight</h2>} {...props}>
      <Table highlightOnHover>
        <thead>
          <tr>
            <th className="w-12" />
            <th>Track name</th>
            <th>Release date</th>
            <th className="w-12" />
          </tr>
        </thead>

        <tbody>
          {tracks && tracks.length !== 0 ? (
            tracks.map((track) => (
              <tr
                key={track.id}
                className="cursor-pointer"
                onClick={() => handleTrackClick(track.id)}
              >
                <td>
                  <Avatar src={track.artwork} radius="md" />
                </td>
                <td>{track.title}</td>
                <td>{dayjs(track.date).format("LL")}</td>
                <td>
                  <Checkbox checked={form.values.highlight?.id === track.id} />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td />
              <td colSpan={3}>
                <p>No tracks yet!</p>
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <br />

      <AddTrackModal src={trackSrc} onClose={() => setTrackSrc(null)} />

      <Button
        variant="light"
        leftIcon={<Plus className="w-4" />}
        onClick={() =>
          openMediaLibrary("audio", (file) => setTrackSrc(file.url))
        }
      >
        Add track
      </Button>

      <FormActionButtons form={form} onSave={handleSave} />
    </Modal>
  );
};
