import { useDelete, usePatch, usePost, useSWR } from "../helpers";
import { Track } from "@server/entities/user";
import { CreateTrackDto } from "@server/modules/user/track/dto/create-track.dto";
import { UpdateTrackDto } from "@server/modules/user/track/dto/update-track.dto";
import { QueryTrackDto } from "@server/modules/user/track/dto/query-track.dto";

export const postTrack = async (data: CreateTrackDto) =>
  usePost<Track>("/user/track", data);

export const patchTrack = async (id: string, data: UpdateTrackDto) =>
  usePatch<null>(`/user/track/${id}`, data);

export const deleteTrack = (id: string) => useDelete<null>(`/user/track/${id}`);

export const getTracks = (queryTrackDto: QueryTrackDto) =>
  useSWR<Track[]>(`/user/track`, queryTrackDto);

export const getTrack = (userId: string) =>
  useSWR<Track>(`/user/track/${userId}`);
