import { useChat } from "../../contexts/ChatContext";
import { getNotifications } from "../../requests";

export const useFavicon = () => {
  const { unreadCount } = useChat();
  const { data } = getNotifications();

  const favicon = document.querySelector(
    "link[rel*='icon']"
  ) as HTMLLinkElement | null;

  if (!favicon) return;

  const alert = unreadCount > 0 || (data && data?.count > 0);

  if (alert) favicon.href = "/favicon-alert.ico";
  else favicon.href = "/favicon.ico";
};
