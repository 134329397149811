import { motion } from "framer-motion";
import React, { useCallback } from "react";
import { useStemViewer } from "../../contexts/StemViewerContext";
import { stemPanelWidth } from "../../helpers/constants";
import { StemHowl } from "../../recoil/stem";
import { Stem } from "./Stem";
import { StemPanel } from "./StemPanel";

interface StemRowProps {
  stem: StemHowl;
}

const StemRow: React.FC<StemRowProps> = ({ stem }) => {
  const { stem: stemCtl } = useStemViewer().controllers;
  const { uploading } = useStemViewer().stem(["uploading"]);

  const handleSelect = useCallback(() => {
    stemCtl.update(
      stem.stemId,
      { active: true },
      {
        otherStems: { active: false },
        withoutUpsert: true,
      }
    );
  }, []);

  return (
    <motion.tr
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      onClick={handleSelect}
    >
      <td
        className="sticky z-[60] flex left-0 shadow-lg"
        style={{ width: stemPanelWidth }}
      >
        <StemPanel
          stem={stem}
          uploading={
            stem.uploading
              ? stem.stemId === uploading.id
                ? uploading.percent * 100
                : 0
              : undefined
          }
        />
      </td>
      <td>
        <Stem stem={stem} />
      </td>
    </motion.tr>
  );
};

export { StemRow };
