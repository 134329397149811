import {
  InviteCollabDto,
  UpdateCollabDto,
} from "@server/modules/project/collaborator/dto";
import { useDelete, usePatch, usePost, useSWR } from "../helpers";
import { InviteCollabExternalDto } from "@server/modules/project/collaborator/dto/invite-collab-external.dto";
import { Collaborator } from "@server/entities/project";

export const inviteCollab = async (data: InviteCollabDto) =>
  usePost<null>("/project/collab", data);

export const inviteExternalCollab = async (data: InviteCollabExternalDto) =>
  usePost<null>("/project/collab/external", data);

export const updateCollab = async (id: string, data: UpdateCollabDto) =>
  usePatch<null>(`/project/collab/${id}`, data);

export const removeCollab = (id: string) =>
  useDelete<null>(`/project/collab/${id}`);

export const useCollab = (projectId: string) =>
  useSWR<Collaborator>(`/project/collab/${projectId}`);
