import { Markup } from "@server/entities/project";
import { atom } from "recoil";

export type MarkupState = {
  loading: boolean;
  markups: Markup[];
  view: Markup | undefined;
  viewLoading: boolean;
  selected: string | null;
  saving: boolean;
};

export const selectedState = atom<string | null>({
  key: "selected",
  default: null,
});

export const loadingState = atom<boolean>({
  key: "loadingMarkups",
  default: false,
});

export const markupsState = atom<Markup[]>({
  key: "markups",
  default: [],
});

export const savingState = atom<boolean>({
  key: "saving",
  default: false,
});

export const viewState = atom<MarkupState["view"]>({
  key: "viewMarkup",
  default: undefined,
});

export const viewLoadingState = atom<boolean>({
  key: "viewLoading",
  default: false,
});
