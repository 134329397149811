import {
  ActionButtons,
  ActionButtonsProps,
} from "@common/components/ActionButtons";
import { UseAsyncFormReturnType } from "@common/utils/useAsyncForm";
import React from "react";

interface FormActionButtonsProps
  extends RequiredBy<Partial<ActionButtonsProps>, "onSave"> {
  form: UseAsyncFormReturnType<any>;
  prompt?: boolean;
}

const FormActionButtons: React.FC<FormActionButtonsProps> = ({
  form,
  prompt,
  ...props
}) => {
  // if (prompt) usePrompt(form.isDirty());

  return (
    <ActionButtons
      size="sm"
      loading={form.loading}
      saveDisabled={!form.isDirty()}
      onCancel={form.reset}
      {...props}
    />
  );
};

export { FormActionButtons };
