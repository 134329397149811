import { clamp } from "lodash";
import { getRecoil, setRecoil } from "src/contexts/RecoilNexus";
import { Controller, PartialSetterOrUpdater } from "../helpers/controller";
import { TrackState } from "./track.atom";
import { trackState } from "./track.selector";
import { Project } from "@server/entities/project";
import { stemPanelWidth } from "../../helpers/constants";

export class TrackController extends Controller {
  private readonly _track: () => TrackState;
  private readonly setTrack: PartialSetterOrUpdater<TrackState>;
  private interval: NodeJS.Timeout | undefined;
  get track() {
    return this._track();
  }

  constructor() {
    super();
    this._track = () => getRecoil(trackState);
    this.setTrack = (state) => setRecoil(trackState, state);
  }

  /**
   * Sets the project
   * @param project
   */
  setProject(project: Project) {
    this.setTrack({ project });
  }

  /**
   * Function that runs every tick (200ms)
   */
  tick() {
    // If interval already running, return
    if (this.interval) return;

    // Run tick logic
    this.interval = setInterval(() => this.tickLogic(), 200);
  }

  tickLogic() {
    // If paused or stopped, clear interval and reset
    if (!this.track.playing) {
      clearInterval(this.interval);
      this.interval = undefined;
      return;
    }

    this.setTrack(({ currTime, duration, region }) => {
      // Return to start if end of track
      if (currTime >= duration) {
        return {
          currTime: 0,
          playing: false,
        };
      }

      // Return to start of region if outside the bounds
      if (region && clamp(currTime, region[0], region[1]) != currTime) {
        this.seek(region[0], false);
        return { currTime: region[0] };
      }

      return { currTime: currTime + 0.2 };
    });
  }

  /**
   * Seeks to a certain time in all stems
   * @param time
   * @param set
   */
  seek(time: number, set = true) {
    if (time < 0 || time > this.track.duration) return;

    this.state.stem.stems.forEach((stem) => {
      try {
        stem.howl.seek(time);
      } catch (e) {}
      if (this.track.playing) {
        // If not playing, and stem hasn't finished, play
        if (!stem.howl?.playing() && time < stem?.howl?.duration()) {
          stem.howl?.play();
        }
        // If playing, and stem has finished, pause
        if (stem.howl?.playing() && time > stem.howl?.duration()) {
          stem.howl?.pause();
        }
      }
    });

    if (set) this.setTrack({ currTime: time });
  }

  /**
   * Changes the scale of the track (+ve for zoom in, -ve for zoom out)
   * @param newScale
   */
  scale(newScale: number) {
    const roundedScale = Math.round(newScale * 100) / 100;
    const scaleLimit = this.getScaleLimit();
    this.setTrack(() => {
      if (
        (roundedScale > scaleLimit && roundedScale > this.track.scale) ||
        roundedScale < 1
      )
        return { scale: this.track.scale };
      return { scale: roundedScale };
    });
  }

  getScaleLimit() {
    const trackBody = document.getElementById("track_view");
    return trackBody
      ? Math.max(
          Math.ceil(
            this.track.duration /
              ((trackBody.clientWidth - stemPanelWidth) / 100)
          ),
          15
        )
      : 15;
  }

  updateBpm(bpm: number) {
    this.setTrack({ bpm });
  }

  /**
   * Pauses the track if playing, and plays the track if paused.
   */
  pausePlay() {
    this.state.stem.stems.forEach((stem) => {
      if (!this.track.playing) {
        this.tick();
        stem.howl?.seek(this.state.track.currTime);
        if (!stem.howl?.playing()) return stem.howl?.play();
      }
      return stem.howl?.pause();
    });

    return this.setTrack({ playing: !this.track.playing });
  }

  /**
   * Stops the track and returns to start
   */
  stop() {
    this.state.stem.stems.forEach((stem) => {
      stem.howl?.seek(0);
      return stem.howl?.stop();
    });

    return this.setTrack({ playing: false, currTime: 0 });
  }

  /**
   * Selects a region of the track
   * @param startPos start position of region in pixels
   * @param offset offset of final position of region in pixels
   */
  region(startPos: number, offset: number) {
    const pos1 = clamp(this.pixelsToTime(startPos), 0, this.track.duration);
    const pos2 = clamp(
      pos1 + this.pixelsToTime(offset),
      0,
      this.track.duration
    );

    const start = pos1 < pos2 ? pos1 : pos2;
    const end = pos1 < pos2 ? pos2 : pos1;

    this.setTrack({ region: [start, end] });
  }

  /**
   * Set scroll position
   */
  scroll(scroll: { left?: number; top?: number }) {
    this.setTrack((state) => ({
      scroll: [scroll.left || state.scroll[0], scroll.top || state.scroll[1]],
    }));
  }

  /**
   * Opens the notes panel
   */
  openNotes() {
    this.setTrack({ notesOpen: true });
  }

  /**
   * Closes the notes panel
   */
  closeNotes() {
    this.setTrack({ notesOpen: false });
  }

  /**
   * Clears the current region
   */
  clearRegion() {
    return this.setTrack({ region: null });
  }

  /**
   * Conversion function for converting time (s) to number of pixels (px)
   * @param duration
   */
  timeToPixels(duration: number): number {
    return (duration * 100) / this.track.scale;
  }

  /**
   * Conversion function for converting number of pixels (px) to seconds (s)
   * @param pixels
   */
  pixelsToTime(pixels: number): number {
    return (pixels * this.track.scale) / 100;
  }
}
