import React, { MouseEvent, useCallback } from "react";
import { Image } from "@mantine/core";
import dayjs from "dayjs";
import { useProfile } from "../pages/Profile";
import { Track } from "../../../../../server/src/entities/user";
import Skeleton from "@common/components/Skeleton";
import { usePlayer } from "../../../contexts/Player/PlayerContext";
import { DotsMenu } from "@common/components";
import { Edit, Trash } from "tabler-icons-react";

interface TrackCardProps {
  track?: Track;
  onEdit?: (track: Track) => void;
  onDelete?: (track: Track) => void;
}

export const TrackCard: React.FC<TrackCardProps> = ({
  track,
  onEdit,
  onDelete,
}) => {
  const { isMe, user } = useProfile();
  const { load } = usePlayer();

  const handleEditCallback = useCallback(
    (ev: MouseEvent) => {
      ev.stopPropagation();
      onEdit && track && onEdit(track);
    },
    [track]
  );

  const handleDeleteCallback = useCallback(
    (ev: MouseEvent) => {
      ev.stopPropagation();
      onDelete && track && onDelete(track);
    },
    [track]
  );

  if (!track)
    return (
      <div className="w-fit h-52 flex flex-col gap-3 p-6 rounded-xl bg-dark-1000">
        <Skeleton visible height={128}>
          <Image className="mb-4" width={128} height={128} radius="md" />
        </Skeleton>

        <Skeleton visible height={18} />

        <Skeleton visible height={12} />
      </div>
    );

  return (
    <div
      onClick={() =>
        user && load({ name: track.title, artist: user.displayName, ...track })
      }
      className="relative w-fit h-52 p-6 rounded-xl bg-dark-800
      border border-solid border-dark-900 transition hover:border-dark-800 cursor-pointer"
    >
      <Image
        className="mb-4"
        width={128}
        height={128}
        radius="md"
        src={track.artwork}
      />

      <h4>{track.title}</h4>
      <p>{track.date ? dayjs(track.date).format("MMMM YYYY") : "Unreleased"}</p>

      {isMe && (
        <DotsMenu
          className="absolute top-2 right-2"
          styles={{ item: { padding: "6px 10px" } }}
        >
          <DotsMenu.Dropdown>
            <DotsMenu.Item
              onClick={handleEditCallback}
              icon={<Edit className="w-4" />}
            >
              Edit track
            </DotsMenu.Item>

            <DotsMenu.Item
              onClick={handleDeleteCallback}
              icon={<Trash className="w-4" />}
              color="red"
            >
              Delete track
            </DotsMenu.Item>
          </DotsMenu.Dropdown>
        </DotsMenu>
      )}
    </div>
  );
};
