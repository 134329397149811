import { notification } from "@common/utils/notification";
import { useDeepEffect } from "@common/utils/use-deep-effect";
import { FormErrors, useForm, UseFormReturnType } from "@mantine/form";
import { LooseKeys, UseFormInput } from "@mantine/form/lib/types";
import React, { useState } from "react";
import { AnySchema, ValidationError } from "yup";
import { FetchResponse } from "../../requests/helpers";
import { mapErrors } from "./map-errors";
import { isEqual } from "lodash";

export interface UseAsyncFormInput<T> extends UseFormInput<T> {
  initialValues: { [key in keyof T]: any } & { [key: string]: any };
  initialErrors?: FormErrors;
  schema?: AnySchema<any, Partial<T>>;
}

export type SendFormFunction<R extends FetchResponse<any>, T> = (
  data: T
) => Promise<R>;

export interface UseAsyncFormReturnType<T> extends UseFormReturnType<T> {
  /**
   * Loading state for when form is validating or sending data to a
   * request service
   */
  loading: boolean;
  /**
   * Returns true if error, false if no error
   */
  validateAsync: () => Promise<boolean> | undefined;
  validateFieldAsync: <K extends LooseKeys<T>>(
    field: Extract<K, string>
  ) => Promise<boolean> | undefined;
  setValues: <L extends T>(
    value: React.SetStateAction<L>,
    initial?: boolean
  ) => void;
  setFieldValue: <L extends T, F extends LooseKeys<L>>(
    path: F,
    value: F extends keyof L ? L[F] : unknown
  ) => void;
  resetDirty: <L extends T>(values?: L) => void;
  /**
   * Takes an async function and returns the response with loading state
   * @param func
   */
  sendForm: <R extends FetchResponse<any>>(
    func: SendFormFunction<R, T>,
    opts?: {
      successMessage?: string;
      resetInitial?: boolean;
      dirtyOnly?: boolean;
    }
  ) => Promise<R>;
}

export default function useAsyncForm<T extends { [key: string]: any }>({
  initialValues,
  initialErrors,
  schema,
}: UseAsyncFormInput<T>): UseAsyncFormReturnType<T> {
  const [loading, setLoading] = useState(false);
  const [_initialValues, setInitialValues] = useState(
    initialValues || ({} as T)
  );

  const form = useForm({
    initialValues: _initialValues,
    initialErrors,
  });

  useDeepEffect(() => {
    setValues({ ...form.values, ...initialValues } || ({} as T), true);
  }, [initialValues]);

  const validationSchema: AnySchema | undefined = schema;

  const validateAsync = (): Promise<boolean> | undefined => {
    setLoading(true);
    return validationSchema
      ?.validate(form.values, { abortEarly: false })
      .then(() => {
        form.setErrors({});
        return false;
      })
      .catch((err: ValidationError) => {
        const errors: Record<string, string> = {};
        err.inner.forEach((e) =>
          e.path !== undefined ? (errors[e.path] = e.message) : {}
        );
        form.setErrors(errors);
        setLoading(false);
        return true;
      });
  };

  const validateFieldAsync = <K extends LooseKeys<T>>(
    field: Extract<K, string>
  ): Promise<boolean> | undefined => {
    return validationSchema
      ?.validateAt(field, form.values, { abortEarly: false })
      .then(() => {
        form.setFieldError(field, null);
        return false;
      })
      .catch((err: ValidationError) => {
        form.setFieldError(field, err.errors);
        return true;
      });
  };

  async function sendForm<R extends FetchResponse<any>>(
    func: SendFormFunction<R, T>,
    opts?: {
      successMessage?: string;
      resetInitial: boolean;
      dirtyOnly?: boolean;
    }
  ): Promise<R> {
    // Schema validation
    const hasErrors = await validateAsync();
    if (hasErrors)
      return { error: { status: 500, message: "Validation error" } } as R;

    let values = form.values;

    // Get dirty values only
    if (opts?.dirtyOnly) {
      values = {} as typeof form.values;

      for (const key in form.values) {
        if (form.isDirty(key)) {
          values[key as keyof T] = form.values[key];
        }
      }
    }

    // Form sent to server
    setLoading(true);
    const res = await func(values);
    setLoading(false);

    if (res.error) {
      form.setErrors(mapErrors(res.error));
      return res;
    } else {
      form.setErrors({});
      if (opts?.successMessage) notification.success(opts?.successMessage);
    }
    if (opts?.resetInitial) {
      setInitialValues(form.values);
      form.resetDirty();
    } else form.setValues(_initialValues);
    return res;
  }

  function setValues<L extends T>(
    values: React.SetStateAction<L>,
    initial?: boolean
  ) {
    form.setValues(values as L);
    if (initial) {
      setInitialValues(values as L);
      form.resetDirty(values as L);
    }
  }

  function setFieldValue<L extends T, F extends LooseKeys<L>>(
    path: F,
    value: F extends keyof L ? L[F] : unknown
  ) {
    // @ts-ignore
    form.setFieldValue(path, value);
  }

  function reset() {
    form.setValues(_initialValues);
    form.resetDirty();
  }

  function isDirty(field?: keyof T) {
    if (field) return !isEqual(form.values[field], _initialValues[field]);
    return !isEqual(form.values, _initialValues);
  }

  return {
    ...form,
    loading,
    validateAsync,
    validateFieldAsync,
    sendForm,
    setValues,
    setFieldValue,
    reset,
    isDirty: isDirty,
    resetDirty: form.resetDirty,
  };
}
