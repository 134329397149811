import { Search, SearchProps } from "@common/components";
import UserItem from "@common/components/UserItem";
import { User } from "@server/entities";
import React from "react";
import { useProject } from "../view/ProjectContext";

interface SearchCollabProps
  extends Omit<SearchProps<User>, "data" | "onChange" | "value"> {
  value: User | null;
  onChange: (user: User | null) => void;
}

export function SearchCollab({ value, onChange, ...props }: SearchCollabProps) {
  const { project } = useProject();

  return (
    <div>
      <Search<User>
        name="search-users"
        rightSection={<></>}
        nothingFound="No users found"
        placeholder="Search collaborators"
        data={
          project?.collaborators?.map((collab) => ({
            label: collab.user.displayName,
            value: collab.user.id,
            data: collab.user,
          })) || []
        }
        renderOption={(option) => (
          <UserItem user={option.data} size="sm" className="m-0 py-0" />
        )}
        value={value?.id}
        onChange={(value) => {
          onChange &&
            onChange(
              project?.collaborators?.find((collab) => collab?.userId === value)
                ?.user || null
            );
        }}
        {...props}
      />
    </div>
  );
}
