import React, { useState } from "react";
import { Mixdown, Project } from "@server/entities";
import { usePlayer, usePlayerState } from "../../../../contexts/Player";
import {
  ActionIcon,
  Button,
  Card,
  Input,
  Menu,
  Modal,
  Select,
  Tooltip,
} from "@common/components";
import { PauseRounded, PlayArrowRounded, Upload } from "@mui/icons-material";
import { useProject } from "../ProjectContext";
import useAsyncForm from "@common/utils/useAsyncForm";
import { UpdateMixdownDto } from "@server/modules/project/mixdown/dto/update-mixdown.dto";
import { object, string } from "yup";
import {
  deleteMixdown,
  updateMixdown,
} from "../../../../requests/project/mixdown";
import { notification } from "@common/utils/notification";
import { MixdownTime } from "./MixdownTime";
import { ArrowsExchange, Edit, Trash } from "tabler-icons-react";
import Confirm from "@common/components/Confirm";

interface MixdownPanelProps {
  currMixdown?: Mixdown;
  project: Partial<Omit<Project, "collaborators">>;
  file?: File;
  onUpload?: () => void;
  onMixdownChange: (mixdownId: string | null) => void;
  onMixdownCompare: (mixdownId: string) => void;
}

export const MixdownPanel: React.FC<MixdownPanelProps> = ({
  currMixdown,
  project,
  file,
  onUpload,
  onMixdownChange,
  onMixdownCompare,
}) => {
  const { view, mutate } = useProject();
  const { load, playPause, unload } = usePlayer();
  const { audio, playing: _playing } = usePlayerState(["audio", "playing"]);
  const playing = _playing && audio?.projectId === project.id;
  const [rename, setRename] = useState(false);
  const [deleteMix, setDelete] = useState(false);

  const form = useAsyncForm<UpdateMixdownDto>({
    initialValues: {
      name: currMixdown?.name,
    },
    schema: object().shape({
      name: string().required("Mixdown name is required"),
    }),
  });

  const handlePlayPause = () => {
    const src =
      (file ? URL.createObjectURL(file) : null) || currMixdown?.file?.url;
    const name = project.name || file?.name || "Unknown";

    if (playing) {
      return playPause();
    }

    if (!src) {
      return;
    }

    if (audio?.file && audio.file === file) {
      return playPause();
    } else {
      load({
        src,
        name,
        file,
        artist: project.artist,
        artwork: project.albumArt,
        projectId: project.id,
      });
    }
  };

  const handleSave = async () => {
    if (!currMixdown) return;

    const { error } = await form.sendForm(async (values) =>
      updateMixdown(currMixdown.id, values)
    );

    if (error) return notification.error(error.message);

    setRename(false);
    await mutate();
  };

  const handleDelete = async () => {
    if (!currMixdown) return;

    const { error } = await deleteMixdown(currMixdown.id);

    if (error) return notification.error(error.message);

    setDelete(false);
    onMixdownChange(null);
    await mutate();
  };

  return (
    <Card className="relative flex items-center justify-center -mt-6 rounded-tr-none rounded-tl-none p-2">
      <div className="absolute flex items-center gap-2 left-2">
        <MixdownTime />

        {project.mixdowns && project.mixdowns.length > 0 && (
          <Select
            className="-my-2 w-32 md:w-48"
            size="xs"
            data={
              project.mixdowns?.map((mixdown) => ({
                label: mixdown.name,
                value: mixdown.id,
              })) || []
            }
            value={currMixdown?.id}
            onChange={(value) => {
              unload();
              if (value) onMixdownChange(value);
            }}
          />
        )}
      </div>

      <ActionIcon
        data-testid="mixdown-play-pause"
        variant={playing ? "light" : "filled"}
        color={playing ? "gray" : "primary"}
        onClick={handlePlayPause}
      >
        {playing ? <PauseRounded /> : <PlayArrowRounded />}
      </ActionIcon>

      {view !== "viewer" && (
        <div className="absolute right-2 flex items-center gap-2">
          {currMixdown && (
            <>
              <Tooltip label="Compare versions">
                <Menu>
                  <Menu.Target>
                    <ActionIcon color="green" size="sm" variant="light">
                      <ArrowsExchange />
                    </ActionIcon>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <p className="text-xs p-2 mb-0">Compare with:</p>
                    {project.mixdowns
                      ?.filter((mix) => mix.id !== currMixdown?.id)
                      .map((mixdown) => (
                        <Menu.Item
                          key={mixdown.id}
                          onClick={() => onMixdownCompare(mixdown.id)}
                        >
                          {mixdown.name}
                        </Menu.Item>
                      ))}
                  </Menu.Dropdown>
                </Menu>
              </Tooltip>

              <Tooltip label="Rename mixdown">
                <ActionIcon
                  size="sm"
                  variant="transparent"
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    setRename(true);
                  }}
                >
                  <Edit />
                </ActionIcon>
              </Tooltip>

              <Tooltip label="Delete mixdown">
                <ActionIcon
                  size="sm"
                  variant="light"
                  color="red"
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    setDelete(true);
                  }}
                >
                  <Trash />
                </ActionIcon>
              </Tooltip>
            </>
          )}

          <Button
            data-testid="mixdown-upload"
            color="gray"
            variant="light"
            leftIcon={<Upload className="w-4 h-4" />}
            onClick={onUpload}
            size="xs"
          >
            Upload
          </Button>
        </div>
      )}

      <Confirm
        title="Delete mixdown"
        content="Are you sure you want to delete this mixdown?"
        opened={deleteMix}
        onClose={() => setDelete(false)}
        onConfirm={handleDelete}
        color="red"
        icon={<Trash className="w-5 h-5" />}
      />

      <Modal
        opened={rename}
        onClose={() => setRename(false)}
        size="sm"
        title={<h2>Rename mixdown</h2>}
      >
        <Input
          {...form.getInputProps("name")}
          autoFocus
          label="New mixdown name"
        />

        <div className="flex justify-end gap-4 mt-4">
          <Button onClick={handleSave} disabled={!form.values.name}>
            Rename
          </Button>
        </div>
      </Modal>
    </Card>
  );
};
