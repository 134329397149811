import { styled } from "@mui/material/styles";
import MuiSlider, { SliderProps } from "@mui/material/Slider";
import { useMemo } from "react";

interface ExtendedSliderProps extends Omit<SliderProps, "color"> {
  color?: string;
}

const Slider = ({ color, ...props }: ExtendedSliderProps) => {
  const CustomSlider = useMemo(() => {
    return styled(MuiSlider)({
      color,
      "& .MuiSlider-valueLabel": {
        fontFamily: "Helvetica Neue, sans-serif",
        fontWeight: 800,
        fontSize: 14,
        backgroundColor: color,
        width: 30,
        overflow: "hidden",
        borderRadius: 8,
      },
      "& .MuiSlider-thumb": {
        opacity: 0,
        transform: "scale(0.8) translate(-7.5px, -7.5px)",
        transition: "0.1s ease-in-out opacity",
      },
      "& .MuiSlider-thumb:hover": {
        opacity: 1,
      },
    });
  }, [color]);

  return <CustomSlider {...props} />;
};

export { Slider };
