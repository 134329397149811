import { Image, ModalProps } from "@mantine/core";
import React from "react";
import { DatePicker, Input, Modal } from "@common/components";
import useAsyncForm from "@common/utils/useAsyncForm";
import { UploadImageModal } from "@common/components/UploadImageModal";
import { useProfile } from "./Profile";
import { FormActionButtons } from "@common/components/FormActionButtons";
import { CreateTrackDto } from "@server/modules/user/track/dto/create-track.dto";
import { postTrack } from "../../../requests/user/track";
import { DEFAULT_ARTWORK } from "@common/utils/constants";
import dayjs from "dayjs";

interface AddTrackModalProps extends Omit<ModalProps, "opened"> {
  src: string | null;
}

export const AddTrackModal: React.FC<AddTrackModalProps> = ({
  src,
  ...props
}) => {
  const { user, mutate } = useProfile();

  const form = useAsyncForm<CreateTrackDto>({
    initialValues: {
      src,
      type: "Single",
      artwork: DEFAULT_ARTWORK,
    } as CreateTrackDto,
  });

  const handleSave = async () => {
    if (!user) return;

    const { error } = await form.sendForm(postTrack);

    if (error) return;

    await mutate();
    props.onClose();
  };

  return (
    <Modal opened={!!src} title={<h2>Add Track</h2>} {...props}>
      <div className="flex items-center gap-8">
        <div>
          <div className=" w-fit p-6 rounded-xl bg-dark-1000">
            <UploadImageModal
              targetWidth={300}
              targetHeight={300}
              onUpload={(url) => form.setFieldValue("artwork", url)}
            >
              <Image
                className="mb-4"
                width={128}
                height={128}
                radius="md"
                src={form.values.artwork}
              />
            </UploadImageModal>

            {form.values.title && <h4>{form.values.title}</h4>}
            {form.values.date ? (
              <p>{dayjs(form.values.date).format("MMMM YYYY")}</p>
            ) : (
              <p>Unreleased</p>
            )}
          </div>
        </div>

        <div className="flex flex-1 flex-col gap-4">
          <Input
            required
            label="Track title"
            placeholder="Track title"
            {...form.getInputProps("title")}
          />

          <DatePicker
            label="Released on"
            placeholder="Released on"
            tooltip="Leave this blank if this is an unreleased track"
            {...form.getInputProps("date")}
          />

          <Input required readOnly label="Artist" value={user?.displayName} />
        </div>
      </div>

      <br />

      <FormActionButtons form={form} onSave={handleSave} />
    </Modal>
  );
};
