import React, { useEffect, useState } from "react";
import { Button, Input } from "@common/components/index";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as _Swiper } from "swiper";
import { Mousewheel, Scrollbar } from "swiper";
import {
  Avatar,
  InputProps,
  MantineNumberSize,
  MantineSize,
  useMantineTheme,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { Artist } from "@server/entities";
import { searchSpotify } from "../../requests/integrations/spotify";

interface SearchInfluencesProps {
  value?: Artist[];
  onChange: (value: Artist[]) => void;
  size?: MantineSize;
  radius?: MantineNumberSize;
  inputProps?: Partial<InputProps>;
}

export const SearchArtists: React.FC<SearchInfluencesProps> = ({
  value,
  onChange,
  size = "xl",
  radius = "lg",
  inputProps,
}) => {
  const theme = useMantineTheme();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebouncedValue(search, 200);
  const [artists, setArtists] = useState<Array<Artist>>([]);
  const [swiper, setSwiper] = useState<_Swiper | null>(null);

  useEffect(() => {
    searchSpotify({ query: search, page: 1 }).then(({ data }) => {
      if (!data) return;

      setArtists(
        data.map((artist) => ({
          name: artist.name,
          dp: artist.images[0]?.url,
          type: artist.type,
        }))
      );
    });
  }, [debouncedSearch]);

  useEffect(() => {
    searchSpotify({ query: search, page }).then(({ data }) => {
      if (!data) return;

      setArtists((artists) => [
        ...artists,
        ...data.map((artist) => ({
          name: artist.name,
          dp: artist.images[0]?.url,
          type: artist.type,
        })),
      ]);
    });
  }, [page]);

  const handleAdd = (influence: Artist) => {
    if (!value) return onChange([influence]);
    if (value.some((item) => item.name === influence.name)) return;
    onChange(value.concat(influence));
  };

  const handleRemove = (influence: Artist) => {
    if (!value) return;
    onChange(value.filter((item) => item.name !== influence.name));
  };

  return (
    <div style={{ width: 0, minWidth: "100%" }}>
      <Input
        value={search}
        onChange={({ target }) => setSearch(target.value)}
        placeholder="Search artists..."
        rightSection={<MagnifyingGlassIcon className="text-dark-400 w-5 h-5" />}
        {...inputProps}
      />

      <Swiper
        modules={[Mousewheel, Scrollbar]}
        mousewheel
        scrollbar={{
          draggable: true,
          hide: true,
          dragClass: "swiper-scrollbar-drag bg-primary-700",
        }}
        slidesPerView={"auto"}
        allowTouchMove={false}
        onSwiper={setSwiper}
        onReachEnd={() => setPage((page) => page + 1)}
      >
        {artists.map((artist, index) => (
          <SwiperSlide key={index} className="w-48 py-6">
            <div
              className="flex flex-col gap-4 p-4 text-center w-36 rounded-md bg-dark-1000 cursor-pointer transition-transform hover:-translate-y-2"
              onClick={() => handleAdd(artist)}
            >
              <Avatar
                src={artist.dp}
                size={size}
                radius={radius}
                className="mx-auto"
              />
              <h3
                style={{ fontSize: theme.fontSizes[size || "md"] }}
                className="overflow-hidden whitespace-nowrap overflow-ellipsis"
              >
                {artist.name}
              </h3>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="flex flex-wrap items-center gap-4 my-2">
        {value?.map((artist, index) => (
          <Button
            key={index}
            className="animate-opacity py-2"
            color="dark"
            variant="outline"
            leftIcon={<Avatar src={artist.dp} size="xs" radius="xl" />}
            onClick={() => handleRemove(artist)}
          >
            {artist.name}
          </Button>
        ))}
      </div>
    </div>
  );
};
