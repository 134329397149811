import React from "react";
import moment from "moment";
import { usePlayerState } from "../../../../contexts/Player";
import { Input } from "@common/components";

export const MixdownTime: React.FC = () => {
  const { currTime } = usePlayerState(["currTime"]);

  return (
    <Input
      size="xs"
      classNames={{ input: "w-16 text-md text-center bg-dark-900" }}
      value={moment().startOf("day").seconds(currTime).format("mm:ss")}
    />
  );
};
