import { Card, DatePicker } from "@common/components";
import { UseAsyncFormReturnType } from "@common/utils/useAsyncForm";
import { UpdateMarkupDto } from "@server/modules/project/markup/dto/update-markup.dto";
import React from "react";
import { SearchCollab } from "../../../../projects/components/SearchCollab";
import { SearchStems } from "../../stem/components/SearchStems";

interface MarkupDetailsProps {
  form: UseAsyncFormReturnType<UpdateMarkupDto>;
}

const MarkupDetails: React.FC<MarkupDetailsProps> = ({ form }) => {
  return (
    <Card variant="outlined" className="flex flex-col gap-4 w-72">
      <SearchCollab
        data-quick-assist-id="sv-markup-assignee"
        data-testid="sv-markup-assignee"
        {...form.getInputProps("assignee")}
        label="Assignee"
        size="sm"
      />

      <SearchCollab
        data-quick-assist-id="sv-markup-reporter"
        data-testid="sv-markup-reporter"
        {...form.getInputProps("reporter")}
        required
        label="Reporter"
        size="sm"
      />

      <DatePicker
        data-quick-assist-id="sv-markup-due-date"
        data-testid="sv-markup-due-date"
        className="w-auto"
        withinPortal
        label="Due date"
        size="sm"
        {...form.getInputProps("dueDate")}
      />

      <SearchStems
        data-quick-assist-id="sv-markup-track"
        data-testid="sv-markup-track"
        {...form.getInputProps("stem")}
        label="Track"
        size="sm"
      />
    </Card>
  );
};

export { MarkupDetails };
