import * as React from "react";
import {
  useBeforeUnload,
  unstable_useBlocker as useBlocker,
} from "react-router-dom";

export function usePrompt(
  message: string | null | undefined | false,
  when: boolean
) {
  let blocker = useBlocker(
    React.useCallback(
      () =>
        typeof message === "string" && when ? !window.confirm(message) : false,
      [message, when]
    )
  );

  let prevState = React.useRef(blocker.state);
  React.useEffect(() => {
    if (blocker.state === "blocked") {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);

  useBeforeUnload(
    React.useCallback(
      (event) => {
        if (when && typeof message === "string") {
          event.preventDefault();
          event.returnValue = message;
        }
      },
      [message, when]
    ),
    { capture: true }
  );
}
