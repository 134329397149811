import { Stem } from "@server/entities/project";
import { FilePondFile } from "filepond";
import { Howl } from "howler";
import { omit } from "lodash";
import { StemHowl } from "../stem";
import axios from "axios";
import crypto from "crypto";
import { Buffer } from "buffer";

interface StemToFileParams {
  stem: Stem | StemHowl;
  onDownloadProgress?: (percent: number) => void;
  original?: boolean;
  signal?: AbortSignal;
}

/**
 * Converts a stem document to a File object for loading into stemviewer
 */
export const stemToFile = async ({
  stem,
  onDownloadProgress = () => {},
  original,
  signal,
}: StemToFileParams) => {
  if (!stem.file) throw new Error(`File not populated for stem: ${stem.id}`);

  let fileData: BlobPart;

  const handleDownloadProgress = (ev) => {
    const percentCompleted = ev.loaded / ev.total;
    onDownloadProgress(Math.cbrt(percentCompleted));
  };

  const versionId = (stem as StemHowl).previewVersionId
    ? (stem as StemHowl).previewVersionId
    : stem.versionId;

  // Check if versionId is unix timestamp
  const newVersioning = versionId?.match(/^\d+$/);

  try {
    if (!stem.compressedUrl || original) throw new Error();

    const { data } = await axios(
      `${stem.compressedUrl}${newVersioning ? "" : `/${versionId}`}`,
      {
        responseType: "blob",
        onDownloadProgress: handleDownloadProgress,
        signal,
      }
    );

    fileData = data;
  } catch (err) {
    let url = stem.file.url;

    if (newVersioning) {
      url = stem.file.url
        .split("/")
        .slice(0, -1)
        .concat(versionId as string)
        .join("/");
    } else if (versionId && versionId.length > 36) {
      url = stem.file.url.split("/").slice(0, -1).join("/");
    }

    const { data } = await axios(url, {
      responseType: "blob",
      params: {
        versionId: versionId?.match(/^\d+$/) ? undefined : versionId,
      },
      onDownloadProgress: handleDownloadProgress,
      signal,
    });

    fileData = data;
  }

  const { filename } = stem.file;
  const type = `audio/${filename.split(".").slice(-1)[0]}`;

  return new File([fileData], stem.file.filename, { type });
};

/**
 * Converts a stem howl object to DTO for upserting
 * @param stem
 */
export function stemToDto<T extends StemHowl>(
  stem: T
): Omit<T, "howl" | "buffer" | "currentFile" | "previousFile"> {
  return omit(stem, ["howl", "buffer", "currentFile", "previousFile"]);
}

/**
 * Gets audio buffer from array buffer (Howler)
 * @param arrayBuffer
 */
export async function getBuffer(
  arrayBuffer: ArrayBuffer
): Promise<AudioBuffer> {
  // @ts-ignore
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  // Decode the audio data from the ArrayBuffer
  return await audioContext.decodeAudioData(arrayBuffer);
}

/**
 * Reads blob as data url
 * @param blob
 */
export async function readAsDataUrl(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      if (typeof reader.result !== "string") return;
      return resolve(reader.result);
    });

    reader.addEventListener("error", (err) => {
      // error notification
      reject(err);
    });

    reader.readAsDataURL(blob);
  });
}

export function base64ToBlob(base64: string, mimeType: string) {
  // Decode the Base64 string to a Uint8Array
  const binaryString = Buffer.from(base64.split(",").at(1) as string, "base64");

  // Create a Blob object with the specified MIME type
  return new Blob([binaryString], { type: mimeType });
}

/**
 * Converts an audio file to a Howl object
 */
export const fileToHowl = async (file: File): Promise<Howl> =>
  new Promise(async (resolve, reject) => {
    const src = await readAsDataUrl(file);
    const howl = new Howl({
      src,
      onload: async () => resolve(howl),
      onloaderror: reject,
    });
  });

export const filePondToFile = (file: FilePondFile) => {
  return new File([file.file], file.filename, {
    ...file.file,
    type: file.fileType,
  });
};

export const getStemHash = async (file: File) => {
  const buffer = await file.arrayBuffer();
  const audio = await getBuffer(buffer);

  return crypto
    .createHash("sha1")
    .update(audio.getChannelData(0))
    .digest("hex");
};
